import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { companyEmployAdd } from "../../../redux/slices/common/modalTheme";
import { fetchAllUserInfo } from "../../../redux/slices/user/user";

import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function AutocompleteSearchBar() {
  useEffect(() => {
    dispatch(fetchAllUserInfo());
  }, []);
  const { allUserInfo } = useSelector((state) => state.userInfo);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);

    if (e.target.value.length >= 3 && allUserInfo) {
      const results = allUserInfo
        .filter(
          (item) =>
            item.userInfoData.firstName
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            item.userInfoData.lastName
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            item.userInfoData.email
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
        )
        .map((item) => item.userInfoData);

      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };

  return (
    <section className="bg-gray-2 0 dark:bg-gray__900 ">
      <div className="mx-auto px-0 md:container dark:bg-gray__900">
        <div className="mx-auto w-full max-w-[1270px]  mb-0 rounded-b-lg border  border-stroke dark:border-gray__800 bg-white dark:bg-gray__800 pb-2 2xl:mb-0">
          <div className="mx-10 lg:mx-[20%] mt-5">
            <h1 className="px-2 pb-3 text-gray__800 dark:text-gray__200 font-semibold font-roboto font-roboto">
              Add Or Remove
            </h1>

            <div
              className="relative w-full w-[100%]   rounded-full  shadow focus:outline-none focus:ring-1 bg-gray__200 dark:bg-gray__600 px-4 py-2"
              style={{ minHeight: "40px" }}
            >
              <FontAwesomeIcon
                icon={faSearch}
                className="absolute text-gray-400 dark:text-white"
                style={{
                  top: "50%",
                  left: "10px",
                  transform: "translateY(-50%)",
                }}
              />
              <input
                type="text"
                placeholder="Search with name or email"
                value={searchTerm}
                onChange={handleChange}
                className="w-full bg-transparent border-none dark:text-gray__200 focus:ring-0 focus:outline-none pl-10"
              />
            </div>

            <ul className="mt-1  mt-2 overflow-y-auto">
              {searchResults.map((item, index) => (
                <li
                  key={index}
                  className="cursor-pointer py-1"
                  onClick={() =>
                    dispatch(companyEmployAdd({ type: true, user: item }))
                  }
                >
                  <div className="flex items-center">
                    <img
                      src={item.photoURL}
                      alt="Profile"
                      className="w-6 h-6 rounded-full mr-4"
                    />
                    <div>
                      <p className="text-sm dark:text-ioty__white text-ioty__black roboto">{`${item.firstName} ${item.lastName}`}</p>
                      <p className="text-sm dark:text-ioty__white text-ioty__black roboto">
                        {item.email}
                      </p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
