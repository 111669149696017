import React, { useState, useEffect } from "react";
import {
  faAdd,
  faCog,
  faEdit,
  faEnvelope,
  faMapMarker,
  faPlus,
  faProjectDiagram,
  faSpinner,
  faTrash,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { Switch } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { categoriesUpdate, AllCategories } from "../redux/slices/project/projects";
import { useDispatch, useSelector } from "react-redux";
import {upLoadSubCategoryImage} from '../redux/slices/common/fileUpload'



function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Category() { 
  const dispatch = useDispatch();
  
  
  const { categories } = useSelector((state) => state.projectSlice);
  const {isLoading, subCategoryImage } = useSelector((state) => state.fileUpload);


  const [enabled, setEnabled] = useState(false);

  const [formData, setFormData] = useState({
    category: "",
    categories: [],
    subCategory: [],
    nameSubCategory: "",
    photoURL:
      "",
  });

  const [targetImage, setTargetImage] = useState("vover");

  function generateRandomId() {
    return Math.random().toString(36).substr(2, 9);
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const catagoriesHandelar = async () => {
    const { category, categories, subCategory } = formData;

    if (category.trim() !== "") {
      const newCategory = {
        categoryName: category,
        categoryId: generateRandomId(),
        subCategoryStatus: enabled,
        subCategory: subCategory,
      };

      const updatedCategories = Array.isArray(categories)
        ? [...categories, newCategory]
        : [newCategory];

      await setFormData((prevFormData) => ({
        ...prevFormData,
        categories: updatedCategories,
        category: "",
        subCategory: subCategory,
      }));

      dispatch(categoriesUpdate(updatedCategories));
      await setFormData((prevFormData) => ({
        ...prevFormData,
        category: "",
        subCategory: [],
        nameSubCategory:'',
        photoURL:''
      }));
    }
  };

  const subCatagoriesHandelar = async () => {
    const { photoURL, nameSubCategory, subCategory } = formData;

    if (nameSubCategory.trim() !== "") {
      const newSubCategory = {
        subCategoryName: nameSubCategory,
        subCategoryId: generateRandomId(),
        hotspotImage: photoURL,
      };

      const updatedSubCategories = [...subCategory, newSubCategory];

      await setFormData((prevFormData) => ({
        ...prevFormData,
        subCategory: updatedSubCategories,
      }));
      setFormData((prevFormData) => ({
        ...prevFormData,
        nameSubCategory: '',
        photoURL:''
      }));
    }
   
  };

  const removeCategory = async (index) => {
    const updatedCategories = [...formData.categories];
    updatedCategories.splice(index, 1);
  
    await setFormData((prevFormData) => ({
      ...prevFormData,
      categories: updatedCategories,
    }));
  
    // After removing the category, you need to dispatch the updated categories array, not newCategory
    dispatch(categoriesUpdate(updatedCategories));
  };
  
  const removeSubCategory = (index) => {
    const updatedCategories = [...formData.subCategory];
    updatedCategories.splice(index, 1);

    setFormData((prevFormData) => ({
      ...prevFormData,
      subCategory: updatedCategories,
    }));
  };
  
  function getFirstTwoLetters(str) {
    return str && str.slice(0, 3);
  }

  useEffect(() => {
    dispatch(AllCategories());
  }, []);

  useEffect(() => {
    if (categories) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        categories: categories[0].categories,
      }));
    }
  }, [categories]);

  const handelSubCategoryImage = (event) => {
    setTargetImage("suCategory");
    if (event.target.files[0]) {
      dispatch(
        upLoadSubCategoryImage(event.target.files[0])
      );
      event.target.value = null;
    }
  };
  useEffect(()=>{
    if (subCategoryImage) {
      console.log('call ')
      setFormData((prevFormData) => ({
        ...prevFormData,
        photoURL: subCategoryImage,
      }));
    }
  },[subCategoryImage])
  const validations = ()=>{
    const {category, subCategory } = formData
    if(!enabled && category) {
        return false
    }
    else if (enabled &&  subCategory.length > 0){
      return false
    }
    else {
     return true
    }
  }
   const validationsSubcategory =()=>{
     if(enabled && formData.nameSubCategory && formData.photoURL ) {
      return false
     }
     else {
      return true
     }
   }  
  return (
    <div className="mx-auto px-0 md:container mt-2 dark:bg-gray__900">
      <div className="mx-auto w-full max-w-[1270px]  mb-0 rounded-b-lg     pb-5 2xl:mb-0">
        <div className="mt-2 sm:mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 dark:bg-gray__700 p-5 border  border-stroke dark:border-gray__800 bg-white dark:bg-gray__800 pb-5 rounded">
        
          <div className="col-span-full  sm:col-span-3 mx-5 ">
          <div className="col-span-full mt-10 sm:mt-0">
          <div className="flex w-full   border-l-[6px]  border-primary bg-primary bg-opacity-[90%] px-7  shadow-md md:p-3">
              <div className="mr-5 flex h-9 w-full max-w-[36px] items-center justify-center rounded-lg bg-primary bg-opacity-90">
                
              <img width="50" className="p-1" height="50" src="https://img.icons8.com/ios/50/FFFFFF/add--v1.png" alt="add--v1"/>
              </div>
              <p className='p-2 font-semibold text-white'>Add a category and subcategory under the main category. </p>
            </div>
           
          </div>
        
            <div className="mt-8">
              <label
                htmlFor="lastName"
                className="block dark:text-gray__200 text-xs font-medium leading-6 text-gray__900  font-roboto"
              >
                Category Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="category"
                id="category"
                autoComplete="family-name" 
                value={formData.category}

                    onChange={handleInputChange}
                className="block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6"
              />
            </div>
            <div>
              <label
                htmlFor="lastName"
                className="block dark:text-gray__200 text-xs font-medium leading-6 text-gray__900 my-3  font-roboto"
              >
                Add Sub Category 
              </label>
              <Switch
                checked={enabled}
                onChange={setEnabled}
                className={classNames(
                  enabled ? "bg-primary" : "bg-primary",
                  "relative inline-flex h-4 w-9 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
              
              <ul role="list" className="">
                  {formData.subCategory && formData.subCategory.map((item, index) => (
                    <li key={item.categoryId} className="col-span-1 flex rounded-md shadow-sm mt-1">
                      <div
                        className='flex w-16 bg-gray__900 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white'
                        
                      >
                        <img src={item.hotspotImage}></img>
                      </div>
                      <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                        <div className="flex-1 truncate px-4 py-2 text-sm">
                          <h1  className="font-medium text-gray-900 hover:text-gray-600">
                          Sub Category : {item.subCategoryName}
                          </h1>
                          <p className="text-gray-500">Id: {item.subCategoryId} </p>
                        </div>
                        <div className="flex-shrink-0 pr-2">
                          <button
                            type="button"
                            onClick={() => removeSubCategory(index)} 
                            className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none "
                          >
                            <span className="sr-only">Open options</span>
                            <FontAwesomeIcon
                                icon={faTrash}
                                className="text-red-400 mr-2"
                              />
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              {enabled && (<>
                <div className="col-span-full sm:col-span-3">

                <div className=" mb-5 ">
                  <label
                    htmlFor="lastName"
                    className="block dark:text-gray__200 text-xs font-medium leading-6 text-gray__900 mb-2  font-roboto"
                  >
                   Sub Category Image <span className="text-red-500">*</span>
                  </label>
                  {
                    !formData.photoURL? (<div className="bg-blue-600 h-16 w-16"></div>):(<> <img
                      src={formData.photoURL}
                      alt="profile cover rounded-xl"
                      className="h-16 w-16 "
                    /></>)
                  }
                  <div className=" bottom-5 mb-2 right-5 z-10">
                    <label className="flex bg-gray-700 ml-24 -mt-12 h-9 w-9 cursor-pointer items-center justify-center rounded-full bg-primary text-gray__500 hover:bg-opacity-90">
                      <input
                        type="file"
                        onChange={handelSubCategoryImage}
                        name="suCategory"
                        id="suCategory"
                        className="sr-only bg-gray__900"
                      />
                      {isLoading && targetImage === "suCategory" ? (
                        <FontAwesomeIcon
                          icon={faSpinner}
                          className="animate-spin mr-1.5 h-4 w-4"
                        />
                      ) : (
                        <span>
                          <FontAwesomeIcon
                            icon={faPlus}
                            className=" h-5 text-white w-5"
                          />
                        </span>
                      )}
                    </label>
                  </div>
                </div>

                <div className="relative">
                  <input
                    type="text"
                    name="nameSubCategory"
                    id="nameSubCategory"
                    autoComplete="family-name"
                    value={formData.nameSubCategory}
                    onChange={handleInputChange}
                    className="block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6"
                  />
                  <button
                    disabled ={ validationsSubcategory()}
                      onClick={subCatagoriesHandelar}
                    className="absolute top-0 right-0 bg-primary text-gray__200  h-[96%] px-2 rounded flex items-center text-sm  font-roboto"
                  >
                    <FontAwesomeIcon
                      icon={faAdd}
                     
                      className="text-gray__200 mr-2"
                    />{" "}
                    Add
                  </button>
                </div>
              </div></>)}
           
              <div className="flex mt-2 justify-end mr-0 mt-5">
                <button
                  onClick={catagoriesHandelar}
                  disabled={validations()}

                  className="inline-flex items-center ml-1 px-8 py-1.5 border border-transparent text-sm  font-medium rounded-md shadow-sm text-white bg-primary dark:bg-gray__700  focus:outline-none focus:border-blue-700  transition duration-150 ease-in-out disabled:opacity-90  font-roboto"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
          <div className="col-span-full  sm:col-span-3 mx-5">
            {formData.categories && formData.categories.length>0 &&(
              <div className="flex w-full   border-l-[6px]  border-primary bg-primary bg-opacity-[90%] px-7  shadow-md md:p-3">
              <div className="mr-5 flex h-9 w-full max-w-[36px] items-center justify-center rounded-lg bg-primary bg-opacity-90">
                
              <img width="50" className="p-1" height="50" src="https://img.icons8.com/pastel-glyph/64/FFFFFF/list.png" alt="add--v1"/>
              </div>
              <p className='p-2 font-semibold text-white'>Selected Category and subcategory  </p>
            </div>
            )}
          

    <ul role="list" className="">
        {formData.categories && formData.categories.map((item, index) => (
          <div>
          <li key={item.categoryId} className="col-span-1 flex rounded-md shadow-sm mt-1">
            <div
              className='flex w-16 bg-primary dark:bg-primary opacity-[90%] flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white'
              
            >
              {getFirstTwoLetters(item.categoryId)}
            </div>
            <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray__200 bg-white">
              <div className="flex-1 truncate px-4 py-2 text-sm">
                <h1  className="font-medium text-gray-900 hover:text-gray-600">
                Category Name: {item.categoryName}
                </h1>
                <p className="text-gray-500">Id:{item.categoryId} </p>
              </div>
              <div className="flex-shrink-0 pr-2">
                <button
                  type="button"
                  onClick={() => removeCategory(index)} 
                  className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none "
                >
                  <span className="sr-only">Open options</span>
                  <FontAwesomeIcon
                      icon={faTrash}
                      className="text-red-400 mr-2"
                    />
                </button>
              </div>
           
            </div>
            
          </li>
          <div className="col-span-full ml-16">
              <ul role="list" className="">
                  {item.subCategory && item.subCategory.map((item, index) => (
                    <li key={item.categoryId} className="col-span-1 flex  shadow-sm mt-0">
                      <div
                        className='flex w-12 bg-primary dark:bg-primary flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white'
                        
                      >
                        <img src={item.hotspotImage}></img>
                      </div>
                      <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray__200 bg-white">
                        <div className="flex-1 truncate px-4 text-xs">
                          <h1  className="font-medium text-gray-900 hover:text-gray-600">
                          Sub Category : {item.subCategoryName}
                          </h1>
                          <p className="text-gray-500">Id: {item.subCategoryId} </p>
                        </div>
                        
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
          </div>
        ))}
      </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Category;
