function ProjectUpload() {
  return (
    <div className="ProjectUpload">
       <>
        <div className="px-2 sm:10">
          <h1 className="text-2xl dark:text-gray__300 mt-10 text-center font-roboto font-bold">ProjectUpload Page</h1>
        
        </div>
       </>
    </div>
  );
}

export default ProjectUpload;