import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  uploadLogo
} from "../../redux/slices/common/fileUpload";

import { updateCompanyInfo } from '../../redux/slices/company/company';
export default function UpdateForm() { 
  const { isLoading, downloadURLLogo } = useSelector((state) => state.fileUpload);
  const [targetImage, setTargetImage] = useState("");
  const dispatch = useDispatch(); 
  const {userInfo} = useSelector((state) => state.userInfo); 
  const {loading} = useSelector((state) => state.modalTheme);
  const {userCompanyInfo} = useSelector((state) => state.companySlice);
  const [companyUserInfo,setCompanyInfoUser] =useState()
  const [companyData, setCompanyData] =useState({})
  const handleImageChange = async (event) => {
    if (event.target.files[0]) {
      setTargetImage('logo')
      dispatch(uploadLogo(event.target.files[0], "logo"));
      
      event.target.value = null;
    }
  };

  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState({
    companyName: '',
    phoneNumber: '',
    address: '',
    city: '',
    isPremium: false,
    companyEmail: '',
    isStatus: true,
    zipCode: '',
    state: '',
    admin:[],
    photoURL: 'https://img.freepik.com/free-photo/white-paper-background-simple-diy-craft_53876-146283.jpg',
    coverPhoto:'https://img.freepik.com/free-photo/white-paper-background-simple-diy-craft_53876-146283.jpg'
  })
  useEffect(() => {
    if (userCompanyInfo && userCompanyInfo.companyInfo) {
      const { companyInfo } = userCompanyInfo;
      setFormData((prevFormData) => ({
        ...prevFormData,
        companyName: companyInfo.companyName || '',
        phoneNumber: companyInfo.phoneNumber || '',
        address: companyInfo.address || '',
        city: companyInfo.city || '',
        isPremium: companyInfo.isPremium || false,
        companyEmail: companyInfo.companyEmail || '',
        creatorEmail: companyInfo.creatorEmail || '',
        isStatus: companyInfo.isStatus || true,
        zipCode: companyInfo.zipCode || '',
        state: companyInfo.state || '',
        admin: companyInfo.admin || [],
        photoURL: companyInfo.photoURL || 'https://img.freepik.com/free-photo/white-paper-background-simple-diy-craft_53876-146283.jpg',
        coverPhoto: companyInfo.coverPhoto || 'https://img.freepik.com/free-photo/white-paper-background-simple-diy-craft_53876-146283.jpg',
        creatorUid: companyInfo.creatorUid || '',
        createTime: companyInfo.createTime || 0
      }));
    }
  }, [userCompanyInfo]);
 

  useEffect(()=>{
    if(downloadURLLogo) {
      setFormData({
        ...formData,
        photoURL:downloadURLLogo ? downloadURLLogo :'https://img.freepik.com/free-photo/white-paper-background-simple-diy-craft_53876-146283.jpg'
      })
     }
  },[downloadURLLogo])
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    const domainRegex = /@(gmail|yahoo|outlook|cloud)\./i;
   
    

  if (domainRegex.test(formData.companyEmail)) {
    setErrorMessage('Please use a company email address.');
  } else {
    setErrorMessage('');
  }
  }

  const isFormIncomplete = !formData.companyName  ||  !formData.companyEmail;

  const isEmailInvalid = !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.companyEmail);
  const updateCompanyData = () => {
    dispatch(updateCompanyInfo({
        info:formData,  id:userCompanyInfo.companyId
    }))
  }

  return (
    <div className='grid grid-cols-1 sm:grid-cols-8 xl:grid-cols-12'>
      
        
      <div className='mx-3 sm:mx-0 sm:col-start-2 sm:col-end-8 xl:col-start-3 xl:col-end-11 2xl:col-start-4 2xl:col-end-10'>
    
           <div className=''>
           <div className='border-b dark:border-gray__800   md:pb-2'>
             <div className='mt-2 sm:mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6'>
               <div className='col-span-full mt-10 sm:mt-0'>
                 <label htmlFor='photo' className='block dark:text-gray__200 text-md xl:text-2xl xl:pb-4 font-medium  text-gray__900'>
                     Company Information  2
                 </label>
                
               </div>
               
               <div className=" mb-3 lg:mb-0 h-[120px] w-full max-w-[120px] col-span-full rounded-full border-4 border-white shadow-card sm:h-[140px] sm:max-w-[140px]">
               
                  <img
                    src={formData.photoURL}
                    alt="profile"
                    className="h-full  w-full rounded-full object-cover object-center"
                  />
                  <label
                    htmlFor="profile"
                    className="relative   ml-4 bg-ioty__primary dark:bg-gray__700  bottom-2 right-2 flex  cursor-pointer items-center justify-center rounded-full bg-primary text-white hover:bg-opacity-90"
                  >
                    {isLoading  ? (
                      <FontAwesomeIcon
                        icon={faSpinner}
                        className="animate-spin mr-1.5 h-4 w-4"
                      />
                    ) : (
                      <span>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          className="fill-current"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M5.4453 1.63085C5.56894 1.44539 5.7771 1.33398 6 1.33398H10C10.2229 1.33398 10.4311 1.44539 10.5547 1.63085L11.6901 3.33398H14C14.5304 3.33398 15.0391 3.5447 15.4142 3.91977C15.7893 4.29484 16 4.80355 16 5.33398V12.6673C16 13.1978 15.7893 13.7065 15.4142 14.0815C15.0391 14.4566 14.5304 14.6673 14 14.6673H2C1.46957 14.6673 0.960859 14.4566 0.585786 14.0815C0.210714 13.7065 0 13.1978 0 12.6673V5.33398C0 4.80355 0.210714 4.29484 0.585786 3.91977C0.960859 3.5447 1.46957 3.33398 2 3.33398H4.30988L5.4453 1.63085ZM6.35679 2.66732L5.22137 4.37045C5.09772 4.55592 4.88957 4.66732 4.66667 4.66732H2C1.82319 4.66732 1.65362 4.73756 1.5286 4.86258C1.40357 4.9876 1.33333 5.15717 1.33333 5.33398V12.6673C1.33333 12.8441 1.40357 13.0137 1.5286 13.1387C1.65362 13.2637 1.82319 13.334 2 13.334H14C14.1768 13.334 14.3464 13.2637 14.4714 13.1387C14.5964 13.0137 14.6667 12.8441 14.6667 12.6673V5.33398C14.6667 5.15717 14.5964 4.9876 14.4714 4.86258C14.3464 4.73756 14.1768 4.66732 14 4.66732H11.3333C11.1104 4.66732 10.9023 4.55592 10.7786 4.37045L9.64321 2.66732H6.35679Z"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.99984 6.66732C6.89527 6.66732 5.99984 7.56275 5.99984 8.66732C5.99984 9.77189 6.89527 10.6673 7.99984 10.6673C9.10441 10.6673 9.99984 9.77189 9.99984 8.66732C9.99984 7.56275 9.10441 6.66732 7.99984 6.66732ZM4.6665 8.66732C4.6665 6.82637 6.15889 5.33398 7.99984 5.33398C9.84079 5.33398 11.3332 6.82637 11.3332 8.66732C11.3332 10.5083 9.84079 12.0007 7.99984 12.0007C6.15889 12.0007 4.6665 10.5083 4.6665 8.66732Z"
                          />
                        </svg>
                      </span>
                    )}
                    <input
                      type="file"
                      name="profile"
                      id="profile"
                      className="sr-only"
                      onChange={handleImageChange}
                    />
                      <span className="text-sm p-1">  Logo</span>
                  </label>
                 
                </div>
               <div className='col-span-full md:mt-8 sm:col-span-3'>
                 <label htmlFor='lastName' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                  Company Name <span className="text-red-500">*</span>
                 </label>
                 <input
                   type='text'
                   name='companyName'
                   id='companyName'
                   autoComplete='family-name'
                   value={formData.companyName}
                   onChange={handleInputChange}
                   className='block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6'
                 />
               </div>
               
               <div className='col-span-full md:mt-8 sm:col-span-3'>
                <label htmlFor='email' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                  Company Email <span className="text-red-500">*</span>
                </label>
                <input
                  type='email'
                  name='companyEmail'
                  id='companyEmail'
                  autoComplete='email'
                  value={formData.companyEmail}
                  onChange={handleInputChange}
                  className={`block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700 sm:text-sm sm:leading-6 ${isEmailInvalid ? 'border-red-500' : ''}`}
                />
                {errorMessage && <p className="text-red-500 text-xs font-semibold mt-2">{errorMessage}</p>}
              </div>
               <div className='col-span-full'>
                 <label htmlFor='address' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                   Company Address
                 </label>
                 <input
                   type='text'
                   name='address'
                   id='address'
                   autoComplete='street-address'
                   value={formData.address}
                   onChange={handleInputChange}
                   className='block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6'
                 />
               </div>
               <div className='col-span-full sm:col-span-3'>
                 <label htmlFor='phoneNumber' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                   Phone Number
                 </label>
                 <input
                   type='number'
                   name='phoneNumber'
                   id='phoneNumber'
                   autoComplete='tel'
                   value={formData.phoneNumber}
                   onChange={handleInputChange}
                   className='block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6'
                 />
               </div>
               <div className='col-span-full sm:col-span-3'>
                 <label htmlFor='state' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                   State
                 </label>
                 <input
                   type='text'
                   name='state'
                   id='state'
                   autoComplete='address-level1'
                   value={formData.state}
                   onChange={handleInputChange}
                   className='block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6'
                 />
               </div>
               <div className='col-span-full sm:col-span-3'>
                 <label htmlFor='city' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                   City
                 </label>
                 <input
                   type='text'
                   name='city'
                   id='city'
                   autoComplete='address-level2'
                   value={formData.city}
                   onChange={handleInputChange}
                   className='block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6'
                 />
               </div>
              
               <div className='col-span-full sm:col-span-3'>
                 <label htmlFor='zipCode' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                   Zip Code
                 </label>
                 <input
                   type='text'
                   name='zipCode'
                   id='zipCode'
                   autoComplete='postal-code'
                   value={formData.zipCode}
                   onChange={handleInputChange}
                   className='block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6'
                 />
               </div>
               
             </div>
           </div>
           
           <div className='flex mt-2 justify-end'>
            
            <Link to="/profile">
            <button
             
             className='inline-flex items-center px-8 py-2 border border-transparent text-sm  font-medium rounded-md shadow-sm  bg-ioty__white dark:bg-gray__900 text-ioty__black  focus:outline-none focus:border-blue-700   dark:text-ioty__white transition duration-150 ease-in-out disabled:opacity-50'
            
           >
             Cancel
           </button>
            </Link>
            {companyUserInfo ?  <button
              
            
              className='inline-flex items-center ml-1 px-8 py-2 border border-transparent text-sm  font-medium rounded-md shadow-sm text-white bg-ioty__primary dark:bg-gray__700  focus:outline-none focus:border-blue-700  transition duration-150 ease-in-out disabled:opacity-50'
               disabled={isFormIncomplete || isEmailInvalid ||  errorMessage}
            >
                {loading.type === 'company_write' && loading.isLoad  ? (
                     <FontAwesomeIcon
                       icon={faSpinner}
                       className="animate-spin mr-1.5 h-4 w-4"
                     />
                   )  : 'Edit'}
            </button>: <button
              
              onClick={updateCompanyData}  
              className='inline-flex items-center ml-1 px-8 py-2 border border-transparent text-sm  font-medium rounded-md shadow-sm text-white bg-ioty__primary dark:bg-gray__700  focus:outline-none focus:border-blue-700  transition duration-150 ease-in-out disabled:opacity-50'
               disabled={isFormIncomplete || isEmailInvalid ||  errorMessage }
            >
                {loading.type === 'company_write' && loading.isLoad  ? (
                     <FontAwesomeIcon
                       icon={faSpinner}
                       className="animate-spin mr-1.5 h-4 w-4"
                     />
                   )  : 'Save'}
            </button>}
            
           </div>
         </div>
    
      </div>
    </div>)
}