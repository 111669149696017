import {
  faBox,
  faBuilding,
  faCubes,
  faExchangeAlt,
  faHome,
  faMoon,
  faProjectDiagram,
  faShoppingBag,
  faSignOut,
  faSun,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { formatDistanceToNow } from "date-fns";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  companyAction,
  updateSuperAdminNotification,
  updateUserNotification,
} from "../../redux/slices/user/user";

import { Link } from "react-router-dom";
import { logOut } from "../../redux/slices/auth/auth";
import {
  changeMode,
  profileEditAction,
  signInModal,
} from "../../redux/slices/common/modalTheme";
import ResetPassword from "../auth/ResetPassword";
import Signin from "../auth/SignIn";

import Signup from "../auth/Signup";
import Varification from "../auth/Varification";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const navigationSystemAdmin = [
  { name: "Home", to: "/", icon: faHome, current: true },
  { name: "Companis", to: "/companies", icon: faBuilding, current: true },
  { name: "Project", to: "/project", icon: faProjectDiagram, current: false },
  { name: "Package", to: "/package", icon: faBox, current: false },
  { name: "Bundles", to: "/bundles", icon: faCubes, current: false },
  { name: "Products", to: "/product", icon: faShoppingBag, current: false },
  {
    name: "Trasactions",
    to: "/transition",
    icon: faExchangeAlt,
    current: false,
  },
  { name: "Users", to: "/users", icon: faUsers, current: false },
];

const navigationUser = [
  { name: "Home", to: "/", icon: faHome, current: true },
  { name: "Project", to: "/project", icon: faProjectDiagram, current: false },
];
const navigationCompany = [
  { name: "Home", to: "/", icon: faHome, current: true },
  { name: "Company", to: "/company", icon: faBuilding, current: true },
  { name: "Project", to: "/project", icon: faProjectDiagram, current: false },
  { name: "Package", to: "/package", icon: faBox, current: false },
  { name: "Bundles", to: "/bundles", icon: faCubes, current: false },
  { name: "Products", to: "/product", icon: faShoppingBag, current: false },
];

export default function Navbar({ value, notification, adminRole }) {
  const navigate = useNavigate();
 
  const [open, setOpen] = useState(false);
  const [notificationUser, setNotificationUser] = useState([]);
 
  const {
    theme,
    signupModal,
    signinModal,
    resetpasswordModal,
    notificationTosta,
    varificationModal,
  } = useSelector((state) => state.modalTheme);
  const dispatch = useDispatch();
  const [seenValue, setSeen] = useState(true);

  const logoutHandelar = () => {
    setOpen(false);
    dispatch(logOut());
    navigate("/");
  };
  const notificationSeen = () => {
    if (adminRole.isSystemAdmin) {
      dispatch(updateSuperAdminNotification());
    } else {
      dispatch(updateUserNotification(value.uid));
    }
  };
  const handleProfileEdit = () => {
    dispatch(profileEditAction({ type: true }));
    setOpen(false);
  };
  useEffect(() => {
    if (notification) {
      const isAnySeen = notification.some(
        (notification) => notification.isSeen === false
      );
      setSeen(isAnySeen);
    }
    setNotificationUser(notification);
  }, [notification]);
  const profileRoute = () => {
    dispatch(profileEditAction({ type: false }));
    navigate("/profile");
  };
  const companyActionReq = (id, type) => {
    dispatch(
      companyAction({
        id,
        type,
      })
    );
  };
  // const handleProfileEdit= ()=>{
  //    dispatch(profileEditAction({type:true}))
  //    setOpen(false)
  // }
  return (
    <div>
      <ResetPassword
        value={resetpasswordModal}
        error={notificationTosta}
      ></ResetPassword>
      <Signup value={signupModal} error={notificationTosta}></Signup>
      <Signin value={signinModal} error={notificationTosta}></Signin>
      <Varification
        error={notificationTosta}
        value={varificationModal}
      ></Varification>
      <div className="mx-auto bg-white border-b dark:border-gray__800 border-gray__300  dark:bg-gray__900  px-4 sm:px-6 lg:px-8">
        <div className="flex h-14 justify-between">
          <div className="flex">
            <div className="-ml-2  mr-2 flex items-center md:hidden">
              {/* Mobile menu button */}
            </div>

            <div className="flex flex-shrink-0 items-center">
              {value && (
                <button
                  type="button"
                  className="rounded-md bg-gray  dark:bg-gray__900 mt-0 mr-2 dark:text-gray__200 text-ioty__black hover:text-gray__500 focus:ring-2 focus:ring-indigo-500 dark:focus:ring-gray-200 text-primary dark:text-primary"
                  onClick={() => setOpen(true)}
                >
                  <Bars3Icon className={`${open ? "hidden" : "h-8 w-8"}`} />
                  <XMarkIcon
                    className={`${open === false ? "hidden" : "h-8 w-8"}`}
                  />
                </button>
              )}
              <Link to="/">
              <h1 className="text-md sm:text-xl dark:text-gray__200  font-semibold text-ioty__black">
                <span className="text-primary">SIGNAL</span>{" "}
                <span className="text-gray__500">FLOW</span>
              </h1>
              </Link>
            </div>

            <div className="hidden md:ml-6 md:flex md:items-center md:space-x-4"></div>
          </div>
          <div className="flex">
            <div className=" items-center">
              {!value && (
                <button
                  className="p-1 bg-white dark:bg-gray__800 dark:text-gray__200  mt-3 sm:mt-2 mx-1 text-ioty__black border border-ioty__black font-roboto px-2 text-sm sm:px-4 bg-gray__900 rounded text-gray__300"
                  onClick={(e) => {
                    dispatch(signInModal({ type: signinModal }));
                  }}
                >
                  Sign In
                </button>
              )}
            </div>
            {value && (
              <div className="mt-4 mx-2">
                <Menu as="div" className="relative inline-block   text-left">
                  <div>
                    <Menu.Button className="flex items-center  rounded-full dark:bg-gray-900 dark:text-gray-400 text-gray-600 hover:text-gray-600 focus:outline-none focus:ring-1 focus:ring-ioty__primary focus:ring-offset-1 focus:ring-offset-gray-100">
                      <span className="sr-only">Open options</span>
                      <BellIcon
                        onClick={notificationSeen}
                        className={` text-gray__700 dark:text-gray__300
                      ${
                        seenValue
                          ? "text-gray__700 w-6 h-6"
                          : "text-gray__300 w-6 h-6"
                      }`}
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                    className="dark:bg-gray__800 "
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-80  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item className="">
                          {({ active }) => (
                            <>
                              {notificationUser &&
                                notificationUser.map((item, index) => (
                                  <Link
                                    key={index}
                                    className={classNames(
                                      active
                                        ? "bg-gray-100 text-gray-900 dark:text-ioty__white mt-1 dark:hover:bg-gray-900"
                                        : "text-gray-700",
                                      "block px-4 py-2 text-sm border-gray-700 dark:text-ioty__white mt-1 dark:hover:bg-gray-800"
                                    )}
                                  >
                                    <div>
                                      <div className="ml-3 w-96 pt-0.5  ">
                                        <p className="text-sm font-medium  text-ioty__black dark:text-ioty__white">
                                          {item.message}
                                        </p>

                                        {item.congMrss && (
                                          <p className="text-sm  font-medium py-2  text-ioty__black dark:text-ioty__white">
                                            {item.congMrss ? item.congMrss : ""}
                                            <span className="text-ioty__secondary mx-2">
                                              {" "}
                                              {item.congMrss
                                                ? formatDistanceToNow(
                                                    new Date(item.timestamp)
                                                  )
                                                : ""}
                                            </span>
                                          </p>
                                        )}

                                        {item.companyName && (
                                          <p className="mt-2 text-sm ttext-ioty__black dark:text-ioty__white">
                                            {item.companyName}{" "}
                                            <span className="text-ioty__secondary">
                                              {" "}
                                              -
                                              {formatDistanceToNow(
                                                new Date(item.timestamp)
                                              )}
                                            </span>
                                          </p>
                                        )}
                                      </div>
                                      {item.isAction &&
                                        adminRole &&
                                        adminRole.isSystemAdmin && (
                                          <div className="border-t border-gary-200  dark:border-gray-700">
                                            <button
                                              onClick={() =>
                                                companyActionReq(
                                                  item.companyId,
                                                  true
                                                )
                                              }
                                              className="inline-flex items-center mt-1 text-xs  rounded justify-center dark:bg-gray-900 bg-gray-200 text-center  font-bold  dark:text-ioty__primary text-ioty__primary   hover:bg-opacity-90 px-4 py-2"
                                            >
                                              Accept
                                            </button>
                                            <button
                                              onClick={() =>
                                                companyActionReq(
                                                  item.companyId,
                                                  false
                                                )
                                              }
                                              className="inline-flex items-center mt-1 mx-2 text-xs  rounded justify-center dark:bg-gray-900 bg-gray-200 text-center  font-bold  dark:text-ioty__secondary text-ioty__secondary   hover:bg-opacity-90 px-3 py-2"
                                            >
                                              Decline
                                            </button>
                                          </div>
                                        )}
                                    </div>
                                  </Link>
                                ))}
                            </>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            )}
            <div className=" mx-1  sm:mr-10 items-center">
              <button
                className=" bg-white mt-3 sm:mx-4 text-ioty__black dark:text-gray__200 dark:bg-gray__700 mt-2  p-1 bg-gray__800 rounded-full text-gray__300"
                onClick={(e) => {
                  dispatch(changeMode({ type: theme.theme, instance: true }));
                }}
              >
                {theme.theme === "dark" ? (
                  <FontAwesomeIcon className="w-5" size="lg" icon={faSun} />
                ) : (
                  <FontAwesomeIcon size="lg" className="w-5" icon={faMoon} />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      {value && (
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative b z-10" onClose={setOpen}>
            <div className="fixed inset-0">
              <div className="absolute inset-0">
                <div className="pointer-events-none fixed mt-14 inset-y-0 left-0 flex max-w-full pr-10 sm:pr-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto   w-screen max-w-xs ">
                      <div className="flex h-full w-full  flex-col dark:bg-gray__900 border-r border-t dark:border-gray__800 border-gray__300 bg-white shadow-xl">
                        <div className="p-6">
                          <div className="flex items-start justify-between">
                            <div className="group relative flex items-center px-5 py-2">
                              <div
                                className="absolute inset-0 "
                                aria-hidden="true"
                              />

                              <Link
                                onClick={() => setOpen(false)}
                                to="/profile"
                              >
                                <div className="relative flex min-w-0 flex-1 items-center">
                                  <span className="relative inline-block flex-shrink-0">
                                    <img
                                      className="h-10 w-10 rounded-full"
                                      src={value.photoURL}
                                      alt=""
                                    />
                                  </span>
                                  <div className="ml-4 truncate">
                                    <p className="truncate text-sm dark:text-gray__300 font-semibold font-roboto font-medium text-gray__900">
                                      {value.name}
                                    </p>
                                    <p className="truncate text-sm dark:text-gray__300 text-gray__500">
                                      {value.email}
                                    </p>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="border-b dark:border-gray__800 border-gray__300"></div>
                        <div className="space-y-1 pl-1 pb-3 ">
                          {adminRole && adminRole.isSystemAdmin && (<>
                            {navigationSystemAdmin.map((item, index) => (
                            <Link
                              onClick={() => setOpen(false)}
                              to={`${item.to}`}
                              key={index}
                              className="relative flex items-center justify-center border-r-4 border-transparent  px-4 text-base font-medium text-body-color duration-200 hover:border-primary hover:bg-gray-2 dark:hover:bg-gray__900  hover:text-primary"
                            >
                              <button
                                key={index}
                                as="a"
                                className="w-[100%] py-4 text-start ml-2 text-gray-500 hover:text-primary  flex items-center dark:text-gray__300"
                                aria-current={item.current ? "page" : undefined}
                              >
                                <div className="flex items-start hover:text-primary">
                                  <span className="mr-2 hover:text-primary">
                                    <FontAwesomeIcon
                                      size="lg"
                                      className="w-12 hover:text-primary text-start mr-6 "
                                      icon={item.icon}
                                    />
                                  </span>
                                  <span className="text-lg hover:text-primary">{item.name}</span>
                                </div>
                              </button>
                            </Link>
                          ))}
                          </>)}  
                          {adminRole && adminRole.isCompanyAdmin && (<>
                            {navigationCompany.map((item, index) => (
                            <Link
                              onClick={() => setOpen(false)}
                              to={`${item.to}`}
                              key={index}
                              className="relative flex items-center justify-center border-r-4 border-transparent  px-4 text-base font-medium text-body-color duration-200 hover:border-primary hover:bg-gray-2 dark:hover:bg-gray__900  hover:text-primary"
                            >
                              <button
                                key={index}
                                as="a"
                                className="w-[100%] py-4 text-start ml-2 text-gray-500 hover:text-primary  flex items-center dark:text-gray__300"
                                aria-current={item.current ? "page" : undefined}
                              >
                                <div className="flex items-start hover:text-primary">
                                  <span className="mr-2 hover:text-primary">
                                    <FontAwesomeIcon
                                      size="lg"
                                      className="w-12 hover:text-primary text-start mr-6 "
                                      icon={item.icon}
                                    />
                                  </span>
                                  <span className="text-lg hover:text-primary">{item.name}</span>
                                </div>
                              </button>
                            </Link>
                          ))}
                          </>)}
                          {adminRole && adminRole.userAdmin && (<>
                            {navigationUser.map((item, index) => (
                            <Link
                              onClick={() => setOpen(false)}
                              to={`${item.to}`}
                              key={index}
                              className="relative flex items-center justify-center border-r-4 border-transparent  px-4 text-base font-medium text-body-color duration-200 hover:border-primary hover:bg-gray-2 dark:hover:bg-gray__900  hover:text-primary"
                            >
                              <button
                                key={index}
                                as="a"
                                className="w-[100%] py-4 text-start ml-2 text-gray-500 hover:text-primary  flex items-center dark:text-gray__300"
                                aria-current={item.current ? "page" : undefined}
                              >
                                <div className="flex items-start hover:text-primary">
                                  <span className="mr-2 hover:text-primary">
                                    <FontAwesomeIcon
                                      size="lg"
                                      className="w-12 hover:text-primary text-start mr-6 "
                                      icon={item.icon}
                                    />
                                  </span>
                                  <span className="text-lg hover:text-primary">{item.name}</span>
                                </div>
                              </button>
                            </Link>
                          ))}
                          </>)}
                          
                        </div>
                        <div className="flex justify-end mt-[5vh] sm:mt-[30vh] md:mt-[30vh] lg:mt-[30vh] xl:mt-[30vh] xl:mt-[35vh] 2xl:mt-[45vh]">
                          <button
                            onClick={logoutHandelar}
                            className=" text-gray-800 bg-white dark:bg-gray__700 dark:text-gray__300 p-1.5 px-2 rounded-lg text-right bottom-0 mr-3  dark:bg-gray-800 dark:text-gray-200"
                          >
                            {" "}
                            <FontAwesomeIcon size="sm" icon={faSignOut} />
                          </button>
                        </div>
                      </div>

                      <div></div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </div>
  );
}
