function Bundles() {
    return (
      <div className="Bundles">
         <>
          <div className="px-2 sm:10">
            <h1 className="text-2xl dark:text-gray__300 mt-10 text-center font-roboto font-bold">Bundle  </h1>
          
          </div>
         </>
      </div>
    );
  }
  
  export default Bundles;