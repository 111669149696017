import { useDispatch } from "react-redux";
import { projectTabAction } from "../../redux/slices/common/modalTheme";
const tabs = [
  { name: 'HOTSPOTS', href: '#', current: false },
  { name: 'PACKAGES', href: '#', current: false },
  { name: 'ADD', href: '#', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  const dispatch = useDispatch()
  return (
    <div>
     
      <div className="hidden sm:block">
        <nav className=" flex divide-x-2  divide-primary dark:border-gray__800  shadow" aria-label="Tabs">
          {tabs.map((tab, tabIdx) => (
            <button
             onClick={(e)=>dispatch(projectTabAction(tabIdx+1))}
              key={tab.name}
              className ='group  min-w-0 flex-1 overflow-hidden bg-white dark:bg-gray__700 py-1.5  dark:text-white px-3 text-center text-sm font-medium hover:bg-gray-50 focus:z-10'
              
            >
              <span className="text-xs">{tab.name}</span>
            
            </button>
          ))}
        </nav>
      </div>
    </div>
  )
}
