import { auth, database, db } from '../index';

export const bundles = db.collection('bundles')
export const companies = db.collection('companies2')
export const ipackages = db.collection('ipackages')
export const categories  = db.collection('categories')
export const projects = db.collection('projects2')
export const floorpans = db.collection('floorpans')
export const users = db.collection('users2')
export const items = db.collection('items')
export const projectbundles = db.collection('projectbundles')
export let createUser = auth
export const realtime  =database