import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { resendEmailVerificationAction } from '../../redux/slices/auth/auth';
import { passwordResetModal, signInModal, signUpModal, varificationModal } from "../../redux/slices/common/modalTheme";
export default function Varification({ value }) {
  const { user } = useSelector((state) => state.authentication);
  const resetModal= ()=>{
    dispatch(signInModal({ type: true }))
    dispatch(signUpModal({ type: true }))
    dispatch(passwordResetModal({ type: true }))
    dispatch(varificationModal({ type: true }))
  }
  const [formData, setFormData] = useState({
    email: "",
  });

  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [emailField, setEmailField] = useState(false);
  useEffect(() => {
    setOpen(value);
  }, [value]);
  const handleReset = (e) => {
   dispatch(resendEmailVerificationAction(formData.email)
   )    
    }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    isFormValid()
  };
  const isFormValid = () => {
    const { email } = formData;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if  (email === "") {
      return false;
    }
    else if (!emailRegex.test(email)) {
      return false;
    }
    else {
      return true;
    }
  }
  return (
    <Transition.Root show={open} as={Fragment} onClick={resetModal}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 dark:bg-gray__800 bg-gray__600 dark:bg-opacity-70 darl bg-opacity-50  transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg dark:bg-gray__900 bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all w-full sm:my-8  sm:w-full sm:max-w-md sm:p-6">
                <div className="flex items-center justify-between border-b dark:border-gray__700 border-gray__200">
                  <div className="text-sm ">
                   
                  </div>
                 
                </div>

                <div className="mt-0 sm:mx-auto sm:w-full sm:max-w-[480px]">
                  <h1 className="p-3 text-ioty__black dark:text-ioty__white font-roboto mt-5">Please check your email for the verification link.  Is there any problem? please <span className="text-green-700 cursor-pointer" onClick={()=>{
                    setEmailField(true)
                  }}> resend</span></h1>
                  {user && user.isOldUser && (
                    <p className="px-3 text-xs sm:text-sm mr-3 text-ioty__black dark:text-ioty__white  font-roboto"> <span className="font-bold">Note:</span> As a user from SignalFlow's old version, please verify your email for the new update.</p>
                  )}
                  {emailField &&(
                     <div className=" dark:bg-gray__900 bg-white py-4 shadow sm:rounded-lg sm:px-4">
                    
                       <div>
                         <label
                           htmlFor="password"
                           className="block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900"
                         >
                           Email 
                         </label>
                         <div className="mt-2">
                           <input
                             id="email"
                             name="email"
                             type="email"
                             required
                             value={formData.email}
                             onChange={handleChange}
                             className="block w-full rounded-md dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                           />
                         </div>
                       </div>
                       <div>
                         <button 
                          disabled={!isFormValid()}
                          onClick={handleReset}
                          className={`flex w-full justify-center mt-5  rounded-md dark:bg-gray__800 bg-[#87c4dc] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#87c4dc] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${isFormValid ? '' : 'pointer-events-none'}`} 
                           
                         >

                           Resend a Varification Link
                         </button>
                       </div>
                    
                     <div>           
                     </div>
                   </div>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
