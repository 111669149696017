import { faMessage, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { chatTabAction } from "../../redux/slices/common/modalTheme";
import { useState } from "react";
export default function FloorpalnTab(props) {
  const { tabs } = props;
  const dispatch = useDispatch();
  const [subCat , setSubCat] = useState(null)
  const subCategory = (item) =>{
    setSubCat(null)
    if(item.subCategoryStatus) {

      setSubCat(item.subCategory)
    }
  }
  return (
    <div className="flex justify-start">
    <div className="hidden sm:block mx-2">
      {tabs &&
        tabs.map((item, index) => (
          <button
            onClick={()=>subCategory(item)}
            key={index}
            className="group  min-w-0 flex-1 overflow-hidden bg-white dark:bg-primary py-2 dark:text-white px-4    border-primary dark:border-gray__200 border-r-4 rounded-t-xl text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
          >
            {item.categoryName}
            <span
              aria-hidden="true"
              className="bg-transparent absolute inset-x-0 bottom-0 h-0.5"
            />
          </button>
        ))}
        {subCat &&
        subCat.map((sub, index) => (
          <button
            onClick={()=>subCategory(sub)}
            key={index+'a'}
            className="group relative min-w-0 flex-1 overflow-hidden bg-white dark:bg-primary py-2 dark:text-white px-4 border-primary dark:border-gray__200 border-r-4 rounded-t-xl text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
          >
           {sub.subCategoryName}
            <span
              aria-hidden="true"
              className="bg-transparent absolute inset-x-0 bottom-0 h-0.5"
            />
          </button>
        ))}
    </div>
  </div>
  );
}