import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  uploadLogo
} from "../../redux/slices/common/fileUpload";

import { writeCompanyInfo } from '../../redux/slices/company/company';
export default function UpdateForm() { 
  const { isLoading, downloadURLLogo } = useSelector((state) => state.fileUpload);
  const [targetImage, setTargetImage] = useState("");
  const dispatch = useDispatch(); 
  const {userInfo} = useSelector((state) => state.userInfo); 
  const {loading} = useSelector((state) => state.modalTheme);
  const {userCompanyInfo} = useSelector((state) => state.companySlice);
  const [companyUserInfo,setCompanyInfoUser] =useState()
  const [companyData, setCompanyData] =useState({})
  const handleImageChange = async (event) => {
    if (event.target.files[0]) {
      setTargetImage('logo')
      dispatch(uploadLogo(event.target.files[0], "logo"));
      
      event.target.value = null;
    }
  };
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState({
    companyName: '',
    phoneNumber: '',
    address: '',
    city: '',
    isPremium: false,
    companyEmail: '',
    about:'',
    isStatus: true,
    zipCode: '',
    state: '',
    photoURL: 'https://img.freepik.com/free-photo/white-paper-background-simple-diy-craft_53876-146283.jpg',
    coverPhoto:'https://img.freepik.com/free-photo/white-paper-background-simple-diy-craft_53876-146283.jpg'
  })
  useEffect(() => {
    if (userInfo && userInfo.userInfoData) {
         const creatorData ={
            ...formData,
            creatorUid:userInfo.userInfoData.uid,
            creatorEmail:userInfo.userInfoData.email,
            createTime:Date.now(),
            admin:[
              {
                isAdmin:true,
                isEditor:false,
                adminUid:userInfo.userInfoData.uid,
                adminEmail:userInfo.userInfoData.email,
              }
            ]
         }
   
        
        //  })
         setCompanyData(creatorData)
        
    }
    else {
      setCompanyData(formData) 
    }
    
  }, [formData, userInfo, userInfo?.userInfoData]);
 
  const updateCompanyData = () => {
    dispatch(writeCompanyInfo(companyData))
  }
  useEffect(()=>{
    if(downloadURLLogo) {
      setFormData({
        ...formData,
        photoURL:downloadURLLogo ? downloadURLLogo :'https://img.freepik.com/free-photo/white-paper-background-simple-diy-craft_53876-146283.jpg'
      })
     }
  },[downloadURLLogo])
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    const domainRegex = /@(gmail|yahoo|outlook|cloud)\./i;
   
    

  if (domainRegex.test(formData.companyEmail)) {
    setErrorMessage('Please use a company email address.');
  } else {
    setErrorMessage('');
  }
  }

  const isFormIncomplete = !formData.companyName  ||  !formData.companyEmail;

  const isEmailInvalid = !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.companyEmail);


  return (
    <div className='grid grid-cols-1 sm:grid-cols-8 xl:grid-cols-12 '>
      
        
      <div className='mx-3 sm:mx-0 sm:col-start-2 sm:col-end-8 xl:col-start-3 xl:col-end-11 2xl:col-start-4 2xl:col-end-10   rounded-b-lg border  border-stroke dark:border-gray__800 bg-white dark:bg-gray__800 pb-5 2xl:mb-0'>
    
           <div className='p-4 md:px-8 md:pb-4 '>
           <div className='border-b dark:border-gray__700 border-gray__300   md:pb-2 '>
             <div className='mt-2 sm:mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6'>
               <div className='col-span-full mt-10 sm:mt-0'>
                 <label htmlFor='photo' className='block dark:text-gray__200 text-md xl:text-2xl xl:pb-4 font-medium  text-gray__900'>
                     Company Information 
                 </label>
                
               </div>
               
               
               <div className='col-span-full md:mt-8 sm:col-span-2'>
                 <label htmlFor='lastName' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                  Company Name <span className="text-red-500">*</span>
                 </label>
                 <input
                   type='text'
                   name='companyName'
                   id='companyName'
                   autoComplete='family-name'
                   value={formData.companyName}
                   onChange={handleInputChange}
                   className='block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6'
                 />
               </div>
               
               <div className='col-span-full md:mt-8 sm:col-span-2'>
                <label htmlFor='email' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                  Company Email <span className="text-red-500">*</span>
                </label>
                <input
                  type='email'
                  name='companyEmail'
                  id='companyEmail'
                  autoComplete='email'
                  value={formData.companyEmail}
                  onChange={handleInputChange}
                  className={`block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700 sm:text-sm sm:leading-6 ${isEmailInvalid ? 'border-red-500' : ''}`}
                />
                {errorMessage && <p className="text-red-500 text-xs font-semibold mt-2">{errorMessage}</p>}
              </div>
              <div className='col-span-full md:mt-8 sm:col-span-2'>
                 <label htmlFor='phoneNumber' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                   Phone Number
                 </label>
                 <input
                   type='number'
                   name='phoneNumber'
                   id='phoneNumber'
                   autoComplete='tel'
                   value={formData.phoneNumber}
                   onChange={handleInputChange}
                   className='block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6'
                 />
               </div>
               <div className="col-span-full">
              <label htmlFor="about" className="block text-sm dark:text-ioty__white font-medium leading-6 text-gray-900">
                About
              </label>
              <div className="mt-2">
                <textarea
                  id="about"
                  name="about"
                  type='text'                
                  defaultValue={formData.about}
                  onChange={handleInputChange}
                  rows={3}
                  className="block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6"
                 
                />
              </div>
            </div>
               <div className='col-span-full'>
                 <label htmlFor='address' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                   Company Address
                 </label>
                 <input
                   type='text'
                   name='address'
                   id='address'
                   autoComplete='street-address'
                   value={formData.address}
                   onChange={handleInputChange}
                   className='block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6'
                 />
               </div>
           
               <div className='col-span-full sm:col-span-2'>
                 <label htmlFor='city' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                   City
                 </label>
                 <input
                   type='text'
                   name='city'
                   id='city'
                   autoComplete='address-level2'
                   value={formData.city}
                   onChange={handleInputChange}
                   className='block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6'
                 />
               </div>
               <div className='col-span-full sm:col-span-2'>
                 <label htmlFor='state' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                   State
                 </label>
                 <input
                   type='text'
                   name='state'
                   id='state'
                   autoComplete='address-level1'
                   value={formData.state}
                   onChange={handleInputChange}
                   className='block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6'
                 />
               </div>
             
              
               <div className='col-span-full sm:col-span-2'>
                 <label htmlFor='zipCode' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                   Zip Code
                 </label>
                 <input
                   type='text'
                   name='zipCode'
                   id='zipCode'
                   autoComplete='postal-code'
                   value={formData.zipCode}
                   onChange={handleInputChange}
                   className='block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6'
                 />
               </div>
               
             </div>
           </div>
           
           <div className='flex mt-2 justify-end'>
            
            <Link to="/profile">
            <button
             
             className='inline-flex items-center px-8 py-2 border border-transparent text-sm  font-medium rounded-md shadow-sm  bg-ioty__white dark:bg-gray__900 text-ioty__black  focus:outline-none focus:border-blue-700   dark:text-ioty__white transition duration-150 ease-in-out disabled:opacity-50'
            
           >
             Cancel
           </button>
            </Link>
            {companyUserInfo ?  <button
              
            
              className='inline-flex items-center ml-1 px-8 py-2 border border-transparent text-sm  font-medium rounded-md shadow-sm text-white bg-ioty__primary dark:bg-gray__700  focus:outline-none focus:border-blue-700  transition duration-150 ease-in-out disabled:opacity-50'
               disabled={isFormIncomplete || isEmailInvalid ||  errorMessage}
            >
                {loading.type === 'company_write' && loading.isLoad  ? (
                     <FontAwesomeIcon
                       icon={faSpinner}
                       className="animate-spin mr-1.5 h-4 w-4"
                     />
                   )  : 'Edit'}
            </button>: <button
              
              onClick={updateCompanyData}  
              className='inline-flex items-center ml-1 px-8 py-2 border border-transparent text-sm  font-medium rounded-md shadow-sm text-white bg-ioty__primary dark:bg-gray__700  focus:outline-none focus:border-blue-700  transition duration-150 ease-in-out disabled:opacity-50'
               disabled={isFormIncomplete || isEmailInvalid ||  errorMessage }
            >
                {loading.type === 'company_write' && loading.isLoad  ? (
                     <FontAwesomeIcon
                       icon={faSpinner}
                       className="animate-spin mr-1.5 h-4 w-4"
                     />
                   )  : 'Save'}
            </button>}
            
           </div>
         </div>
    
      </div>
    </div>)
}