
import { faMessage, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { chatTabAction } from "../../redux/slices/common/modalTheme";
  export default function ChatTab() {
    const dispatch = useDispatch()
    return (
      <div>
       
        <div className="hidden  sm:block">
          <nav className="isolate flex divide-x-2 divide-primary dark:border-primary rounded-lg shadow" aria-label="Tabs">
              <button       
              onClick={(e)=>dispatch(chatTabAction(1))}
                className ='group relative min-w-0 flex-1 overflow-hidden bg-white dark:bg-gray__700 py-2 dark:text-white px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10'
               
              >
                <span><FontAwesomeIcon className="mx-3" icon={faMessage}  />Chat</span>
                <span
                  aria-hidden="true"
                  className='bg-transparent absolute inset-x-0 bottom-0 h-0.5'
                
                />
              </button>
              <button
               
               onClick={(e)=>dispatch(chatTabAction(2))}
               className ='group relative min-w-0 flex-1 overflow-hidden bg-white dark:bg-gray__700 py-2 dark:text-white px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10'
              
             >
               <span><FontAwesomeIcon icon={faUsers} className="mx-3 "/>Chat List</span>
               <span
                 aria-hidden="true"
                 className='bg-transparent absolute inset-x-0 bottom-0 h-0.5'
               
               />
             </button>
             <button
               
               onClick={(e)=>dispatch(chatTabAction(2))}
               className ='group relative min-w-0 flex-1 overflow-hidden bg-white dark:bg-gray__700 py-2 dark:text-white px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10'
              
             >
               <span>Hotspots</span>
               <span
                 aria-hidden="true"
                 className='bg-transparent absolute inset-x-0 bottom-0 h-0.5'
               
               />
             </button>
           
          </nav>
        </div>
      </div>
    )
  }
  