
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function 
Example(props) {
    const {project} = props
  return (
    <div>
      <h2 className="text-sm font-medium text-gray-500 dark:text-gray__200">Collaborate with:</h2>
      <ul role="list" className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3">
        { project &&project.collaboration && project.collaboration.map((item, index) => (
          <li key={index} className="col-span-1 flex rounded-md shadow-sm">
            <div
             >
              <img className='w-24 h-28 rounded' src={item.companyInfo.photoURL}></img>
            </div>
            <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white dark:bg-gray__700">
              <div className="flex-1 truncate px-4 py-2 text-sm">
                <a href={project.href} className="font-medium text-gray-900 hover:text-gray-600  dark:text-gray__200">
                  {item.companyInfo.companyName}
                </a>
                <p className="text-gray-500 dark:text-gray__200">{item.companyInfo.companyEmail} Members</p>
                <p className='font-roboto text-xs  dark:text-gray__200'>Access</p>
                {item.accessTab && item.accessTab.map((i, index) => (
                        <button className='bg-primary px-4 py-1 rounded text-white' key={index + 'i'}>{i.categoryName}</button>
                    ))}
              </div>
              
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
