import React, { useState, useEffect } from "react";
import {
  faAdd,
  faPlus,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Switch } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { categoriesUpdate,setProjectCategoryCutome,fetchProjectInfo, AllCategories,updatedCategoriesAction } from "../../redux/slices/project/projects";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {upLoadSubCategoryImage} from '../../redux/slices/common/fileUpload'


import {notificationTostaAction} from '../../redux/slices/common/modalTheme'


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}


function ProjectCategory() { 
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const { categories } = useSelector((state) => state.projectSlice);
  const {isLoading, subCategoryImage } = useSelector((state) => state.fileUpload);


  const [enabled, setEnabled] = useState(false);

  const [formData, setFormData] = useState({
    category: "",
    categories: [],
    subCategory: [],
    nameSubCategory: "",
    categoryNew: "",
    categoriesNew: [],
    subCategoryNew: [],
    nameSubCategoryNew: "",
    photoURL:
      "",
  });

  const [targetImage, setTargetImage] = useState("vover");

  function generateRandomId() {
    return Math.random().toString(36).substr(2, 9);
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const catagoriesHandelar = async () => {
    const { category, categoryNew, subCategoryNew, categoriesNew, subCategory } = formData;
  
    if (categoryNew.trim() !== "") {
      // Check if the categoryNew already exists in the categoriesNew array
      const isDuplicate = categoriesNew.some((item) => item.categoryName === categoryNew);
  
      if (isDuplicate) {
        dispatch(notificationTostaAction({
          status:'error', message:' Category already Selected'
        }))
        return;
      }
  
      const newCategory = {
        categoryName: categoryNew,
        categoryId: generateRandomId(),
        subCategoryStatus: enabled,
        subCategory: subCategoryNew,
      };
  
      const updatedCategories = Array.isArray(categoriesNew)
        ? [...categoriesNew, newCategory]
        : [newCategory];
      
      await setFormData((prevFormData) => ({
        ...prevFormData,
        categoriesNew: updatedCategories,
        categoryNew: "",
        subCategoryNew: subCategory,
      }));
  
      dispatch(setProjectCategoryCutome(updatedCategories));
    }
  };

  const subCatagoriesHandelar = async () => {
    const { photoURL, nameSubCategoryNew, subCategoryNew } = formData;

    if (nameSubCategoryNew.trim() !== "") {
      const newSubCategory = {
        subCategoryName: nameSubCategoryNew,
        subCategoryId: generateRandomId(),
        hotspotImage: photoURL,
      };

      const updatedSubCategories = [...subCategoryNew, newSubCategory];

      await setFormData((prevFormData) => ({
        ...prevFormData,
        subCategoryNew: updatedSubCategories,
      }));
      setFormData((prevFormData) => ({
        ...prevFormData,
        nameSubCategoryNew: '',
        photoURL:''
      }));
    }
   
  };

  const removeCategory = async (index) => {
    const updatedCategories = [...formData.categoriesNew];
    updatedCategories.splice(index, 1);
  
    await setFormData((prevFormData) => ({
      ...prevFormData,
      categoriesNew: updatedCategories,
    }));
    dispatch(setProjectCategoryCutome(updatedCategories));

    // After removing the category, you need to dispatch the updated categories array, not newCategory
  };
  
  const removeSubCategory = (index) => {
    const updatedCategories = [...formData.subCategoryNew];
    updatedCategories.splice(index, 1);

    setFormData((prevFormData) => ({
      ...prevFormData,
      subCategoryNew: updatedCategories,
    }));
  };
  useEffect(() => {
    dispatch(AllCategories());
  }, []);

  useEffect(() => {
    if (categories) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        categories: categories[0].categories,
      }));
    }
  }, [categories]);
  useEffect(() => {
    dispatch(fetchProjectInfo(projectId));
  }, [projectId]);

  const { project } = useSelector((state) => state.projectSlice);
 
  const handelSubCategoryImage = (event) => {
    setTargetImage("suCategory");
    if (event.target.files[0]) {
      dispatch(
        upLoadSubCategoryImage(event.target.files[0])
      );
      event.target.value = null;
    }
  };
  useEffect(()=>{
    if (subCategoryImage) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        photoURL: subCategoryImage,
      }));
    }
  },[subCategoryImage])
  const validations = ()=>{
    const {categoryNew, subCategoryNew } = formData
    if(!enabled && categoryNew) {
        return false
    }
    else if (enabled &&  subCategoryNew.length > 0){
      return false
    }
    else {
     return true
    }
  }
   const validationsSubcategory =()=>{
     if(enabled && formData.nameSubCategoryNew && formData.photoURL ) {
      return false
     }
     else {
      return true
     }
   }  
   const catagoriesHandelarProject = () =>{
    dispatch(setProjectCategoryCutome(formData.categoriesNew))
   } 
   const castomeList =(item) =>{
    setFormData((prevFormData)=>({
        ...prevFormData,
        categoryNew: item.categoryName, 
        subCategoryNew: item.subCategory, 
        nameSubCategoryNew: "",
        }))
        setEnabled(item.subCategoryStatus
            )
   }
   useEffect(() => {
    if (project && project.categories) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        categoriesNew: project.categories,
      }));
    }
  }, [project]);

  const {  projectCategory } = useSelector((state) => state.projectSlice);

  useEffect(() => {
    if (projectCategory) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        categoriesNew: projectCategory,
      }));
    }
  }, [projectCategory]);

  const catagoriesHandelarProjectSave = ()=>{
    dispatch(updatedCategoriesAction(formData.categoriesNew,projectId))
  }
  const projectClear = () =>{
    setFormData((prevFormData) => ({
      ...prevFormData,
      categoriesNew: [],
    }));
  }
  return (
    <div className="mx-auto px-0 md:container mt-2 dark:bg-gray__900">
      <div className="mx-auto w-full max-w-[1270px]  mb-0 rounded-b-lg     pb-5 2xl:mb-0">
        <div className="mt-2 sm:mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 dark:bg-gray__700 md:p-5 border  border-stroke dark:border-gray__800 bg-white dark:bg-gray__800 pb-5 rounded">
        <div className="col-span-full  sm:col-span-3 mx-5">
          <div className="col-span-full mt-10 sm:mt-0 bg-primary  ">
          <div className="flex w-full   border-l-[6px]  border-primary  px-7  shadow-md md:p-3">
              
              <p className=' py-1 text-sm md:text-lg font-semibold text-white'>Select Category  </p>
            </div>
            <label
              htmlFor="photo"
              className="block text-primary  text-md xl:text-xl    text-gray__900  font-roboto"
            >
             
            </label>
            <p className="text-xs px-3 py-1 text-gray__300 my-2 font-roboto">You cannot add a new category directly, but you can select a category and then add a subcategory under that selected category.</p>
          </div>

    <ul role="list" className="">
        {formData.categories && formData.categories.map((item, index) => (
          <div>
          <li key={item.categoryId} onClick={() => castomeList(item)} className="col-span-1 flex shadow-sm mt-0 mb-1">
            <div
              className='flex w-16 bg-primary cursor-pointer flex-shrink-0 items-center justify-center  text-sm font-medium text-white'
              
            >
              {index+1}
            </div>
            <div className="flex flex-1 items-center justify-between truncate border-b border-r border-t border-gray__200 bg-white">
              <div className="flex-1 truncate px-4  text-sm">
                <h1  className="text-gray-900 hover:text-gray-600">
                Category Name: <span className="font-semibold ">{item.categoryName}</span>
                </h1>
                <p className="text-gray-500">Id:{item.categoryId} </p>
              </div>
             
              <div><button className="text-gray__900 mx-3 text-white text-sm bg-primary p-1 px-4 rounded">Select</button></div>

            </div>
          </li>
          <div className="col-span-full ml-16">
              <ul role="list" className="">
                  {item.subCategory && item.subCategory.map((item, index) => (
                    <li key={item.categoryId} className="col-span-1 flex  shadow-sm mt-0">
                      <div
                        className='flex w-12 bg-primary dark:bg-gray__900 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white'
                        
                      >
                        <img src={item.hotspotImage}></img>
                      </div>
                      <div className="flex flex-1 items-center justify-between truncate  border-b border-r border-t border-gray__200 bg-white">
                        <div className="flex-1 truncate px-4 text-xs">
                          <h1  className="font-medium text-gray-900 hover:text-gray-600">
                          Sub Category : {item.subCategoryName}
                          </h1>
                          <p className="text-gray-500">Id: {item.subCategoryId} </p>
                        </div>
                         
                      </div>
                     
                    </li>
                  ))}
                </ul>
              </div>
          </div>
        ))}
      </ul>
          </div>
          <div className="col-span-full  sm:col-span-3 mx-5 ">
          <div className="flex w-full   border-l-[6px]  border-primary bg-primary rounded  px-7  shadow-md md:p-3">
              
              <p className=' py-1 font-semibold text-sm md:text-lg text-white'>Add Category & Subcategory  </p>
            </div>
        
            <div className="mt-2">
              <label
                htmlFor="lastName"
                className="block dark:text-gray__200 text-xs font-medium leading-6 text-gray__900  font-roboto"
              >
                Category Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="category"
                id="category"
                autoComplete="family-name" 
                value={formData.categoryNew}
                disabled 
                    onChange={handleInputChange}
                className="block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6"
              />
            </div>
            <div>
              <label
                htmlFor="lastName"
                className="block dark:text-gray__200 text-xs font-medium leading-6 text-gray__900 my-3  font-roboto"
              >
                Add Sub Category 
              </label>
              <Switch
                checked={enabled}
                onChange={setEnabled}
                className={classNames(
                  enabled ? "bg-primary" : "bg-primary",
                  "relative inline-flex h-4 w-9 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
              
              <ul role="list" className="">
                  {formData.subCategoryNew && formData.subCategoryNew.map((item, index) => (
                    <li key={item.categoryId} className="col-span-1 flex shadow-sm ">
                      <div
                        className='flex w-16 bg-gray__900 flex-shrink-0 items-center justify-center text-sm font-medium text-white'
                        
                      >
                        <img src={item.hotspotImage}></img>
                      </div>
                      <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                        <div className="flex-1 truncate px-4 py-2 text-sm">
                          <h1  className="font-medium text-gray-900 hover:text-gray-600">
                          Sub Category : {item.subCategoryName}
                          </h1>
                          <p className="text-gray-500">Id: {item.subCategoryId} </p>
                        </div>
                        <div className="flex-shrink-0 pr-2">
                          <button
                            type="button"
                            onClick={() => removeSubCategory(index)} 
                            className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none "
                          >
                            <span className="sr-only">Open options</span>
                            <FontAwesomeIcon
                                icon={faTrash}
                                className="text-red-400 mr-2"
                              />
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              {enabled && (<>
                <div className="col-span-full sm:col-span-3">

                <div className=" mb-5 ">
                  <label
                    htmlFor="lastName"
                    className="block dark:text-gray__200 text-xs font-medium leading-6 text-gray__900 mb-2  font-roboto"
                  >
                   Sub Category Image <span className="text-red-500">*</span>
                  </label>
                  {
                    !formData.photoURL? (<div className="bg-blue-600 h-16 w-16"></div>):(<> <img
                      src={formData.photoURL}
                      alt="profile cover rounded-xl"
                      className="h-16 w-16 "
                    /></>)
                  }
                  <div className=" bottom-5 mb-2 right-5 z-10">
                    <label className="flex bg-gray-700 ml-24 -mt-12 h-9 w-9 cursor-pointer items-center justify-center rounded-full bg-primary text-gray__500 hover:bg-opacity-90">
                      <input
                        type="file"
                        onChange={handelSubCategoryImage}
                        name="suCategory"
                        id="suCategory"
                        className="sr-only bg-gray__900"
                      />
                      {isLoading && targetImage === "suCategory" ? (
                        <FontAwesomeIcon
                          icon={faSpinner}
                          className="animate-spin mr-1.5 h-4 w-4"
                        />
                      ) : (
                        <span>
                          <FontAwesomeIcon
                            icon={faPlus}
                            className=" h-5 text-white w-5"
                          />
                        </span>
                      )}
                    </label>
                  </div>
                </div>

                <div className="relative">
                  <input
                    type="text"
                    name="nameSubCategoryNew"
                    id="nameSubCategoryNew"
                    autoComplete="family-name"
                    value={formData.nameSubCategoryNew}
                    onChange={handleInputChange}
                    className="block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6"
                  />
                  <button
                    disabled ={ validationsSubcategory()}
                      onClick={subCatagoriesHandelar}
                    className="absolute top-0 right-0 bg-primary text-gray__200  h-[96%] px-2 rounded flex items-center text-sm  font-roboto"
                  >
                    <FontAwesomeIcon
                      icon={faAdd}
                     
                      className="text-gray__200 mr-2"
                    />{" "}
                    Add
                  </button>
                </div>
              </div></>)}
             
              <div className="flex mt-2 justify-end mr-0 mt-5">
                <button
                  onClick={catagoriesHandelar}
                  disabled={validations()}

                  className="inline-flex items-center ml-1 px-8 py-1.5 border border-transparent text-sm  font-medium rounded-md shadow-sm text-white bg-primary dark:bg-gray__700  focus:outline-none focus:border-blue-700  transition duration-150 ease-in-out disabled:opacity-50  font-roboto"
                >
                  Set
                </button>
              </div>
             {formData.categoriesNew && formData.categoriesNew.length> 0 &&( <ul role="list" className="mt-2">
              
              <label
                    htmlFor="lastName"
                    className="block dark:text-gray__200 text-xs font-medium leading-6 text-gray__900 mb-2  font-roboto"
                  >
                   Your Selected Category {project&&project.projectName &&( <span className="text-primary">-{project.projectName}</span>)}
                  </label>
        {formData.categoriesNew && formData.categoriesNew.map((item, index) => (
          <div className="mb-1">
          <li key={item.categoryId} onClick={() => castomeList(item)} className="col-span-1 flex shadow-sm mt-0">
            <div
              className='flex w-14 bg-primary  flex-shrink-0 items-center justify-center  text-sm font-medium text-white'
              
            >
              {index+1}
            </div>
            <div className="flex flex-1 items-center justify-between truncate border-b border-r border-t border-gray__200 bg-white">
              <div className="flex-1 truncate px-4  text-xs">
                <h1  className="font-medium  text-gray-900 hover:text-gray-600">
                Category Name: {item.categoryName}
                </h1>
                <p className="text-gray-500">Id:{item.categoryId} </p>
              </div>
              <div className="flex-shrink-0 pr-2">
                <button
                  type="button"
                  onClick={() => removeCategory(index)} 
                  className="inline-flex h-8 w-8 items-center justify-center  bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none "
                >
                  <span className="sr-only">Open options</span>
                  <FontAwesomeIcon
                      icon={faTrash}
                      className="text-red-400 mr-2"
                    />
                </button>
              </div>
           
            </div>
            
          </li>
          <div className="col-span-full ">
              <ul role="list" className="">
                  {item.subCategory && item.subCategory.map((item, index) => (
                    <li key={item.categoryId} className="col-span-1 flex  shadow-sm mt-0">
                      <div
                        className='flex w-14 h-10 bg-primary  flex-shrink-0 items-center justify-center text-sm font-medium text-white'
                        
                      >
                        <img className="p-4" src={item.hotspotImage}></img>
                      </div>
                      <div className="flex flex-1 items-center justify-between truncate  border-b border-r border-t border-gray__200 bg-white">
                        <div className="flex-1 truncate px-4 text-xs">
                          <h1  className="font-medium text-gray-900 hover:text-gray-600">
                          Sub Category : {item.subCategoryName}
                          </h1>
                          <p className="text-gray-500">Id: {item.subCategoryId} </p>
                        </div>
                        
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
          </div>
        ))}
          <div className="flex mt-2 justify-end mr-0 mt-5">
               <button
                 onClick={projectClear}
                  className="inline-flex hidden md:inline items-center ml-1 px-8 py-1.5 border border-transparent text-sm  font-medium rounded-md shadow-sm text-white bg-primary dark:bg-gray__700  focus:outline-none focus:border-blue-700  transition duration-150 ease-in-out disabled:opacity-50  font-roboto"
                >
                 Clear 
                </button>
                {project && project.companyId && ( <Link to={`/project/${project.companyId}/edit/${project.projectId}`}>
               <button
                  onClick={catagoriesHandelarProject}
                
                  className="inline-flex items-center ml-1 px-8 py-1.5 border border-transparent text-sm  font-medium rounded-md shadow-sm text-white bg-primary dark:bg-gray__700  focus:outline-none focus:border-blue-700  transition duration-150 ease-in-out disabled:opacity-50  font-roboto"
                >
                 Back
                </button>
                </Link>)}
              
                
               <button
                  onClick={catagoriesHandelarProjectSave}
                
                  className="inline-flex items-center ml-1 px-8 py-1.5 border border-transparent text-sm  font-medium rounded-md shadow-sm text-white bg-primary dark:bg-gray__700  focus:outline-none focus:border-blue-700  transition duration-150 ease-in-out disabled:opacity-50  font-roboto"
                >
                 Save on Project
                </button>
               
              </div>
      </ul>)}

      
            </div>
          </div>


     
        </div>
      </div>
    </div>
  );
}

export default ProjectCategory;
