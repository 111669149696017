import { createSlice } from "@reduxjs/toolkit";

const modalTheme = createSlice({
  name: "modalTheme",
  initialState: { theme: {
    theme:'light',
    instance:false,
  }, signupModal: false, profileEdit:false,manuSize:{type:false}, loading:{
    type:'',
    isLoad:false
  }, signinModal: false,
  companyEmployeeModal:false,
  companyNewUser:null,
  companyModal:false,
  companyAdminModal:false,
  collaborateModal:false,
  companySection:1,
  chatTab:1,
  projectTab:1,
  settingModal:false,
  companySetting:false,
  projectModal:false,
  projectSection:0,
  varificationModal:false, resetpasswordModal:false, notificationTosta:{
    message: '',
    from: '',
    status: '',
  } },
  reducers: {
    
    changeMode: (state, action) => {
      state.theme.theme = action.payload.type === 'dark' ? 'light' : 'dark';
      state.theme.instance = true
    },
    signUpModal: (state, action) => {
      state.signupModal = !action.payload.type;
    },
    signInModal: (state, action) => {
      state.signinModal = !action.payload.type;
    },
    passwordResetModal: (state, action) => {
      state.resetpasswordModal = !action.payload.type;
    },
    varificationModal: (state, action) => {
      state.varificationModal = !action.payload.type;
    },
    profileEditAction: (state, action) => {
      state.profileEdit = action.payload;
    },
    notificationTostaAction: (state, action) => {
      state.notificationTosta = action.payload;
    },
    setManuSize: (state, action) => {
      state.manuSize = action.payload;
    },
    setLoading: (state, action) => {
      state.loading.type = action.payload.type;
      state.loading.isLoad = action.payload.isLoad;
    },
    companyEdit: (state, action) => {
      state.companyModal = action.payload;
    }, 
    companyAdmin: (state, action) => {
      state.companyAdminModal = action.payload;
    },

    companyEmployAdd: (state, action) => {
      state.companyEmployeeModal = action.payload.type;
      state.companyNewUser = action.payload.user
    },
    settingModal: (state, action) => {
      state.settingModal = action.payload
    },
    settingModalCompany: (state, action) => {
      state.companySetting = action.payload
    },
    companySectionAction: (state, action) => {
     state.companySection = action.payload
    },
    projectSectionAction: (state, action) => {
      state.projectSection = action.payload
     },
    settingModalProject:(state, action)=> {
      state.projectModal = action.payload
     },
    chatTabAction: (state, action) => {
      
      state.chatTab = action.payload
     },
     projectTabAction: (state, action) => {  
      state.projectTab = action.payload
     },
     collaborateModalAction: (state, action) => {  
      state.collaborateModal = action.payload
     }
  }
});

export const { changeMode, signUpModal,companySectionAction,projectTabAction,chatTabAction, setManuSize,setLoading,settingModal, settingModalCompany,settingModalProject, companyEmployAdd, companyEdit, companyAdmin, signInModal,profileEditAction, passwordResetModal, notificationTostaAction, varificationModal, collaborateModalAction, projectSectionAction} =modalTheme.actions;

export default modalTheme.reducer