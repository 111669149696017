import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {allProducts} from '../redux/slices/products/product'
import ProductList from "../components/products/productList";
function Product() {
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(allProducts())
  },[])

  const {products} = useSelector((state) => state.products);

  console.log(products)

    return (
      <div className="Product">
         <>
          <div className="px-2 sm:10">
              <ProductList products= {products}/>
          </div>
         </>
      </div>
    );
  }
  
  export default Product;