import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import LargeDevice from "./layout/LargeDevice";
import Bundles from "./pages/Bundles";
import Companies from "./pages/Companies";
import ErrorPage from "./pages/ErrorPage";
import Home from "./pages/Home";
import Package from "./pages/Package";
import Product from "./pages/Product";
import Profile from "./pages/Profile";
import Project from "./pages/Project";
import Transitions from "./pages/Transitions";
import Users from "./pages/Users";
import Category from "./pages/Category";
import CollaborateProject from "./pages/CollaborateProject";



import ProfileAdminView from "./components/Profile/admin/ProfileAdminView";
import ProfileEditAdmin from "./components/Profile/admin/ProfileEditAdmin";
import Navbar from "./components/common/Navbar";
import NotificationComponent from "./components/common/NotificationComponent";
import Setting from "./components/common/Setting";
import TopBar from "./components/common/TopBar";
import AdminModal from "./components/company/admin/AdminModal";
import CompanyDetails from "./components/company/admin/CompanyDetails";
import EditCompany from "./components/company/admin/EditCompany";
import AddFloorplan from "./components/floorplan/AddFloorplan";
import PorjectDetails from "./components/project/ProjectDetails";
import ProjectEdit from "./components/project/ProjectEdit";
import ProjectUpload from "./components/project/ProjectUpload";
import FloorplanDetails from "./components/floorplan/FloorPlanDetails"; 
import ProjectCategory from "./components/project/ProjectCategory";
import CollaborateCategory from "./components/project/CollaborateCategory";
import UserProjectDetails from './components/project/UserProjectDetails'

import Collaborate from "./components/project/Collaborate";
import CollaborateRequest from "./components/project/CollaborateRequest";
import CollaborateProjectDetails from './components/project/CollaborateProjectDetails'

import { autoLoginAction } from "./redux/slices/auth/auth.js";

import {
  passwordResetModal,
  signInModal,
  signUpModal,
  varificationModal,
} from "./redux/slices/common/modalTheme";

function App() {
  const dispatch = useDispatch();
  const { theme, manuSize, settingModal, companySetting } = useSelector(
    (state) => state.modalTheme
  );
  const { user } = useSelector((state) => state.authentication);
  const [userValue, setUserInfo] = useState(null);
  const [adminRole, setAdminRole] = useState({
    isCompanyAdmin: false,
    isClientAdmin: false,
    isSystemAdmin: true,
    isEmployeeAdmin: false,
    userAdmin: false,
  });
  const { userInfo, notification } = useSelector((state) => state.userInfo);

  React.useEffect(() => {
    if (user && userInfo && userInfo.userInfoData) {
      const updatedUser = {
        ...user,
        name:
          userInfo.userInfoData?.firstName +
          " " +
          userInfo.userInfoData?.lastName,
        photoURL: userInfo.userInfoData?.photoURL,
      };
      setUserInfo(updatedUser);

      setAdminRole(userInfo.isAdmin);
    } else {
      setUserInfo(user);
    }
  }, [userInfo, user]);
  React.useEffect(() => {
    if (theme.instance) {
      if (theme.theme === "dark" && theme.instance) {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
      }
    }
  }, [theme]);

  React.useEffect(() => {
    if (userInfo && userInfo.isDark) {
      if (userInfo.isDark) {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
      }
    }
  }, []);

  React.useEffect(() => {
    dispatch(autoLoginAction());
  }, [dispatch]);

  React.useEffect(() => {
    if (user && user.emailVerified === false) {
      dispatch(varificationModal({ type: false }));
      dispatch(signInModal({ type: true }));
      dispatch(signUpModal({ type: true }));
      dispatch(passwordResetModal({ type: true }));
    }
  }, [user, dispatch]);

  return (
    <BrowserRouter>
      <NotificationComponent />
      <Collaborate />
      <Setting setting={settingModal} user={user} />

      <div className="App hidden lg:block dark:bg-gray__900 bg-white min-h-screen">
        <div className="flex">
          <div
            className={`flex-grow-0 ${user ? "" : "hidden"} ${
              manuSize.type ? "2xl:w-[14%] lg:w-[20%]" : "lg:w-[6%] 2xl:w-[4%]"
            } ml-0  bg-white dark:bg-gray__800`}
          >
            <LargeDevice value={notification} />
          </div>

          <div
            className={`flex-grow-0  ${
              manuSize.type && user
                ? "2xl:w-[86%] lg:w-[80%]"
                : "lg:w-[94%] 2xl:w-[96%]"
            } dark:bg-gray__900 bg-ioty__white min-h-screen ${
              user ? "" : "2xl:w-screen lg:w-screen"
            }`}
          >
            <div className="flex ">
              <div className="flex-grow  ">
                <TopBar
                  value={userValue}
                  notification={notification}
                  adminRole={adminRole}
                />
              </div>
            </div>
            <Routes> 
              <Route exact path="/" element={<Home />} />
              <Route exact path="/company/:companyId/project/:projectId/details" element={<UserProjectDetails />} />
              <Route
                exact
                path="/project-category/:projectId"
                element={<ProjectCategory />}
                
              />
                 <Route
              exact
              path="/collaborate-category/:projectId"
              element={<CollaborateCategory />}
            />
              {userValue && adminRole && adminRole.userAdmin && (
                <>
                  <Route path="/comapny/:userId" />

                  <Route exact path="/project" element={<Project />} />
                  <Route exact path="/company" element={<Companies />} />
                  
                  <Route
                    exact
                    path="/company/:companyId/project/:projectId"
                    element={<PorjectDetails />}
                  />
                   <Route
                    exact
                    path="/company/:companyId/project/collaborate/:projectId"
                    element={<CollaborateProjectDetails />}
                  />
                  <Route
                    exact
                    path="/project/:companyId/edit/:projectId"
                    element={<ProjectEdit />}
                  />
                  <Route
                    exact
                    path="/company/:companyId/project/:projectId/new-floorpaln"
                    element={<AddFloorplan />}
                  />
                  <Route
                    exact
                    path="/company/:companyId/project/:projectId/floorplan/:floorplanId"
                    element={<FloorplanDetails />}
                  />

                  <Route exact path="/profile" element={<Profile />} />
                  <Route path="*" element={<Navigate to="/error" />} />
                  <Route path="/error" element={<ErrorPage />} />
                </>
              )}
              {userValue && adminRole && adminRole.isCompanyAdmin && (
                <>
                  <Route
                    exact
                    path="/company/porject/upload/:id"
                    element={<ProjectUpload />}
                  />
                  <Route
                    exact
                    path="/company/:companyId/project/:projectId"
                    element={<PorjectDetails />}
                  />
                  <Route
                    exact
                    path="/project/:companyId/edit/:projectId"
                    element={<ProjectEdit />}
                  />
                  <Route
                    exact
                    path="/company/:companyId/project/:projectId/new-floorpaln"
                    element={<AddFloorplan />}
                  />
                  <Route
                    exact
                    path="/company/:companyId/project/:projectId/floorplan/:floorplanId"
                    element={<FloorplanDetails />}
                  />
                  <Route
                    exact
                    path="/company/:companyId/projects/collaborate"
                    element={<CollaborateProject />}
                  />
                  <Route
                    exact
                    path="/company/:companyId/project/collaborate/:projectId"
                    element={<CollaborateProjectDetails />}
                  />
                  <Route exact path="/company" element={<Companies />} />
                  <Route exact path="/project" element={<Project />} />
                  <Route exact path="/profile" element={<Profile />} />
                  <Route exact path="/bundles" element={<Bundles />} />
                  <Route
                    exact
                    path="/companies/edit/:id"
                    element={<EditCompany />}
                  />
                  <Route path="/profile/:id" element={<ProfileAdminView />} />
                  <Route
                    path="/profile/edit/:id"
                    element={<ProfileEditAdmin />}
                  />
                  <Route exact path="/package" element={<Package />} />
                  <Route exact path="/product" element={<Product />} />
                  <Route path="/error" element={<ErrorPage />} />
                  <Route path="*" element={<Navigate to="/error" />} />
                </>
              )}
              {userValue && adminRole && adminRole.isSystemAdmin && (
                <>
                  <Route
                    exact
                    path="company/porject/upload/:id"
                    element={<ProjectUpload />}
                  />
                  <Route exact path="/companies" element={<Companies />} />
                  <Route exact path="/company" element={<Companies />} />
                  <Route exact path="/bundles" element={<Bundles />} />
                  <Route exact path="/category" element={<Category />} />
                  <Route
                    exact
                    path="/company/:companyId/project/collaborate/:projectId"
                    element={<CollaborateProjectDetails />}
                  />
                  <Route
                    exact
                    path="/company/:companyId/project/:projectId"
                    element={<PorjectDetails />}
                  />
                  <Route
                    exact
                    path="/project/:companyId/edit/:projectId"
                    element={<ProjectEdit />}
                  />
                  <Route
                    exact
                    path="/company/:companyId/project/:projectId/new-floorpaln"
                    element={<AddFloorplan />}
                  />
                  <Route
                    exact
                    path="/company/:companyId/project/:projectId/floorplan/:floorplanId"
                    element={<FloorplanDetails />}
                  />
                  <Route
                    exact
                    path="/company/:companyId/projects/collaborate"
                    element={<CollaborateProject />}
                  />

                  <Route exact path="/package" element={<Package />} />
                  <Route exact path="/product" element={<Product />} />
                  <Route
                    exact
                    path="/companies/:id"
                    element={<CompanyDetails />}
                  />
                  <Route
                    exact
                    path="/companies/edit/:id"
                    element={<EditCompany />}
                  />
                  <Route path="/profile/:id" element={<ProfileAdminView />} />
                  <Route
                    path="/profile/edit/:id"
                    element={<ProfileEditAdmin />}
                  />
                  <Route exact path="/project" element={<Project />} />
                  <Route exact path="/transition" element={<Transitions />} />
                  <Route exact path="/users" element={<Users />} />
                  <Route exact path="/profile" element={<Profile />} />
                  <Route path="*" element={<Navigate to="/error" />} />
                  <Route path="/error" element={<ErrorPage />} />
                </>
              )}
            </Routes>
          </div>
        </div>
      </div>

      <div className="lg:hidden">
        <div className="App dark:bg-gray__900 bg-ioty__white min-h-screen">
          <AdminModal />
          <Navbar
            value={userValue}
            notification={notification}
            adminRole={adminRole}
          />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/company/:companyId/project/:projectId/details" element={<UserProjectDetails />} />

            <Route
              exact
              path="/project-category/:projectId"
              element={<ProjectCategory />}
            />
            <Route
              exact
              path="/collaborate-category/:projectId"
              element={<CollaborateCategory />}
            />
            <Route path="/profile/edit/:id" element={<ProfileEditAdmin />} />
            {userValue && adminRole && adminRole.userAdmin && (
              <>
                <Route exact path="/company" element={<Companies />} />
                <Route exact path="/project" element={<Project />} />
                <Route
                  exact
                  path="/company/:companyId/project/:projectId"
                  element={<PorjectDetails />}
                />
                <Route
                    exact
                    path="/company/:companyId/project/collaborate/:projectId"
                    element={<CollaborateProjectDetails />}
                  />
                <Route
                  exact
                  path="/project/:companyId/edit/:projectId"
                  element={<ProjectEdit />}
                />
                <Route
                  exact
                  path="/company/:companyId/project/:projectId/new-floorpaln"
                  element={<AddFloorplan />}
                />
                <Route
                  exact
                  path="/company/:companyId/project/:projectId/floorplan/:floorplanId"
                  element={<FloorplanDetails />}
                />

                <Route
                  exact
                  path="/profile"
                  adminRole={adminRole}
                  element={<Profile />}
                />
                <Route path="*" element={<Navigate to="/error" />} />
                <Route path="/error" element={<ErrorPage />} />
              </>
            )}
            {userValue && adminRole && adminRole.isCompanyAdmin && (
              <>
                <Route
                  exact
                  path="company/porject/upload/:id"
                  element={<ProjectUpload />}
                />
                <Route exact path="/company" element={<Companies />} />
                <Route exact path="/project" element={<Project />} />
                <Route
                  exact
                  path="/company/:companyId/project/:projectId"
                  element={<PorjectDetails />}
                />
                <Route
                    exact
                    path="/company/:companyId/project/collaborate/:projectId"
                    element={<CollaborateProjectDetails />}
                  />
                <Route
                  exact
                  path="/project/:companyId/edit/:projectId"
                  element={<ProjectEdit />}
                />
                <Route
                  exact
                  path="/company/:companyId/project/:projectId/new-floorpaln"
                  element={<AddFloorplan />}
                />
                <Route
                  exact
                  path="/company/:companyId/project/:projectId/floorplan/:floorplanId"
                  element={<FloorplanDetails />}
                />
                <Route
                  exact
                  path="/company/:companyId/projects/collaborate"
                  element={<CollaborateProject />}
                />

                <Route
                  exact
                  path="/profile"
                  adminRole={adminRole}
                  element={<Profile />}
                />
                <Route
                  exact
                  path="/companies/edit/:id"
                  element={<EditCompany />}
                />
                <Route path="/profile/:id" element={<ProfileAdminView />} />
                <Route
                  path="/profile/edit/:id"
                  element={<ProfileEditAdmin />}
                />

                <Route exact path="/bundles" element={<Bundles />} />
                <Route exact path="/package" element={<Package />} />
                <Route exact path="/product" element={<Product />} />
                <Route path="*" element={<Navigate to="/error" />} />
                <Route path="/error" element={<ErrorPage />} />
              </>
            )}
            {userValue && adminRole && adminRole.isSystemAdmin && (
              <>
                <Route
                  exact
                  path="company/porject/upload/:id"
                  element={<ProjectUpload />}
                />
                <Route exact path="/companies" element={<Companies />} />
                <Route exact path="/bundles" element={<Bundles />} />
                <Route exact path="/company" element={<Companies />} />
                <Route exact path="/category" element={<Category />} />

                <Route
                  exact
                  path="/company/:companyId/project/:projectId"
                  element={<PorjectDetails />}
                />
                <Route
                  exact
                  path="/project/:companyId/edit/:projectId"
                  element={<ProjectEdit />}
                />
                <Route
                  exact
                  path="/company/:companyId/project/:projectId/new-floorpaln"
                  element={<AddFloorplan />}
                />
                <Route
                    exact
                    path="/company/:companyId/project/collaborate/:projectId"
                    element={<CollaborateProjectDetails />}
                  />
                <Route
                  exact
                  path="/company/:companyId/project/:projectId/floorplan/:floorplanId"
                  element={<FloorplanDetails />}
                />
                <Route
                  exact
                  path="/company/:companyId/projects/collaborate"
                  element={<CollaborateProject />}
                />

                <Route exact path="/package" element={<Package />} />
                <Route
                  exact
                  path="/companies/:id"
                  element={<CompanyDetails />}
                />
                <Route
                  exact
                  path="/companies/edit/:id"
                  element={<EditCompany />}
                />
                <Route path="/profile/:id" element={<ProfileAdminView />} />
                <Route
                  path="/profile/edit/:id"
                  element={<ProfileEditAdmin />}
                />
                <Route exact path="/product" element={<Product />} />
                <Route exact path="/project" element={<Project />} />
                <Route exact path="/transition" element={<Transitions />} />
                <Route exact path="/users" element={<Users />} />
                <Route
                  exact
                  path="/profile"
                  adminRole={adminRole}
                  element={<Profile />}
                />
                <Route path="*" element={<Navigate to="/error" />} />
                <Route path="/error" element={<ErrorPage />} />
              </>
            )}
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
