import { faBuilding, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import UAParser from "ua-parser-js";
import { settingModalCompany,settingModalProject, projectSectionAction } from "../../redux/slices/common/modalTheme";
import { themeUpdate } from "../../redux/slices/user/user";

import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function ProjectSetting(props) {
  const [loginDevice, setLoginDevice] = useState([]);


  const { user } = useSelector((state) => state.authentication);

  const { userInfo } = useSelector((state) => state.userInfo);
  const { projectModal } = useSelector((state) => state.modalTheme);


  const dispatch = useDispatch();
  const [enabled, setEnabled] = useState(false);

  const { setting, adminStatus } = props;
  const hadleSetting = () => {
    dispatch(settingModalProject(false));
  };
  const handlarTheamChange = () => {
    setEnabled(!enabled);
    dispatch(themeUpdate(!enabled, userInfo.userInfoData.uid));
  };

  useEffect(() => {
    if (userInfo && userInfo.isDark) {
      setEnabled(userInfo.isDark);
    }
  }, [userInfo]);
  useEffect(() => {
    const parser = new UAParser();
    const parsedArray = [];
    if (user && user.device) {
      for (const data of user.device) {
        const deviceName = data.deviceName;
        const result = deviceName ? parser.setUA(deviceName).getDevice() : null;
        if (result && result.type) {
          parsedArray.push(result);
        }
      }
      setLoginDevice(parsedArray);
    }
  }, [user]);

  return (
    <Transition.Root show={projectModal} onClick={hadleSetting} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0  right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen  max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white dark:bg-gray__800  py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className=" text-xl font-roboto text-primary  leading-6 text-gray-900">
                          Company Setting & Privacy
                          <p className="text-xs mt-2">
                            All Company Setting & privacy will be here.
                          </p>
                        </Dialog.Title>
                        <div className="ml-3 flex  h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white dark:bg-gray__800 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={hadleSetting}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon
                              className="h-6 w-6 text-primary"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      <div>
                        <h1 className="mt-10  font-roboto  text-md">
                          {" "}
                          <button className="p-1.5 rounded-full bg-gray__200  font-roboto  dark:bg-gray__700 ">
                            <FontAwesomeIcon
                              icon={faBuilding}
                              className="  h-4 w-4  rounded-full text-gray__400 dark:text-gray__200"
                            />
                          </button>{" "}
                          <span className="mx-2 text-gray__600 dark:text-gray__300">
                            Project
                          </span>
                        </h1>
                        <div className="mt-10">
                        <button  onClick={()=>dispatch(projectSectionAction(2))} className="mt-4  font-roboto  px-1 text-ioty__black dark:text-gray__300">Floorplans</button>
                        </div>
                        <button  onClick={()=>dispatch(projectSectionAction(1))} className="mt-4  font-roboto  px-1 text-ioty__black dark:text-gray__300"> Collaborators</button>
                        </div>
                        <div>
                        
                        {adminStatus && adminStatus === "admin" && (
                          <>
                          

                            {/* <div> <Link ><button  onClick={()=>dispatch(companySectionAction(2))} className="mt-4 px-1  font-roboto  text-ioty__black dark:text-gray__300"><FontAwesomeIcon
                                    icon={faPlus}
                                    className="  h-4 w-4 mr-3 rounded-full text-gray__400 dark:text-gray__200"
                                  /> Add Employee</button></Link></div>
                                 
                                 <div> <Link ><button  onClick={()=>dispatch(companySectionAction(3))} className="mt-4 px-1  font-roboto  text-ioty__black dark:text-gray__300"><FontAwesomeIcon
                                    icon={faProjectDiagram}
                                    className="  h-4 w-4 mr-3 rounded-full text-gray__400 dark:text-gray__200"
                                  /> Projects</button></Link></div> */}
                          </>
                        )}

                        {/* {adminStatus && adminStatus  === 'editor' && (<>
                                  <div className="mt-5"> <Link ><button  onClick={()=>dispatch(companySectionAction(1))} className="mt-4  font-roboto  px-1 text-ioty__black dark:text-gray__300"><FontAwesomeIcon
                                    icon={faUsers}
                                    className="  h-4 w-4 mr-3 rounded-full text-gray__400 dark:text-gray__200"
                                  /> Employees List</button></Link></div>
                                 
                                
                                 
                                 <div> <Link ><button  onClick={()=>dispatch(companySectionAction(3))} className="mt-4 px-1  font-roboto  text-ioty__black  dark:text-gray__300"><FontAwesomeIcon
                                    icon={faProjectDiagram}
                                    className="  h-4 w-4 mr-3 rounded-full text-gray__400 dark:text-gray__200"
                                  /> Projects</button></Link></div>
                                </>)} */}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
