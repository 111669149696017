import React from 'react';
import { Link } from 'react-router-dom';

const Card11 = (props) => {
  const { companyProjects } = props;
  return (
    <section className="lg:mx-2 lg:py-1">
      <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap justify-start">
          {companyProjects &&
            companyProjects.map((item) => (
              <SingleCard key={item.id} item={item} />
            ))}
        </div>
      </div>
    </section>
  );
};

export default Card11;

const SingleCard = ({ item }) => {
  return (
    <div className="w-full px-2 my-2 md:w-1/2 lg:w-1/3">
      <Link to={`/company/${item.companyId}/project/${item.projectId}`}>
        <div className="card-container shadow-three rounded mx-1 lg:mx-0 dark:bg-gray__800 bg-white">
          <div className="mb-6 w-full overflow-hidden rounded">
            <img
              src={item.coverPhoto}
              alt="card imge"
              className="h-48 w-full object-cover object-center"
            />
          </div>

          <div className="px-2 mb-3 relative">
            <div className="manu absolute right-2 top-0"></div>
            <h3 className="dark:text-gray__300 text-xl font-semibold font-roboto">{item.projectName}</h3>
            <h3 className="dark:text-gray__300 text-sm mt-2 font-roboto">{item.projectLocation}</h3>
            <p className="dark:text-gray__300 text-sm pb-5 pt-3 font-roboto">{item.description}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};