import React from 'react';
import { Link } from 'react-router-dom';

const Card11 = (props) => {
  const { floorpans } = props;
  console.log(floorpans, 'floorpansfloorpans')
  return (
    <section className="lg:mx-2 lg:py-1">
      <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap justify-start">
          {floorpans &&
            floorpans.map((item) => (
              <SingleCard key={item.id} item={item} />
            ))}
        </div>
      </div>
    </section>
  );
};

export default Card11;
// to={`/company/${item.companyId}/project/${item.projectId}`}
const SingleCard = ({ item }) => {
  return (
    <div className="w-full px-2 my-2 md:w-1/2 lg:w-1/3">
      <Link to={`/company/${item.companyId}/project/${item.projectId}/floorplan/${item.floorplanId}`}>
        <div className="card-container shadow-three rounded mx-1 lg:mx-0 dark:bg-gray__700 bg-white">
          <div className="mb-6 w-full overflow-hidden rounded">
            <img
              src={item.floorplanImage
              }
              alt="card imge"
              className="h-48 w-full object-cover object-center"
            />
          </div>

          <div className="px-2 mb-3 relative">
            <div className="manu absolute right-2 top-0"></div>
            <h3 className="dark:text-gray__300 text-xl font-semibold font-roboto">{item.floorplanName}</h3>
            
            <p className="dark:text-gray__300 text-sm pb-2 pt-3 font-roboto">{item.description}</p>
            <div>
              <p className='mx-1 mb-1 font-roboto dark:text-gray__200 text-sm'>Units</p>
  {item.units.map((unit) => (
    <button className='mr-1 p-1 dark:bg-gray__800 rounded-lg dark:text-gray__200 text-xs' key={unit}>{unit}</button>
  ))}
</div>
          </div>
        </div>
      </Link>
    </div>
  );
};