import { Menu, Transition } from '@headlessui/react';
import { EllipsisHorizontalIcon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid';
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
export default function EmployeeList(props) {
  const { employee, listEmployee, adminStatus } = props;
  const [people, setPeople] = useState([]);


  useEffect(() => {
    if (employee && listEmployee && employee.length > 0 && listEmployee.length > 0) {
      // Combine data from both arrays when they are not empty
      const combinedData = employee.map((person) => {
        const correspondingAdmin = listEmployee.find(
          (admin) => admin.adminUid === person.uid
        );

        if (correspondingAdmin) {
          return { ...person, ...correspondingAdmin };
        } else {
          return person;
        }
      });

      setPeople(combinedData);
    } else {
      // Use only the employee array when listEmployee is empty or null
      setPeople(employee || []);
    }
  }, [employee, listEmployee]);
  return (
   <>
    <ul role="list" className="grid grid-cols-1 gap-2 mt-1 sm:grid-cols-2 lg:grid-cols-3 ">
      {people && people.map((person, index)=> (
        <li key={index} className="col-span-1 divide-y border border-gray__200 dark:border-gray__800  rounded bg-white dark:bg-gray__800 shadow">
          <div className="flex w-full items-center justify-between space-x-4 p-6 text-gray__200 dark:text-gray__900">
          <img className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300" src={person.photoURL} alt="" />
            <div className="flex-1 truncate text-gray__200 dark:text-gray__900">
              <div className="flex items-center space-x-3 text-gray__200 dark:text-gray__900">
                <h3 className="truncate text-sm font-medium text-gray-900 text-gray__700 dark:text-gray__200">{person.firstName} {person.lastName}</h3>
                <span className="inline-flex flex-shrink-0 items-center  rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                {person.isEditor ? 'Employee' :'' }  {person.isAdmin ? 'Admin' :'' }
                </span>
              </div>
              <p className="mt-1 truncate text-sm text-gray-500 text-gray__700 dark:text-gray__200">{person.title}</p>
            </div>
            {adminStatus && adminStatus === "admin" && (<>
              <Menu as="div" className="relative ml-auto">
              <Menu.Button className="-m-2.5 block p-2.5 text-gray__400 dark:text-gray__400 hover:text-gray-500">
                <span className="sr-only">Open options</span>
                <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-gray__200 dark:bg-gray__700 py-2">
                  <Menu.Item>
                    {({ active }) => (
                    
                    <Link to={`/profile/${person.uid
                    }`}>
                       <button  className='p-2 text-start mx-2 w-full text-ioty__black dark:text-ioty__white'>View</button>
                     </Link>
                      
                    )}
                  </Menu.Item>
                 
                </Menu.Items>
              </Transition>
            </Menu>
            </> )}
            
          </div>
          <div className='text-gray__200 dark:text-gray__900'>
            <div className="-mt-px flex divide-x text-gray__200 dark:text-gray__900 ">
              <div className="flex w-0 flex-1  dark:text-gray__200 ">
                <a
                  href={`mailto:${person.email}`}
                  className="relative inline-flex w-0 dark:text-gray__200 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray__700"
                >
                  <EnvelopeIcon className="h-5 w-5 text-gray__700 dark:text-gray__200" aria-hidden="true" />
                  Email
                </a>
              </div>
              <div className="-ml-px flex w-0 flex-1  text-gray__200 dark:text-gray__900">
                <a
                  href={`tel:${person.telephone}`}
                  className="relative inline-flex w-0 dark:text-gray__200 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray__700"
                >
                  <PhoneIcon className="h-5 w-5 text-gray__700 dark:text-gray__200" aria-hidden="true" />
                  Call
                </a>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul></>
  )
}