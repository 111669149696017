import {
  faBuilding,
  faCog,
  faLock,
  faMoon,
  faSun
} from "@fortawesome/free-solid-svg-icons"
import { Dialog, Switch, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import moment from "moment"
import { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UAParser from "ua-parser-js"
import { settingModal } from '../../redux/slices/common/modalTheme'
import { themeUpdate } from "../../redux/slices/user/user"



import { useEffect, useState } from "react"
  
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
export default function Example(props ) {
    const [loginDevice, setLoginDevice] = useState([]);
    const { userCompanyInfo } = useSelector((state) => state.companySlice);

    const { user } = useSelector((state) => state.authentication);

    const { userInfo } = useSelector((state) => state.userInfo);

     const dispatch = useDispatch()
     const [enabled, setEnabled] = useState(false);


     const {setting, } = props
     const hadleSetting = () =>{
        dispatch(settingModal(false))
     }
     const handlarTheamChange = () => {
        setEnabled(!enabled);
        dispatch(themeUpdate(!enabled,userInfo.userInfoData.uid ));
      };
    
      useEffect(() => {
        if (userInfo && userInfo.isDark) {
          setEnabled(userInfo.isDark);
        }
      }, [userInfo]);
      useEffect(() => {
        const parser = new UAParser();
        const parsedArray = [];
        if (user && user.device) {
          for (const data of user.device) {
            const deviceName = data.deviceName;
            const result = deviceName ? parser.setUA(deviceName).getDevice() : null;
            if (result && result.type) {
              parsedArray.push(result);
            }
          }
          setLoginDevice(parsedArray);
        }
      }, [user]);
    
  return (
    <Transition.Root show={setting} onClick={hadleSetting} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={()=>{}}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0  right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen  max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white dark:bg-gray__800  py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className=" text-xl font-roboto text-primary font-semibold leading-6 text-gray-900">
                           Setting  & Privacy
                           <p className="text-xs mt-2">All Setting & privacy will be here.</p>
                        </Dialog.Title>
                        <div className="ml-3 flex  h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white dark:bg-gray__800 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={hadleSetting}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon  className="h-6 w-6 text-primary" aria-hidden="true" />
                          </button>
                        </div>
                      
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                    <h1 className="mt-5  font-roboto  text-md"> <button className="p-1.5 rounded-full bg-gray__200 dark:bg-gray__700 "><FontAwesomeIcon
                                icon={faCog}
                                className="  h-4 w-4  rounded-full text-gray__400 dark:text-gray__200"
                              /></button> <span className="mx-2 text-gray__600 dark:text-gray__300">Seeting</span></h1>
                    <div>
                        <Switch.Group
                        as="div"
                        className="flex text-end mt-5 items-center"
                      >
                        <Switch.Label as="span" className="text-sm">
                          <span className="font-medium mx-auto text-ioty__black dark:text-gray__300 px-1  md:mt-0 mr-4">
                            Default Theme
                          </span>
                        </Switch.Label>
                        <Switch
                          checked={enabled}
                          onChange={handlarTheamChange}
                          className={classNames(
                            enabled ? "bg-gray__400" : "bg-gray__400",
                            "relative inline-flex h-5 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none   "
                          )}
                        >
                          <span className="sr-only">Use setting</span>
                          <span
                            className={classNames(
                              enabled ? "translate-x-5" : "translate-x-0",
                              "pointer-events-none relative inline-block h-4 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                            )}
                          >
                            <span
                              className={classNames(
                                enabled
                                  ? " duration-100 ease-out"
                                  : "duration-200 ease-in",
                                "absolute inset-0 flex h-full w-full  items-center justify-center transition-opacity"
                              )}
                              aria-hidden="true"
                            >
                              <FontAwesomeIcon
                                icon={faMoon}
                                className=" mr-1.5 h-5 w-5  text-ioty__black"
                              />
                            </span>
                            <span
                              className={classNames(
                                enabled
                                  ? "opacity-100 duration-200 ease-in"
                                  : "opacity-0 duration-100 ease-out",
                                "absolute inset-0 flex h-full w-full bg-white rounded-full items-center justify-center transition-opacity"
                              )}
                              aria-hidden="true"
                            >
                              <FontAwesomeIcon
                                icon={faSun}
                                className=" mr-1.3 h-4 w-4 mx-1 rounded-full text-gray__400"
                              />
                            </span>
                          </span>
                        </Switch>
                      </Switch.Group>
                        </div>
                        <div>
                            <h1 className="mt-10  font-roboto  text-md"> <button className="p-1.5 rounded-full bg-gray__200 dark:bg-gray__700 "><FontAwesomeIcon
                                icon={faLock}
                                className="  h-4 w-4  rounded-full text-gray__400 dark:text-gray__200"
                              /></button> <span className="mx-2 text-gray__600 dark:text-gray__300">Privacy</span></h1> 
                           {user && (
                             <> <p className="text-sm font-roboto px-1 mt-4 ml-0  font-roboto lg:ml-0 my-2 text-ioty__black dark:text-gray__300">
                             Last sign in -{" "}
                             <span className=" mx-2">
                             {moment(new Date(user.lastSignIn)).fromNow()} 
                              
                             </span>
                           </p>
                            <p className="text-sm font-roboto px-1 mt-4 ml-0  font-roboto lg:ml-0 my-2 text-ioty__black dark:text-gray__300">
                             Account Create -
                            <span className=" mx-2">
                            {moment(user.accountCreateTime).format(
                             "DD MMM YYYY"
                           )}  ({moment(new Date(user.accountCreateTime)).fromNow()} )
                             
                            </span>
                          </p></>
                           )}
                           {loginDevice.length > 0  && (<p className="text-sm px-1 font-roboto  mt-4 text-ioty__black dark:text-ioty__white">
                      {loginDevice.length >0 && 'Other Log in Devices:'}  {loginDevice.map((device, index) => (
                      <span key={index}>
                        {device.vendor} {device.model} ({device.type})
                      </span>
                    ))}
                  </p> )}
                         
                        
                        </div>

                        <div>
                        <h1 className="mt-10  font-roboto  text-md"> <button className="p-1.5 rounded-full bg-gray__200 dark:bg-gray__700 "><FontAwesomeIcon
                                icon={faBuilding}
                                className="  h-4 w-4  rounded-full text-gray__400 dark:text-gray__200"
                              /></button> <span className="mx-2 text-gray__600 dark:text-gray__300">Company</span></h1>
                              {!userCompanyInfo && ( <Link to="/company"><button  onClick={hadleSetting} className="mt-4 px-1 text-ioty__black dark:text-gray__300"> Enroll a company</button></Link>)}
                              {userCompanyInfo && ( <Link to="/company"><button  onClick={hadleSetting} className="mt-4 px-1 text-ioty__black dark:text-gray__300">Company</button></Link>)}

                            
                        </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}