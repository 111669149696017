import React from 'react';
import { Link } from 'react-router-dom';
import { collaborateProjectUpdate } from '../../redux/slices/project/projects';
import { useDispatch } from 'react-redux';

const Card11 = (props) => {
  const { section, comapnyId, companyProjects,collaborateProjectApproval } = props;
  console.log(companyProjects);

  // Ensure companyProjects is an array before filtering
  const filteredProjects = Array.isArray(companyProjects)
    ? companyProjects.filter((item) => item.status === true)
    : [];

  const filteredProjectsWating = Array.isArray(collaborateProjectApproval)
    ? collaborateProjectApproval.filter((item) => item.status === false)
    : [];

  return (
    <section className="lg:mx-2 lg:py-1">
      <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap justify-start">
          {section === 1 &&
            filteredProjects.map((item) => (
              <SingleCard key={item.id} comapnyId={comapnyId} item={item} />
            ))}
          {section === 2 &&
            filteredProjectsWating.map((item) => (
              <SingleCard2 key={item.id} comapnyId={comapnyId} item={item} />
            ))}

          {section === 2 &&
            filteredProjectsWating.length ==0 && (
              <div className="flex w-full p- mx-4  border-l-[6px]  border-warning bg-warning bg-opacity-[15%] px-7  shadow-md md:p-3">
              <div className="mr-5 flex h-9 w-full max-w-[36px] items-center justify-center rounded-lg bg-warning bg-opacity-30">
                <svg
                  width="19"
                  height="16"
                  viewBox="0 0 19 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.50493 16H17.5023C18.6204 16 19.3413 14.9018 18.8354 13.9735L10.8367 0.770573C10.2852 -0.256858 8.70677 -0.256858 8.15528 0.770573L0.156617 13.9735C-0.334072 14.8998 0.386764 16 1.50493 16ZM10.7585 12.9298C10.7585 13.6155 10.2223 14.1433 9.45583 14.1433C8.6894 14.1433 8.15311 13.6155 8.15311 12.9298V12.9015C8.15311 12.2159 8.6894 11.688 9.45583 11.688C10.2223 11.688 10.7585 12.2159 10.7585 12.9015V12.9298ZM8.75236 4.01062H10.2548C10.6674 4.01062 10.9127 4.33826 10.8671 4.75288L10.2071 10.1186C10.1615 10.5049 9.88572 10.7455 9.50142 10.7455C9.11929 10.7455 8.84138 10.5028 8.79579 10.1186L8.13574 4.75288C8.09449 4.33826 8.33984 4.01062 8.75236 4.01062Z"
                    fill="#FBBF24"
                  ></path>
                </svg>
                
              </div>
              <p className='p-2 font-semibold text-white'>No request pending for collaboration.</p>
            </div>
            )}
              {section === 1 &&
            filteredProjects.length ==0 && (
              <div className="flex w-full p- mx-4  border-l-[6px]  border-warning bg-warning bg-opacity-[15%] px-7  shadow-md md:p-3">
              <div className="mr-5 flex h-9 w-full max-w-[36px] items-center justify-center rounded-lg bg-warning bg-opacity-30">
                <svg
                  width="19"
                  height="16"
                  viewBox="0 0 19 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.50493 16H17.5023C18.6204 16 19.3413 14.9018 18.8354 13.9735L10.8367 0.770573C10.2852 -0.256858 8.70677 -0.256858 8.15528 0.770573L0.156617 13.9735C-0.334072 14.8998 0.386764 16 1.50493 16ZM10.7585 12.9298C10.7585 13.6155 10.2223 14.1433 9.45583 14.1433C8.6894 14.1433 8.15311 13.6155 8.15311 12.9298V12.9015C8.15311 12.2159 8.6894 11.688 9.45583 11.688C10.2223 11.688 10.7585 12.2159 10.7585 12.9015V12.9298ZM8.75236 4.01062H10.2548C10.6674 4.01062 10.9127 4.33826 10.8671 4.75288L10.2071 10.1186C10.1615 10.5049 9.88572 10.7455 9.50142 10.7455C9.11929 10.7455 8.84138 10.5028 8.79579 10.1186L8.13574 4.75288C8.09449 4.33826 8.33984 4.01062 8.75236 4.01062Z"
                    fill="#FBBF24"
                  ></path>
                </svg>
                
              </div>
              <p className='p-2 font-semibold text-white'>You haven't collaborated on any project yet</p>
            </div>
            )}
        </div>
      </div>
    </section>
  );
};


export default Card11;

const SingleCard = ({ item }) => {

  const dispatch =useDispatch()
  return (
    <div className="w-full px-2 my-2 md:w-1/2 lg:w-1/3">
     
      <Link to={`/company/${item.companyId}/project/collaborate/${item.projectId}`}>
        <div className="card-container shadow-three rounded mx-1 lg:mx-0 dark:bg-gray__800 bg-white">
          <div className="mb-6 w-full overflow-hidden rounded">
            <img
              src={item.coverPhoto}
              alt="card imge"
              className="h-48 w-full object-cover object-center"
            />
          </div>

          <div className="px-2 mb-3 relative">
            <div className="manu absolute right-2 top-0"></div>
            <h3 className="dark:text-gray__300 text-xl font-semibold font-roboto">{item.projectName}</h3>
            <h3 className="dark:text-gray__300 text-sm mt-2 font-roboto">{item.projectLocation}</h3>
            <p className="dark:text-gray__300 text-sm pb-5 pt-3 font-roboto">{item.description}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};

const SingleCard2 = ({ item, comapnyId }) => {
  const dispatch =useDispatch()

  return (
    <div className="w-full px-2 my-2 md:w-1/2 lg:w-1/3">
        
        <div className='mx-3 bg-white dark:bg-gray__800 sm:mx-0 sm:col-start-2 sm:col-end-8 dark:bg-gary__700 xl:col-start-3 xl:col-end-11 2xl:col-start-4 2xl:col-end-10'>
        <div className="px-2 px-2 sm:10">
        <div className=" w-full overflow-hidden rounded">
            <img
              src={item.coverPhoto}
              alt="card imge"
              className="h-48 w-full object-cover object-center"
            />
          </div>
          <div className="manu absolute right-2 top-0"></div>
            <h3 className="dark:text-gray__300 text-xl font-semibold font-roboto">{item.projectName}</h3>
            
            <p className="dark:text-gray__300 text-sm pb-5 pt-3 font-roboto">{item.description}</p>
          <div className="flex w-full  border-l-[6px]  border-warning bg-warning bg-opacity-[15%] px-7  shadow-md md:p-3">
            <div className="mr-5 flex h-9 w-full max-w-[36px] items-center justify-center rounded-lg bg-warning bg-opacity-30">
              <svg
                width="19"
                height="16"
                viewBox="0 0 19 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.50493 16H17.5023C18.6204 16 19.3413 14.9018 18.8354 13.9735L10.8367 0.770573C10.2852 -0.256858 8.70677 -0.256858 8.15528 0.770573L0.156617 13.9735C-0.334072 14.8998 0.386764 16 1.50493 16ZM10.7585 12.9298C10.7585 13.6155 10.2223 14.1433 9.45583 14.1433C8.6894 14.1433 8.15311 13.6155 8.15311 12.9298V12.9015C8.15311 12.2159 8.6894 11.688 9.45583 11.688C10.2223 11.688 10.7585 12.2159 10.7585 12.9015V12.9298ZM8.75236 4.01062H10.2548C10.6674 4.01062 10.9127 4.33826 10.8671 4.75288L10.2071 10.1186C10.1615 10.5049 9.88572 10.7455 9.50142 10.7455C9.11929 10.7455 8.84138 10.5028 8.79579 10.1186L8.13574 4.75288C8.09449 4.33826 8.33984 4.01062 8.75236 4.01062Z"
                  fill="#FBBF24"
                ></path>
              </svg>
            </div>
            <div className="w-full">
              <h5 className=" text-sm font-semibold text-ioty__secondary">Project request is awaiting approval.</h5>
              <div className='flex justify-end'><button onClick={()=>dispatch(collaborateProjectUpdate(item, comapnyId, 'delete'))}className='mx-2 bg-white dark:bg-red-800 dark:text-white px-4 mt-2 py-1 rounded text-sm' >Decline</button> <button onClick={()=>dispatch(collaborateProjectUpdate(item, comapnyId, 'accept'))} className='mx-2 bg-primary px-4 mt-2 py-1 text-white rounded text-sm'>Accept</button></div>
              
            </div>
          </div>
        </div>
      </div>
    
      </div>
  );
};