import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    uploadImageProject
} from '../../redux/slices/common/fileUpload';
import { profileEditAction } from '../../redux/slices/common/modalTheme';
import { fetchProjectInfo, updateProjectInfo } from '../../redux/slices/project/projects';


const notificationMethods = [
  { id: '1', title: 'Yes' },
  { id: '2', title: 'No' },
];

export default function ProjectEdit({ adminRole }) {
  const { companyId, projectId } = useParams();
  const { user } = useSelector((state) => state.authentication);
  const { isLoading, projectImage } = useSelector((state) => state.fileUpload);
  const { userInfo } = useSelector((state) => state.userInfo);
  const [buttonStatus,setButtonStatus] = useState(false)
  const [message,setMessage]= useState('')

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    projectName: '',
    projectLocation: '',
    developer: '',
    slug: '',
    description: '',
    taxRate: 0.7,
    categories:[],
    featuredProject: false,
    companyId:'',
    projectId:'',
    applies: {
      materials: true,
      labor: true
    },
    coverPhoto: 'https://www.solidbackgrounds.com/images/4096x2304/4096x2304-french-sky-blue-solid-color-background.jpg'
  });
  const [targetImage, setTargetImage] = useState('');



  const handleImageChangeCover = async (event) => {
    setTargetImage('companyCover');
    if (event.target.files[0]) {
      dispatch(uploadImageProject(event.target.files[0], 'companyCover'));
      event.target.value = null;
    }
  };

  const handleInputChange = event => {
    const { name, value, type, checked } = event.target;

    if (type === 'checkbox') {
      setFormData(prevFormData => ({
        ...prevFormData,
        applies: {
          ...prevFormData.applies,
          [name]: checked
        }
      }));
    } else if (type === 'radio') {
      setFormData(prevFormData => ({
        ...prevFormData,
        featuredProject: value === 'Yes'
      }));
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value
      }));
    }
  };

  const saveProjectData = () => {
    dispatch(updateProjectInfo(formData))
    setButtonStatus(true);
    setTimeout(() => {
        setButtonStatus(false);
    }, 5000);
  };

  useEffect(() => {
    dispatch(fetchProjectInfo(projectId));
  }, [projectId]);

  const { project } = useSelector((state) => state.projectSlice);
  const {  projectCategory } = useSelector((state) => state.projectSlice)
  useEffect(() => {
    if (project && projectCategory) {
      setFormData(prevFormData => ({
    projectName:  project.projectName|| prevFormData.projectName,
    projectLocation:  project.projectLocation|| prevFormData.projectLocation,
    developer:  project.developer|| prevFormData.developer,
    slug:  project.slug|| prevFormData.slug,
    description:  project.description|| prevFormData.description,
    taxRate:  project.taxRate|| prevFormData.taxRate,
    featuredProject:  project.featuredProject|| prevFormData.featuredProject,
    coverPhoto: project.coverPhoto|| prevFormData.coverPhoto,
    companyId: project.companyId|| prevFormData.companyId,
    projectId: project.projectId|| prevFormData.projectId,
    categories:projectCategory|| prevFormData.categories,
    applies: {
      materials: project.applies.materials || prevFormData.applies.labor,
      labor: project.applies.labor ||project.prevFormData.labor
    },
   
      
      }));

      setMessage('Selected categories')
    }
    else if (project && projectCategory === null) {
      setFormData(prevFormData => ({
    projectName:  project.projectName|| prevFormData.projectName,
    projectLocation:  project.projectLocation|| prevFormData.projectLocation,
    developer:  project.developer|| prevFormData.developer,
    slug:  project.slug|| prevFormData.slug,
    description:  project.description|| prevFormData.description,
    taxRate:  project.taxRate|| prevFormData.taxRate,
    featuredProject:  project.featuredProject|| prevFormData.featuredProject,
    coverPhoto: project.coverPhoto|| prevFormData.coverPhoto,
    companyId: project.companyId|| prevFormData.companyId,
    projectId: project.projectId|| prevFormData.projectId,
    categories:project.categories|| prevFormData.categories,
    applies: {
      materials: project.applies.materials || prevFormData.applies.labor,
      labor: project.applies.labor ||project.prevFormData.labor
    },
   
      
      }));
      setMessage('Project Categories')
    }
   
  }, [project, projectCategory]);

 

 
  const isFormIncomplete = !formData.projectName || !formData.projectLocation || !formData.description;
    return (
      <> 
      <div className='grid grid-cols-1 sm:grid-cols-8 xl:grid-cols-12'>       
        <div className='mx-3 sm:mx-0 sm:col-start-2 sm:col-end-8 xl:col-start-3 xl:col-end-11 2xl:col-start-4 2xl:col-end-10'>
             <div className='  rounded-b-lg border  border-stroke dark:border-gray__800 bg-white dark:bg-gray__800 pb-5 2xl:mb-0'>
             <div className=' px-1 sm:p-5 lg:p-8  md:pb-2'>
               <div className='mt-2 sm:mt-2 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6'>
                 <div className='col-span-full'>
                   <label htmlFor='photo' className='block dark:text-gray__200 text-md xl:text-2xl xl:pb-4 font-medium  text-gray__900'>
                      {project ? project.projectName :''}
                   </label>
                  
                 </div>

                 <div className="col-span-full">
               <label htmlFor='lastName' className='block dark:text-gray__200 text-sm font-medium leading-6 mb-3 text-gray__900'>
                    {message}  Or   <Link to={`/project-category/${formData.projectId}`}><button className="mx-1 p-1"> <FontAwesomeIcon
                      icon={faEdit}
                      className=" mr-1.5 h-4 w-4"
                    /></button></Link>
                 </label>
                    {formData.categories &&
                    formData.categories.map((item) => (
                        <button className="py-1 px-2 bg-primary dark:bg-gray__900 mr-1 rounded text-sm text-white" key={item.categoryId}>{item.categoryName}</button>
                      ))}
                  </div>

                 <div className='col-span-full mt sm:col-span-3'>
                   <label htmlFor='firstName' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                      Name <span className="text-red-500">*</span>
                   </label>
                   <input
                     type='text'
                     name='projectName'
                     id='projectName'
                     autoComplete='given-name'
                     value={formData.projectName} 
                     onChange={handleInputChange}
                     className='block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6'
                   />
                 </div> 
                 <div className='col-span-full sm:col-span-3'>
                   <label htmlFor='lastName' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                      Developer 
                   </label>
                   <input
                     type='text'
                     name='developer'
                     id='developer'
                     autoComplete='family-name'
                     value={formData.developer} 
                     onChange={handleInputChange}
                     className='block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6'
                   />
                 </div>
                 <div className="col-span-full">
                <label htmlFor="about" className="block text-sm dark:text-ioty__white font-medium leading-6 text-gray-900">
                  Description  <span className="text-red-500">*</span>
                </label>
                <div className="mt-2">
                  <textarea
                    id="description"
                    name="description"
                    type='text'                
                    defaultValue={formData.description}
                    onChange={handleInputChange}
                    rows={3}
                    className="block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6"
                   
                  />
                </div>
              </div> 
                 <div className='col-span-full sm:col-span-2'>
                   <label htmlFor='lastName' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                      Location  <span className="text-red-500">*</span>
                   </label>
                   <input
                     type='text'
                     name='projectLocation'
                     id='projectLocation'
                     autoComplete='family-name'
                     value={formData.projectLocation} 
                     onChange={handleInputChange}
                     className='block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6'
                   />
                 </div>
                
                 <div className='col-span-full sm:col-span-2'>
                   <label htmlFor='lastName' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                      Slug  
                   </label>
                   <input
                     type='text'
                     name='slug'
                     id='slug'
                     autoComplete='family-name'
                     value={formData.slug} 
                     onChange={handleInputChange}
                     className='block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6'
                   />
                 </div>
                 <div className='col-span-full sm:col-span-2'>
                   <label htmlFor='lastName' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                      Tax Rate  
                   </label>
                   <input
                     type='number'
                     name='taxRate'
                     id='taxRate'
                     autoComplete='family-name'
                     value={formData.taxRate}
                     onChange={handleInputChange}
                     className='block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6'
                   />
                 </div>
                 <div className='col-span-full sm:col-span-3 ml-1'>
    <label htmlFor='lastName'  className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900 sm:mt-1'>
      Featured Project ?  
    </label>
    {notificationMethods.map((notificationMethod) => (
      <div key={notificationMethod.id} className="flex items-center">
        <input
          id={notificationMethod.id}
          name="featuredProject"
          type="radio"
          value={notificationMethod.title === 'Yes' ? 'Yes' : 'No'}
          checked={formData.featuredProject === (notificationMethod.title === 'Yes')}
          onChange={handleInputChange}
          className="h-5 w-5 mt-2 border-gray-300 text-indigo-600 focus:ring-indigo-600"
        />
        <label htmlFor={notificationMethod.id} className="ml-3 mt-2 dark:text-gray__200 block text-sm font-medium leading-6 text-gray__900">
          {notificationMethod.title}
        </label>
      </div>
    ))}
  </div>
  
  <div className='col-span-full sm:col-span-3'>
    <label htmlFor='lastName' className='block dark:text-gray__200 mt-1 text-sm font-medium leading-6 text-gray__900'>
      Apply to  
    </label>
    <div className="relative flex items-start">
      <div className="flex h-6 mt-1 items-center">
        <input
          id="materials"
          name="materials"
          type="checkbox"
          checked={formData.applies.materials}
          onChange={handleInputChange}
          className="h-4 w-4 rounded border-gray-300 mt-2 text-indigo-600 focus:ring-indigo-600"
        />
      </div>
      <div className="ml-3 text-sm leading-6 mt-2">
        <label htmlFor="materials" className="font-medium dark:text-gray__200 text-gray-900">
          Materials
        </label>
      </div>
    </div>
    <div className="relative flex items-start">
      <div className="flex h-6 mt-1 items-center">
        <input
          id="labor"
          name="labor"
          type="checkbox"
          checked={formData.applies.labor}
          onChange={handleInputChange}
          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
        />
      </div>
      <div className="ml-3 text-sm leading-6 mt-1">
        <label htmlFor="labor" className="font-medium dark:text-gray__200 text-gray-900">
          Labor
        </label>
      </div>
    </div>
  </div>     
               </div>
             </div>
             
             <div className='flex mt-2 justify-end mr-10'>
              <Link to="/company">
              
              <button
                onClick={()=>{
                  dispatch(profileEditAction({type:false}))
                }}
               className='inline-flex items-center px-8 py-2 border border-transparent text-sm  font-medium rounded-md shadow-sm  bg-ioty__white dark:bg-gray__900 text-ioty__black  focus:outline-none focus:border-blue-700   dark:text-ioty__white transition duration-150 ease-in-out disabled:opacity-50'
              
             >
               Cancel
             </button>
              
              </Link>
                <button
                
                  onClick={saveProjectData}
                  className='inline-flex items-center ml-1 px-8 py-2 border border-transparent text-sm  font-medium rounded-md shadow-sm text-white bg-primary dark:bg-gray__700  focus:outline-none focus:border-blue-700  transition duration-150 ease-in-out disabled:opacity-50'
                  disabled={isFormIncomplete || buttonStatus}
                >
                  Save
                </button>
             
             </div>
           </div>
         
        </div>
      </div>
      </>
    );
  }