import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BellIcon } from "@heroicons/react/24/outline";
import { formatDistanceToNow } from "date-fns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { settingModal } from '../../redux/slices/common/modalTheme';
import {
  updateSuperAdminNotification,
  updateUserNotification,
} from "../../redux/slices/user/user";

import {
  changeMode,
  profileEditAction,
  signInModal,
} from "../../redux/slices/common/modalTheme";
import { companyAction } from "../../redux/slices/user/user";

import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../redux/slices/auth/auth";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Navbar({ value, notification, adminRole }) {
  const [notificationUser, setNotificationUser] = useState([]);
  const [seenValue, setSeen] = useState(true);

  const navigate = useNavigate();
  const logoutHandelar = () => {
    dispatch(logOut());
    navigate("/");
  };

  const notificationSeen = () => {
    if (adminRole.isSystemAdmin) {
      dispatch(updateSuperAdminNotification());
    } else {
      dispatch(updateUserNotification(value.uid));
    }
  };
  const companyActionReq = (id, type) => {
    dispatch(
      companyAction({
        id,
        type,
      })
    );
  };
  useEffect(() => {
    if (notification) {
      const isAnySeen = notification.some(
        (notification) => notification.isSeen === false
      );
      setSeen(isAnySeen);
    }
    setNotificationUser(notification);
  }, [notification]);

  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const { theme, signinModal } = useSelector((state) => state.modalTheme);

  const dispatch = useDispatch();

  const handleProfileEdit = () => {
    dispatch(settingModal(true))
    setOpen(false);
  };
  const profileRoute = () => {
    navigate("/profile");
    dispatch(profileEditAction({ type: false }));
  };
  return (
    <div>
      <div className="mx-auto bg-white border-b dark:border-gray__900 border-gray__100  dark:bg-gray__900  px-4 sm:px-6 lg:px-8">
        <div className="flex h-12 justify-between mb-1">
          <div className="flex">
            <div className="-ml-2  mr-2 flex items-center md:hidden"></div>

            <div className="hidden  text-2xl font-roboto font-semibold md:flex md:items-center md:space-x-4">
              {" "}
              <Link to="/">
              <h1 className="text-primary ">
                SIGNAL
                <span className="text-gray__700 dark:text-gray__300">FLOW</span>
              </h1>
              </Link>           
            </div>
          </div>
          <div className="flex">
            <div className=" items-center mt-1">
              {!value && (
                <button
                  className="p-1 bg-white dark:bg-gray__800 dark:text-gray__200  mt-2 sm:mt-2 mx-1 text-ioty__black border border-ioty__black font-roboto px-2 text-sm sm:px-4 bg-gray__900 rounded text-gray__300"
                  onClick={(e) => {
                    dispatch(signInModal({ type: signinModal }));
                  }}
                >
                  Sign In
                </button>
              )}
            </div>
            {value && (
              <div className="mt-3 mx-2">
                <Menu as="div" className="relative inline-block   text-left">
                  <div>
                    <Menu.Button
                      className={`flex items-center mr-4 rounded-full dark:bg-gray-200 dark:text-gray-400 hover:text-gray-600 focus:outline-none  focus:ring-1 focus:ring-primary focus:ring-offset-1 focus:ring-offset-gray-100`}
                    >
                      <span className="sr-only">Open options</span>
                      <BellIcon
                        onClick={notificationSeen}
                        className={`
                      ${
                        seenValue
                          ? "w-8 h-8 text-primary"
                          : "dark:text-gray__300 text-gray__700 w-8 h-8"
                      }`}
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                    className="dark:bg-gray__800 "
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-96  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <>
                              {notificationUser &&
                                notificationUser.map((item, index) => (
                                  <Link
                                    key={index}
                                    className={`block px-4 py-2 text-sm  ${
                                      !item.isSeen
                                        ? "dark:bg-gray-900 dark:ring-1 dark:ring-gray-800 border-l-4 border-green-800"
                                        : "dark:bg-gray__800"
                                    }    dark:text-ioty__white mt-1 dark:hover:bg-gray-800`}
                                  >
                                    <div>
                                      <div className="ml-3 w-96 pt-0.5  ">
                                        <p className="text-sm font-medium  text-ioty__black dark:text-ioty__white">
                                          {item.message}
                                        </p>

                                        {item.congMrss && (
                                          <p className="text-sm  font-medium py-2  text-ioty__black dark:text-ioty__white">
                                            {item.congMrss ? item.congMrss : ""}
                                            <span className="text-ioty__secondary mx-2">
                                              {" "}
                                              {item.congMrss
                                                ? formatDistanceToNow(
                                                    new Date(item.timestamp)
                                                  )
                                                : ""}
                                            </span>
                                          </p>
                                        )}

                                        {item.companyName && (
                                          <p className="mt-2 text-sm ttext-ioty__black dark:text-ioty__white">
                                            {item.companyName}{" "}
                                            <span className="text-ioty__secondary">
                                              {" "}
                                              -
                                              {formatDistanceToNow(
                                                new Date(item.timestamp)
                                              )}
                                            </span>
                                          </p>
                                        )}
                                      </div>
                                      {item.isAction &&
                                        adminRole &&
                                        adminRole.isSystemAdmin && (
                                          <div className="border-t border-gary-200  dark:border-gray-700">
                                            <button
                                              onClick={() =>
                                                companyActionReq(
                                                  item.companyId,
                                                  true
                                                )
                                              }
                                              className="inline-flex items-center mt-1 text-xs  rounded justify-center dark:bg-gray-900 bg-gray-200 text-center  font-bold  dark:text-ioty__primary text-ioty__primary   hover:bg-opacity-90 px-4 py-2"
                                            >
                                              Accept
                                            </button>
                                            <button
                                              onClick={() =>
                                                companyActionReq(
                                                  item.companyId,
                                                  false
                                                )
                                              }
                                              className="inline-flex items-center mt-1 mx-2 text-xs  rounded justify-center dark:bg-gray-900 bg-gray-200 text-center  font-bold  dark:text-ioty__secondary text-ioty__secondary   hover:bg-opacity-90 px-3 py-2"
                                            >
                                              Decline
                                            </button>
                                          </div>
                                        )}
                                    </div>
                                  </Link>
                                ))}
                            </>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            )}

            {value && (
              <div className="mt-3 mx-2">
                <Menu as="div" className="relative inline-block  text-left">
                  <div>
                    <Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-primary focus:outline-none focus:ring-2 focus:ring-ioty__primary focus:ring-offset-2 focus:ring-offset-primary">
                      <span className="sr-only">Open options</span>
                      <img
                        className="w-8 h-8 border-2  rounded-full"
                        src={value.photoURL}
                        alt=""
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                    className="dark:bg-gray__800 "
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={profileRoute}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900 w-full text-start dark:text-ioty__white mt-1 dark:hover:bg-gray-800"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm dark:text-ioty__white mt-1  w-full text-start  dark:hover:bg-gray-800"
                              )}
                            >
                              Profile
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              onClick={handleProfileEdit}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900 dark:text-ioty__white mt-1 dark:hover:bg-gray-800"
                                  : "text-gray-700 dark:text-ioty__white mt-1 dark:hover:bg-gray-800",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              Account Setting
                            </Link>
                          )}
                        </Menu.Item>

                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={logoutHandelar}
                              type="submit"
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900 dark:text-ioty__white mt-1 dark:hover:bg-gray-800"
                                  : "text-gray-700 dark:text-ioty__white mt-1 dark:hover:bg-gray-800",
                                "block w-full px-4 py-2 text-left text-sm dark:text-ioty__white mt-1 dark:hover:bg-gray-800"
                              )}
                            >
                              Sign out
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            )}

            <div className=" mx-1 mt-1  sm:mr-10 items-center">
              <button
                className=" bg-white mt-2 sm:mx-4 text-ioty__black dark:text-gray__200 dark:bg-gray__700 mt-2  p-1 bg-gray__800 rounded-full text-gray__300"
                onClick={(e) => {
                  dispatch(changeMode({ type: theme.theme, instance: true }));
                }}
              >
                {theme.theme === "dark" ? (
                  <FontAwesomeIcon className="w-6" size="xl" icon={faSun} />
                ) : (
                  <FontAwesomeIcon className="w-6" size="xl" icon={faMoon} />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
