import {
    faAdd,
    faCog,
    faEdit,
    faHandshake,
    faList12,
    faMapMarker,
    faProjectDiagram,
    faSpinner,
  } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { Link, useParams } from "react-router-dom";
  import { updateImageProject } from "../../redux/slices/common/fileUpload";
  import { fetchProjectInfo,fetchFloorPlanInfo } from "../../redux/slices/project/projects";
  import FloorplanList from '../floorplan/FloorplanList'
  import {collaborateModalAction,settingModalProject } from '../../redux/slices/common/modalTheme'
  import  ProjectSetting from '../common/ProjectSetting'
  
  import CollaboratorList from './CollaboratorList'
  function PorjectDetails() {
    const [targetImage, setTargetImage] = useState("");
    const { projectId, companyId } = useParams();
    const dispatch = useDispatch(); 
    const { userInfo } = useSelector((state) => state.userInfo);
    const { userCompanyInfo } = useSelector((state) => state.companySlice);
    const { companyModal ,projectSection} = useSelector((state) => state.modalTheme);
    const { isLoading } = useSelector((state) => state.fileUpload);
  
    const [company, setCompanyInfoUser] = useState();
    const [adminStatus, setAdminStatus] = useState("user");
  
    useEffect(() => {
      if (userCompanyInfo) {
        setCompanyInfoUser(userCompanyInfo);
        if (userCompanyInfo.companyInfo.admin && userInfo) {
          userCompanyInfo.companyInfo.admin.map((item) => {
            if (item.adminUid === userInfo.userInfoData.uid) {
              if (item.isEditor) {
                setAdminStatus("editor");
              } else if (item.isAdmin) {
                setAdminStatus("admin");
              }
            }
          });
        }
      }
    }, [userCompanyInfo]);
    useEffect(() => {
      dispatch(fetchProjectInfo(projectId));
    }, [projectId]);
    const { project, floorpans } = useSelector((state) => state.projectSlice);
    const hadleSetting = () => {
      dispatch(settingModalProject(true));
    };
    const handleImageChangeCover = async (event) => {
      setTargetImage("companyCover");
      if (event.target.files[0]) {
        dispatch(updateImageProject(event.target.files[0], project));
        event.target.value = null;
      }
    };
    const collaborateModal = () =>{
      dispatch(collaborateModalAction(true))
    }
    useEffect(() => {
      dispatch(fetchFloorPlanInfo(projectId));
    }, [projectId]);
    console.log(project,'asdf')
    return (
      <div className="PorjectDetails">
        <ProjectSetting/>
        <>
          {project && (
            <>
              <div className="px-2 sm:10">
                <section className="bg-gray-2 0 dark:bg-gray__900 ">
                  <div className="mx-auto px-0 md:container dark:bg-gray__900">
                    <div className="mx-auto w-full max-w-[1270px]  mb-0 rounded-b-lg border  border-stroke dark:border-gray__800 bg-white dark:bg-gray__800 pb-5 2xl:mb-0">
                      <div className=" z-20 h-[140px] sm:h-[100px] md:h-[200px]">
                        <img
                          src={project.coverPhoto}
                          alt="profile cover"
                          className="h-full w-full object-cover object-center"
                        />
                      
                      </div>
  
                      <div className="flex flex-wrap px-5   mt-8 sm:px-9 md:flex-nowrap">
                        <div className="w-full md:pl-1  xl:mt-2">
                          <div className="mb-0 items-center justify-between sm:flex">
                            <div>
                              <h4 className=" mt-2 mr-4 md:-mt-4 ml-1  text-xl dark:text-gray__300  xl:text-2xl font-semibold ">
                                {project.projectName}
                              </h4>
  
                              <div className="col-span-full text-sm flex mt-2 text-ioty__black dark:text-ioty__white items-center">
                                <FontAwesomeIcon
                                  icon={faMapMarker}
                                  className="  text-gray__400 mt-0 h-4 w-4 mr-2"
                                />
                                <h1 className="font-roboto mt-0  inline">
                                  {project.projectLocation}
                                </h1>
                              </div>
  
                              <div className="w-full mb-0 mt-2 mx-auto w-full max-w-[1270px]">
                                <p className="text-sm text-body-color dark:text-gray__300 my-1">
                                  {project.description}
                                </p>
                              </div>
  
                              <div className="sm:flex sm:items-center"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </section>
                <section>                   
  
                  <div className="mx-auto px-0 md:container dark:bg-gray__900">
                    <div className="mx-auto w-full max-w-[1270px]   mt-2 pb-5 2xl:mb-0">
                      <div className="flex justify-between">
                        <div className="flex"> 
                         { project &&( <Link to={`/collaborate-category/${project.projectId}`}>
                            <button
                             
                              className="block rounded bg-primary dark:bg-gray__800 px-3 py-1.5 text-center text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              <FontAwesomeIcon
                                icon={faList12}
                                className="  h-4 w-4 mr-3 rounded-full text-gray__100 dark:text-gray__200"
                              />{" "}
                              Categories
                            </button>

                            
                          </Link>)}
                          <Link to='/company'>
                            <button
                             
                              className="block mx-1 rounded bg-primary dark:bg-gray__800 px-3 py-1.5 text-center text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              <FontAwesomeIcon
                                icon={faProjectDiagram}
                                className="  h-4 w-4 mr-3 rounded-full text-gray__100 dark:text-gray__200"
                              />{" "}
                              Your Projects 
                            </button>

                            
                          </Link>
                        </div>                
                        </div>
                    </div>
                    {projectSection !== 1 &&( <div className="mx-auto w-full max-w-[1270px]   mt-2 pb-5 2xl:mb-0">
                      <FloorplanList floorpans={floorpans} />
                    </div>)}
                    {projectSection === 1 &&( <div className="mx-auto w-full max-w-[1270px]   mt-2 pb-5 2xl:mb-0">
                      <CollaboratorList project={project} />
                    </div>)}
                   
                    
                  </div>
                </section>
              </div>
            </>
          )}
        </>
      </div>
    );
  }
  
  export default PorjectDetails;
  