import ProjectList from "../components/project/public/Projects";
function Project() {
  return (
    <div className="Project">
       <>
        <div className="px-2 sm:10">
         <ProjectList/>     
        </div>
       </>
    </div>
  );
}

export default Project;