import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { companyEmployAdd } from '../../../redux/slices/common/modalTheme';
import { companyUserAction } from '../../../redux/slices/company/company';

export default function AdminModal() {
    const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const [newAdmin, setNewAdmin] = useState({
    adminEmail: '',
    adminUid: '',
    isEditor: false,
    isAdmin: false,
    title: '',
    isDelete:false
  });
  const [isEditor, setEditor] = useState(false);
  const [isAdmin, setAdmin] = useState(false);

  const { isLoading, downloadURLLogo } = useSelector((state) => state.fileUpload);
  const { companyNewUser} =  useSelector((state) => state.modalTheme)
  const {companyInfo} = useSelector((state) => state.companySlice);
  const [errorMessage, setErrorMessage] = useState('');
  
  const [formData, setFormData] = useState({
    companyName: '',
    phoneNumber: '',
    address: '',
    city: '',
    isPremium: false,
    companyEmail: '',
    isStatus: true,
    zipCode: '',
    state: '',
    admin:[],
    photoURL: 'https://img.freepik.com/free-photo/white-paper-background-simple-diy-craft_53876-146283.jpg',
    coverPhoto:'https://img.freepik.com/free-photo/white-paper-background-simple-diy-craft_53876-146283.jpg'
  })
  useEffect(()=>{
    if( companyNewUser &&companyNewUser.uid){
        setNewAdmin((prevNewAdmin) =>({
            ...prevNewAdmin,
            adminEmail:companyNewUser.email,
            adminUid:companyNewUser.uid
        }))
         
    }
   
  }, [companyNewUser])
  useEffect(() => {
    if (companyInfo  && companyInfo.companyInfo) {  
      setFormData((prevFormData) => ({
        ...prevFormData,
        companyName: companyInfo.companyInfo.companyName || '',
        phoneNumber: companyInfo.companyInfo.phoneNumber || '',
        address: companyInfo.companyInfo.address || '',
        about:companyInfo.companyInfo.about || '',
        title:companyInfo.companyInfo.title || '',
        city: companyInfo.companyInfo.city || '',
        isPremium: companyInfo.companyInfo.isPremium || false,
        companyEmail: companyInfo.companyInfo.companyEmail || '',
        creatorEmail: companyInfo.companyInfo.creatorEmail || '',
        isStatus: companyInfo.companyInfo.isStatus || true,
        zipCode: companyInfo.companyInfo.zipCode || '',
        state: companyInfo.companyInfo.state || '',
        admin: companyInfo.companyInfo.admin || [],
        photoURL: companyInfo.companyInfo.photoURL || 'https://img.freepik.com/free-photo/white-paper-background-simple-diy-craft_53876-146283.jpg',
        coverPhoto: companyInfo.companyInfo.coverPhoto || 'https://img.freepik.com/free-photo/white-paper-background-simple-diy-craft_53876-146283.jpg',
        creatorUid: companyInfo.companyInfo.creatorUid || '',
        createTime: companyInfo.companyInfo.createTime || 0
      }));
      
    }
  }, [companyInfo]);
 

  useEffect(()=>{
    if(downloadURLLogo) {
      setFormData({
        ...formData,
        photoURL:downloadURLLogo ? downloadURLLogo :'https://img.freepik.com/free-photo/white-paper-background-simple-diy-craft_53876-146283.jpg'
      })
     }
  },[downloadURLLogo])
  const handleInputChange = (e) => {
    const { name, checked , value } = e.target;
   
    if(name === 'title') {   
        setNewAdmin((p) => ({
        ...p,
         [name]:e.target.value
        }))  
    }
    else if (name === 'isAdmin' && checked) {
      setNewAdmin((prevNewAdmin) => ({
        ...prevNewAdmin,
      
        isAdmin: true,
        isEditor: false,
        isDelete:false
      }));
    } else if (name === 'isEditor' && checked) {
      setNewAdmin((prevNewAdmin) => ({
        ...prevNewAdmin,
       
        
        isAdmin: false,
        isEditor: true,
        isDelete:false
       
      }));
      
      
    } 
    else if (name === 'isDelete' && checked) {
      setNewAdmin((prevNewAdmin) => ({
        ...prevNewAdmin,     
        isAdmin: false,
        isEditor: false,  
        isDelete:true    
      }));
      
      
    }else {
      setNewAdmin((prevNewAdmin) => ({
        ...prevNewAdmin,
        isAdmin: false,
        isEditor: false,
      }));
    }
  };


  const actionUpdate = async () => {
    const updatedAdmins = [...formData.admin];
    const adminExists = updatedAdmins.some((admin) => admin.adminUid === newAdmin.adminUid);
    if (Array.isArray(updatedAdmins) && adminExists && newAdmin.isDelete === false) {
      setErrorMessage('This user already exists. If you want to change role then delete and assign new role.');
    } else if (!newAdmin.isAdmin && !newAdmin.isEditor && newAdmin.isDelete === false) {
      setErrorMessage('You have to select a role as Editor or Admin');
    } else {      
      const updatedFormData = {
        ...formData,
        admin: newAdmin.isDelete ? updatedAdmins.filter((admin) => admin.adminUid !== newAdmin.adminUid) : [...updatedAdmins, newAdmin],
      };
      dispatch(companyUserAction({
        info: updatedFormData,
        uid: newAdmin.adminUid,
        companyId: companyInfo.companyId,
        isDelete: newAdmin.isDelete
      }));
      setErrorMessage('')
    }
  }
  
 
  const {companyEmployeeModal  } = useSelector((state) => state.modalTheme);
  useEffect(() => {
    setOpen(companyEmployeeModal)
  }, [companyEmployeeModal]);
  const handelarAdmin =() =>{
    setErrorMessage('')
    dispatch(companyEmployAdd({type:false, user:null}))
    
  }
  return (
    <Transition.Root show={open} onClick={handelarAdmin} as={Fragment} >
    <Dialog as="div" className="relative z-10" onClose={()=>{}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 dark:bg-gray__800 bg-gray__600 dark:bg-opacity-70 darl bg-opacity-90  transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg dark:bg-gray__900 bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all w-full sm:my-8  sm:w-full sm:max-w-md sm:p-6">
                <div className="flex items-center justify-between border-b dark:border-gray__700 border-gray__200">
                  <h1 className="mb-3 dark:text-ioty__white text-ioty__black">Assign Company Role </h1>
                  
                </div>
                <div>
                <fieldset>
      <div className="space-y-5">
                      <div>
                        <label
                          htmlFor="email"
                          className="block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900"
                        >
                          Title
                        </label>
                        <div className="mt-2">
                          <input
                            id="title"
                           
                            type="text"
                            name="title"
                            value={newAdmin.title}
                            onChange={(e)=>handleInputChange(e)}
                            className="block w-full rounded-md dark:bg-gray__600 border-0 pl-2 py-1.5 dark:text-white shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300  sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
       {companyNewUser && (
         <div>
         <ul className="mt-1 max-h-[6em] mt-2 overflow-y-auto">
         <li  className="cursor-pointer py-1">
             <div className="flex items-center">
               <img
                 src={companyNewUser.photoURL}
                 alt="Profile"
                 className="w-6 h-6 rounded-full mr-4"
               />
               <div>
                 <p className='text-sm dark:text-ioty__white text-ioty__black roboto'>{`${companyNewUser.firstName} ${companyNewUser.lastName}`}</p>
                 <p className='text-sm dark:text-ioty__white text-ioty__black roboto'>{companyNewUser.email}</p>
               </div>
               
             </div>
           </li>
           </ul>
         </div>
       )}
        
        <div class="grid grid-cols-3 gap-4">
  <div class=" p-4"><div className="relative flex items-start">
          
          <div className="flex h-6 items-center">
            <input
              id="isEditor"
              aria-describedby="offers-description"
              name="isEditor"
              type="radio"
              className="h-5 w-5 rounded border-gray-300 text-indigo-600"
              checked={newAdmin.isEditor}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label htmlFor="offers" className="font-medium text-ioty__balck dark:text-ioty__white">
             Employee
            </label>
            
          </div>
        </div></div>
  <div class="p-4"> <div className="relative flex items-start">

<div className="flex h-6 items-center">
  <input
    id="isAdmin"
    aria-describedby="offers-description"
    name="isAdmin"
    type="radio"
    className="h-5 w-5 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
    checked={newAdmin.isAdmin}
    onChange={(e) => handleInputChange(e)}
  />
</div>
<div className="ml-3 text-sm leading-6">
  <label htmlFor="offers" className="font-medium text-ioty__balck dark:text-ioty__white">
   Admin
  </label>
  
</div>
</div></div>

<div class="p-4"> <div className="relative flex items-start">

<div className="flex h-6 items-center">
  <input
    id="isDelete"
    aria-describedby="offers-description"
    name="isDelete"
    type="radio"
    className="h-5 w-5 rounded border-red-600 text-indigo-600 focus:ring-red-600"
    checked={newAdmin.isDelete}
    onChange={(e) => handleInputChange(e)}
  />
</div>
<div className="ml-3 text-sm leading-6">
  <label htmlFor="offers" className="font-medium text-ioty__balck dark:text-ioty__white">
   Delete
  </label>
  
</div>
</div></div>
</div>
       
      </div>
    </fieldset>
                 <button onClick={actionUpdate} className="float-right mt-8 text-sm  dark:bg-gray-700 bg-ioty__primary px-4 text-ioty__white font-roboto text-left rounded py-1"> Update</button>
                </div>
                <div><p className="text-red-400 text-xs ml-3 font-semibold">{errorMessage ? errorMessage :''} </p></div>
                <></>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}