import {
  faAdd,
  faCog,
  faEdit,
  faHandshake,
  faList12,
  faMapMarker,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { updateImageProject } from "../../redux/slices/common/fileUpload";
import { fetchProjectInfo,fetchFloorPlanInfo } from "../../redux/slices/project/projects";
import FloorplanList from '../floorplan/FloorplanList'
import {collaborateModalAction,settingModalProject } from '../../redux/slices/common/modalTheme'
import  ProjectSetting from '../common/ProjectSetting'

import CollaboratorList from './CollaboratorList'
function PorjectDetails() {
  const [targetImage, setTargetImage] = useState("");
  const { projectId, companyId } = useParams();
  const dispatch = useDispatch(); 
  const { userInfo } = useSelector((state) => state.userInfo);
  const { userCompanyInfo } = useSelector((state) => state.companySlice);
  const { companyModal ,projectSection} = useSelector((state) => state.modalTheme);
  const { isLoading } = useSelector((state) => state.fileUpload);

  const [company, setCompanyInfoUser] = useState();
  const [adminStatus, setAdminStatus] = useState("user");

  useEffect(() => {
    if (userCompanyInfo) {
      setCompanyInfoUser(userCompanyInfo);
      if (userCompanyInfo.companyInfo.admin && userInfo) {
        userCompanyInfo.companyInfo.admin.map((item) => {
          if (item.adminUid === userInfo.userInfoData.uid) {
            if (item.isEditor) {
              setAdminStatus("editor");
            } else if (item.isAdmin) {
              setAdminStatus("admin");
            }
          }
        });
      }
    }
  }, [userCompanyInfo]);
  useEffect(() => {
    dispatch(fetchProjectInfo(projectId));
  }, [projectId]);
  const { project, floorpans } = useSelector((state) => state.projectSlice);
  const hadleSetting = () => {
    dispatch(settingModalProject(true));
  };
  const handleImageChangeCover = async (event) => {
    setTargetImage("companyCover");
    if (event.target.files[0]) {
      dispatch(updateImageProject(event.target.files[0], project));
      event.target.value = null;
    }
  };
  const collaborateModal = () =>{
    dispatch(collaborateModalAction(true))
  }
  useEffect(() => {
    dispatch(fetchFloorPlanInfo(projectId));
  }, [projectId]);
  return (
    <div className="PorjectDetails">
      <ProjectSetting/>
      <>
        {project && (
          <>
            <div className="px-2 sm:10">
              <section className="bg-gray-2 0 dark:bg-gray__900 ">
                <div className="mx-auto px-0 md:container dark:bg-gray__900">
                  <div className="mx-auto w-full max-w-[1270px]  mb-0 rounded-b-lg border  border-stroke dark:border-gray__800 bg-white dark:bg-gray__800 pb-5 2xl:mb-0">
                    <div className=" z-20 h-[140px] sm:h-[100px] md:h-[200px]">
                      <img
                        src={project.coverPhoto}
                        alt="profile cover"
                        className="h-full w-full object-cover object-center"
                      />
                      <div className=" bottom-5 right-5 z-10">
                        <label className="flex bg-gray-700 ml-[85%]  -mt-[9%]  xl:ml-[95%] xl:-mt-[3%] h-9 w-9 cursor-pointer items-center justify-center rounded-full bg-primary text-gray__500 hover:bg-opacity-90">
                          <input
                            type="file"
                            onChange={handleImageChangeCover}
                            name="companyCover"
                            id="companyCover"
                            className="sr-only bg-gray__900"
                          />
                          {isLoading && targetImage === "companyCover" ? (
                            <FontAwesomeIcon
                              icon={faSpinner}
                              className="animate-spin mr-1.5 h-4 w-4"
                            />
                          ) : (
                            <span>
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 16 16"
                                className="fill-current"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M5.4453 1.63085C5.56894 1.44539 5.7771 1.33398 6 1.33398H10C10.2229 1.33398 10.4311 1.44539 10.5547 1.63085L11.6901 3.33398H14C14.5304 3.33398 15.0391 3.5447 15.4142 3.91977C15.7893 4.29484 16 4.80355 16 5.33398V12.6673C16 13.1978 15.7893 13.7065 15.4142 14.0815C15.0391 14.4566 14.5304 14.6673 14 14.6673H2C1.46957 14.6673 0.960859 14.4566 0.585786 14.0815C0.210714 13.7065 0 13.1978 0 12.6673V5.33398C0 4.80355 0.210714 4.29484 0.585786 3.91977C0.960859 3.5447 1.46957 3.33398 2 3.33398H4.30988L5.4453 1.63085ZM6.35679 2.66732L5.22137 4.37045C5.09772 4.55592 4.88957 4.66732 4.66667 4.66732H2C1.82319 4.66732 1.65362 4.73756 1.5286 4.86258C1.40357 4.9876 1.33333 5.15717 1.33333 5.33398V12.6673C1.33333 12.8441 1.40357 13.0137 1.5286 13.1387C1.65362 13.2637 1.82319 13.334 2 13.334H14C14.1768 13.334 14.3464 13.2637 14.4714 13.1387C14.5964 13.0137 14.6667 12.8441 14.6667 12.6673V5.33398C14.6667 5.15717 14.5964 4.9876 14.4714 4.86258C14.3464 4.73756 14.1768 4.66732 14 4.66732H11.3333C11.1104 4.66732 10.9023 4.55592 10.7786 4.37045L9.64321 2.66732H6.35679Z"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M7.99984 6.66732C6.89527 6.66732 5.99984 7.56275 5.99984 8.66732C5.99984 9.77189 6.89527 10.6673 7.99984 10.6673C9.10441 10.6673 9.99984 9.77189 9.99984 8.66732C9.99984 7.56275 9.10441 6.66732 7.99984 6.66732ZM4.6665 8.66732C4.6665 6.82637 6.15889 5.33398 7.99984 5.33398C9.84079 5.33398 11.3332 6.82637 11.3332 8.66732C11.3332 10.5083 9.84079 12.0007 7.99984 12.0007C6.15889 12.0007 4.6665 10.5083 4.6665 8.66732Z"
                                />
                              </svg>
                            </span>
                          )}
                        </label>
                        <div className="flex ml-[80%] -mt-[1%] sm:mt-[1%] lg:mt-[3%] xl:-mt-[1%] xl:ml-[95%]  h-16 xl:mr-4  items-center justify-center bottom-5 right-5 z-10">
                          {adminStatus && adminStatus === "admin" && (
                            <>
                            <Link
                              to={`/project/${project.companyId}/edit/${projectId}`}
                            >
                              <button className="dark:bg-gray__800  cursor-pointer text-gray-800 bg-ioty__white sm:p-0 lg:p-0 xl:p-1.5 px-2 rounded-lg text-right bottom-0  cursor-pointer  dark:text-gray__200 text-gray__400 mt-1">
                                <FontAwesomeIcon size="lg" icon={faEdit} />
                              </button>
                            </Link>
                             <button
                             onClick={hadleSetting}
                             className="dark:bg-gray__800 mr-2 mt-1 text-gray-800 bg-ioty__white  cursor-pointer p-1.5 px-2 rounded-lg text-right bottom-0   dark:text-gray__200 text-gray__400"
                           >
                             <FontAwesomeIcon size="lg" icon={faCog} />
                           </button>
                           </>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-wrap px-5   mt-8 sm:px-9 md:flex-nowrap">
                      <div className="w-full md:pl-1  xl:mt-2">
                        <div className="mb-0 items-center justify-between sm:flex">
                          <div>
                            <h4 className=" mt-2 mr-4 md:-mt-4 ml-1  text-xl dark:text-gray__300  xl:text-2xl font-semibold ">
                              {project.projectName}
                            </h4>

                            <div className="col-span-full text-sm flex mt-2 text-ioty__black dark:text-ioty__white items-center">
                              <FontAwesomeIcon
                                icon={faMapMarker}
                                className="  text-gray__400 mt-0 h-4 w-4 mr-2"
                              />
                              <h1 className="font-roboto mt-0  inline">
                                {project.projectLocation}
                              </h1>
                            </div>

                            <div className="w-full mb-0 mt-2 mx-auto w-full max-w-[1270px]">
                              <p className="text-sm text-body-color dark:text-gray__300 my-1">
                                {project.description}
                              </p>
                            </div>

                            <div className="sm:flex sm:items-center"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="sm:flex-auto -mt-[85%] sm:-mt-[38%]  md:-mt-[40%] lg:-mt-[30%] xl:-mt-[25%] 2xl:-mt-[20%]">
          <div className="relative ml-[15%] w-[70%] sm:ml-[15%] md:w-[40%] md:ml-[30%] mt-10 rounded-full mt-3 shadow focus:outline-none focus:ring-1 bg-gray__200 dark:bg-gray__200 px-4 py-2 flex items-center">
  <FontAwesomeIcon
    icon={faSearch}
    className="text-gray-400 mr-2"
  />
  <input
    type="text"
    placeholder="Search with name or email"
    // value={searchTerm}
    // onChange={handleChange}
    className="w-full bg-transparent border-none focus:ring-0 focus:outline-none"
  />
</div>
              
            </div> */}
                </div>
              </section>
              <section>                   

                <div className="mx-auto px-0 md:container dark:bg-gray__900">
                  <div className="mx-auto w-full max-w-[1270px]   mt-2 pb-5 2xl:mb-0">
                    <div className="flex justify-between">
                      <div className="flex">
                          <button
                           onClick={collaborateModal}
                            className="block rounded  bg-primary dark:bg-gray__800 px-3 py-1.5 text-center text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            <FontAwesomeIcon
                              icon={faHandshake}
                              className="  h-4 w-4 mr-3 rounded-full text-gray__100 dark:text-gray__200"
                            />{" "}
                            Colaborate
                          </button>

                       { project &&( <Link to={`/project-category/${project.projectId}`}>
                          <button
                           
                            className="block mx-1 rounded bg-primary dark:bg-gray__800 px-3 py-1.5 text-center text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            <FontAwesomeIcon
                              icon={faList12}
                              className="  h-4 w-4 mr-3 rounded-full text-gray__100 dark:text-gray__200"
                            />{" "}
                            Categories
                          </button>
                        </Link>)}
                      </div>
                        <Link to={`/company/${project.companyId}/project/${project.projectId}/new-floorpaln`}>
                          <button className="block rounded bg-primary dark:bg-gray__800 px-3 py-1.5 text-center text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                            <FontAwesomeIcon
                              icon={faAdd}
                              className="  h-4 w-4 mr-3 rounded-full text-gray__100 dark:text-gray__200"
                            />{" "}
                            Add Floorplan
                          </button>
                        </Link>
                      </div>
                  </div>
                  {projectSection !== 1 &&( <div className="mx-auto w-full max-w-[1270px]   mt-2 pb-5 2xl:mb-0">
                    <FloorplanList floorpans={floorpans} />
                  </div>)}
                  {projectSection === 1 &&( <div className="mx-auto w-full max-w-[1270px]   mt-2 pb-5 2xl:mb-0">
                    <CollaboratorList project={project} />
                  </div>)}
                 
                  
                </div>
              </section>
            </div>
          </>
        )}
      </>
    </div>
  );
}

export default PorjectDetails;
