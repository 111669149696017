import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import CompanyEdit from '../components/company/CompanyEdit';
import CompanyForm from '../components/company/CompanyForm';
import CompanyProfile from '../components/company/CompanyProfile';
import Error from '../components/company/Error';
import Panding from '../components/company/Panding';
import CompanyTable from '../components/company/admin//CompanyTable';
import { userCompany } from '../redux/slices/company/company';
import CompanyCollaborate from '../components/company/CompanyCollaborate'
import {comapnyProjects } from '../redux/slices/project/projects'

function Companies() {
    const dispatch = useDispatch(); 
    const { userInfo } = useSelector((state) => state.userInfo); 
    const { userCompanyInfo } = useSelector((state) => state.companySlice);
    const { companyModal } = useSelector((state) => state.modalTheme);
  
    const [company, setCompanyInfoUser] = useState();
    const [adminStatus, setAdminStatus] = useState('user');
  
    useEffect(() => {
      if (userInfo && userInfo.userInfoData) {
        dispatch(userCompany(userInfo.userInfoData.uid));
      }
    }, [userInfo, userInfo?.userInfoData]);
   
    useEffect(() => {
      
      if (userCompanyInfo) {
        setCompanyInfoUser(userCompanyInfo);
       if(userCompanyInfo.companyInfo.admin &&  userInfo) {
       
        userCompanyInfo.companyInfo.admin.map((item)=>{
          if(item.adminUid === userInfo.userInfoData.uid) {
            if(item.isEditor){
              setAdminStatus('editor')
            }
            else if(item.isAdmin){
              setAdminStatus('admin')
            }
          }
        }) 
       }
      }
    }, [userCompanyInfo]);
  
  
      
    if (userInfo === null) {
      return <Error />;
    }

  return (
    <div className="Companies">
      <>  
         <CompanyCollaborate adminStatus={adminStatus}  value={userCompanyInfo}/> 
      </>
    </div>
  );
}

export default Companies;
