import React, { Component } from 'react';
import Chart from 'react-apexcharts';

class ChartOne extends Component {

  constructor(props) {
    super(props);

    this.state = {
     
        chart: {
            // Your other chart options...
            toolbar: {
              show: true, // Set this to true to show the toolbar
              tools: {
                download: false, // Set this to false to disable the download button
              },
            },
          },   options: {},
      series: [44, 55, 41, 17, 15],
      labels: ['A', 'B', 'C', 'D', 'E']
    }
  }

  render() {

    return (
      <div className="donut mt-8">
        <Chart options={this.state.options} series={this.state.series}  type="donut"  height={295} />
      </div>
    );
  }
}

export default ChartOne;