import React from "react";

const ChatList = () => {
  return (
    <section className="bg-gray-2 py-[40px] border  border-stroke dark:border-gray__800 bg-white dark:bg-gray__800 pb-5">
      <div className="mx-auto px-4 md:container ">
        <div className="shadow-one mx-auto max-w-[435px] rounded-lg bg-white dark:bg-gray__800">
          
          <div style={{ height: "40em",}} className="min-h:[50em]">
            <form className="relative mb-7">
              <input
                type="text"
                className="w-full rounded border dark:bg-gray__300 border-stroke bg-gray-2 py-[10px] pl-5 pr-10 text-sm text-body-color outline-none focus:border-primary"
                placeholder="Search..."
              />
              <button className="absolute top-1/2 right-4 -translate-y-1/2">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.25 3C5.3505 3 3 5.3505 3 8.25C3 11.1495 5.3505 13.5 8.25 13.5C11.1495 13.5 13.5 11.1495 13.5 8.25C13.5 5.3505 11.1495 3 8.25 3ZM1.5 8.25C1.5 4.52208 4.52208 1.5 8.25 1.5C11.9779 1.5 15 4.52208 15 8.25C15 11.9779 11.9779 15 8.25 15C4.52208 15 1.5 11.9779 1.5 8.25Z"
                    fill="#637381"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.957 11.958C12.2499 11.6651 12.7247 11.6651 13.0176 11.958L16.2801 15.2205C16.573 15.5133 16.573 15.9882 16.2801 16.2811C15.9872 16.574 15.5124 16.574 15.2195 16.2811L11.957 13.0186C11.6641 12.7257 11.6641 12.2508 11.957 11.958Z"
                    fill="#637381"
                  />
                </svg>
              </button>
            </form>
            <div className="space-y-[10px]">
              <ChatItem
                active
                img="https://cdn.tailgrids.com/2.0/image/dashboard/images/chat-list/image-01.png"
                name="Henry Dholi"
                text="I cam across your profile and..."
              />
              <ChatItem
                active
                img="https://cdn.tailgrids.com/2.0/image/dashboard/images/chat-list/image-02.png"
                name="Mariya Desoja"
                text="I like your confidence 💪"
              />
              <ChatItem
                active
                img="https://cdn.tailgrids.com/2.0/image/dashboard/images/chat-list/image-03.png"
                name="Robert Jhon"
                text="Can you share your offer?"
              />
              <ChatItem
                img="https://cdn.tailgrids.com/2.0/image/dashboard/images/chat-list/image-04.png"
                name="Cody Fisher"
                text="I'm waiting for you response!"
              />
              <ChatItem
                active
                img="https://cdn.tailgrids.com/2.0/image/dashboard/images/chat-list/image-05.png"
                name="Jenny Wilson"
                text="I'm waiting for you response!"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChatList;

const ChatItem = ({ img, name, text, active }) => {
  return (
    <div className="flex cursor-pointer items-center rounded py-2 px-4 hover:bg-gray-2">
      <div className="relative mr-[14px] h-11 w-full max-w-[44px] rounded-full">
        <img
          src={img}
          alt="profile"
          className="h-full w-full object-cover object-center"
        />
        <span
          className={`absolute bottom-0 right-0 block h-3 w-3 rounded-full border-2 border-gray-2  ${
            active ? "bg-success" : "bg-danger"
          }`}
        ></span>
      </div>
      <div className="w-full">
        <h5 className="text-sm font-medium text-black  dark:text-gray__200">{name}</h5>
        <p className="text-sm text-body-color  dark:text-gray__200">{text}</p>
      </div>
    </div>
  );
};
