import { createSlice } from "@reduxjs/toolkit";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import { createUser } from "../../../firebase/collection/index";
import {
  notificationTostaAction,
  passwordResetModal,
  signInModal,
  signUpModal,
} from "../common/modalTheme";
import { fetchUserInfo, logOutremoveData } from "../user/user";

const authentication = createSlice({
  name: "authInfo",
  initialState: {
    error: "",
    user: null,
  },
  reducers: {
    signUpSuccess: (state, action) => {
      state.user = action.payload;
    },
    signUpFailure: (state, action) => {
      state.error = action.payload;
      state.user = null;
    },
    signInSuccess: (state, action) => {
      state.user = action.payload;
    },
    signInFailure: (state, action) => {
      state.error = action.payload;
      state.user = null;
    },
    passwordResetSuccess: (state, action) => {
      return {
        ...state,
        isPasswordResetEmailSent: true,
      };
    },
    passwordResetFailure: (state, action) => {
      state.error = action.payload;
    },
    resetEmailVerificationStatus: (state, action) => {
      return {
        ...state,
        isEmailVerificationSend: false,
      };
    },
    sendEmailVerification: (state, action) => {
      return {
        ...state,
        isEmailVerificationSend: true,
      };
    },
    loginSuccess: (state, action) => {
      return {
        ...state,
        uid: action.payload,
      };
    },
    logout: (state, action) => {
      state.user = action.payload;
    },
  },
});

// Asynchronous action creator for sign up
const signUpAction = (email, password) => async (dispatch) => {
  try {
    const userCredential = await createUser.createUserWithEmailAndPassword(
      email,
      password
    );
    const user = userCredential.user;
    await user.sendEmailVerification();
    dispatch(sendEmailVerification());
    dispatch(signUpSuccess(user.uid));
    dispatch(signUpModal({ type: true }));
    
    dispatch(
      notificationTostaAction({
        message: "Email Verification Link Send",
        type: "Sign Up",
        status: "error",
      }) 
    );
  } catch (error) {
    const modifiedErrorMessage = error.message.replace("Firebase: ", "");
    dispatch(signUpFailure(modifiedErrorMessage));
    dispatch(
      notificationTostaAction({
        message: modifiedErrorMessage,
        type: "Sign Up",
        status: "error",
      })
    );
  }
};
const signInAction = (email, password) => async (dispatch) => {
  try {
    const userCredential = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
    const user = userCredential.user;

    // Perform any additional actions upon successful sign-in if needed

    dispatch(signInSuccess(user.uid));
    dispatch(signInModal({ type: true }));
    if(user.emailVerified === false) {
    await user.sendEmailVerification();
    dispatch(sendEmailVerification());
    dispatch(
      notificationTostaAction({
        message: "Email Verification link sent to your email",
        type: "Sign In",
        status: "warning",
      })
    );
    }
    else {
      dispatch(
        notificationTostaAction({
          message: "Log in successfully",
          type: "Sign In",
          status: "success",
        })
      );
    }
    
  } catch (error) {
    const modifiedErrorMessage = error.message.replace("Firebase: ", "");
    dispatch(signInFailure(modifiedErrorMessage));
    dispatch(
      notificationTostaAction({
        message: modifiedErrorMessage,
        type: "Sign In",
        status: "error",
      })
    );
  }
};
const logOut = () => (dispatch) => {
  firebase.auth().signOut();
  dispatch(logout(null));
  dispatch(logOutremoveData())
};

const resendEmailVerificationAction = () => async (dispatch) => {
  try {
    const user = firebase.auth().currentUser;
    await user.sendEmailVerification();
    dispatch(sendEmailVerification());
    dispatch(
      notificationTostaAction({
        message: "Email verification link sent",
        type: "Email Verification",
        status: "success",
      })
    );
  } catch (error) {
    // Error handling code...
  }
};
// Asynchronous action creator for auto login
const autoLoginAction = () => (dispatch) => {
  createUser.onAuthStateChanged((user) => {
    if (user) {
      if (
        user.providerData &&
        user.providerData[0] &&
        user.providerData[0].providerId
      ) {
        const providerId = user.providerData[0].providerId;
        const providers = user.providerData;

        const desiredDate = new Date("2023-06-13"); 

        if (providerId === "password") {
          let data = {
            emailVerified: user.emailVerified,
            photoURL: user.photoURL,
            uid: user.uid,
            lastSignIn:user.metadata.lastSignInTime,
            isNewUser: user.metadata.creationTime !== user.metadata.lastSignInTime ? false : true,
            email: user.email,
            name: user.displayName,
            providerId: "email",
            isOldUser: new Date(user.metadata.lastSignInTime) <  desiredDate && !user.emailVerified ? true : false,
            device: providers.map((provider) => {
              const { providerId, displayName, email, phoneNumber, photoURL } = provider;
              return {
                providerId,
                displayName,
                email,
                phoneNumber,
                photoURL,
                deviceName: navigator.userAgent,
                browser: navigator.platform,
              };
            }),
          };

          dispatch(signUpSuccess(data));
          dispatch(fetchUserInfo(user.uid));
        } else if (providerId === "google.com") {
          let data = {
            emailVerified: user.emailVerified,
            photoURL: user.photoURL,
            lastSignIn: user.metadata.lastSignInTime,
            uid: user.uid,
            isNewUser: user.metadata.creationTime !== user.metadata.lastSignInTime ? false : true,
            email: user.email,
            name: user.displayName,
            providerId: "google",
            accountCreateTime: user.metadata.creationTime,
            device: providers.map((provider) => {
              const { providerId, displayName, email, phoneNumber, photoURL } = provider;
              return {
                providerId,
                displayName,
                email,
                phoneNumber,
                photoURL,
                deviceName: navigator.userAgent,
                browser: navigator.platform,
              };
            }),
          };
        
          dispatch(signUpSuccess(data));
          dispatch(fetchUserInfo(user.uid));
        } else if (providerId === "facebook.com") {
          let data = {
            emailVerified: true,
            photoURL: 'https://img.icons8.com/stickers/360/user-male-circle-skin-type-2.png',
            lastSignIn: user.metadata.lastSignInTime,
            uid: user.uid,
            isNewUser: user.metadata.creationTime !== user.metadata.lastSignInTime ? false : true,
            email: user.email,
            name: user.displayName,
            providerId: "google",
            accountCreateTime: user.metadata.creationTime,
            device: providers.map((provider) => {
              const { providerId, displayName, email, phoneNumber, photoURL } = provider;
              return {
                providerId,
                displayName,
                email,
                phoneNumber,
                photoURL,
                deviceName: navigator.userAgent,
                browser: navigator.platform,
              };
            }),
          };
        
          dispatch(signUpSuccess(data));
          dispatch(fetchUserInfo(user.uid));
        }
      } else {
        // User signed in anonymously or without provider data
        console.log("User signed in anonymously or without provider data");
      }
    } else {
      console.log("User not authenticated");
    }
  });
};
 

// Asynchronous action creator for signing in with Facebook
const signUpWithFacebookAction = () => async (dispatch) => {
  const provider = new firebase.auth.FacebookAuthProvider();
  
  try {
    console.log( 'facebook')
    const signInG = await firebase.auth().signInWithPopup(provider);
    dispatch(signUpModal({ type: true }));
if (signInG.additionalUserInfo.isNewUser) {
  dispatch(
    notificationTostaAction({
      message: "SIgn Up successfully",
      type: "Sign In",
      status: "success",
    })
  );
  autoLoginAction();
} else {
  dispatch(signUpModal({ type: true }));
  await firebase.auth().signOut();
  dispatch(logout());
  dispatch(
    notificationTostaAction({
      message: "This email already has an account",
      type: "Sign In",
      status: "error",
    })
  );
}  
  } catch (error) {
    const modifiedErrorMessage = error.message.replace("Firebase: ", "");
    dispatch(signInFailure(modifiedErrorMessage));
    dispatch(
      notificationTostaAction({
        message: modifiedErrorMessage,
        type: "Sign In",
        status: "error",
      })
    );
  }
}

const signInWithFacebookAction = () => async (dispatch) => {
  const provider = new firebase.auth.FacebookAuthProvider();
  
  try {
    console.log( 'facebook')
    const result = await firebase.auth().signInWithPopup(provider);
    const user = result.user;
    dispatch(loginSuccess(user.uid));
    dispatch(signInModal({ type: true }));
  } catch (error) {
    const modifiedErrorMessage = error.message.replace("Firebase: ", "");
    dispatch(signInModal({ type: true }));
    dispatch(signInFailure(modifiedErrorMessage));
    dispatch(
      notificationTostaAction({
        message: modifiedErrorMessage,
        type: "Sign In",
        status: "error",
      })
    );
  }
};

// Asynchronous action creator for signing in with Google
const signInWithGoogleAction = () => async (dispatch) => {
  const provider = new firebase.auth.GoogleAuthProvider();
  try {
    await createUser.signInWithPopup(provider);
    dispatch(signInModal({ type: true }));
    autoLoginAction();
    dispatch(
      notificationTostaAction({
        message: "Log in successfully",
        type: "Sign In",
        status: "success",
      })
    );
  } catch (error) {
    const modifiedErrorMessage = error.message.replace("Firebase: ", "");

    dispatch(
      notificationTostaAction({
        message: modifiedErrorMessage,
        type: "Sign In",
        status: "error",
      })
    );
    dispatch(signUpFailure(modifiedErrorMessage));
  }
};
const passwordResetAction = (email) => async (dispatch) => {
  try {
    await firebase.auth().sendPasswordResetEmail(email);
    dispatch(passwordResetSuccess());
    dispatch(passwordResetModal({
      type:true
    }))
    dispatch(
      notificationTostaAction({
        message: "Password reset email sent",
        type: "Password Reset",
        status: "success",
      })
    );
  } catch (error) {
    const modifiedErrorMessage = error.message.replace("Firebase: ", "");
    dispatch(passwordResetModal({
      type:true
    }))
    dispatch(passwordResetFailure(modifiedErrorMessage));
    dispatch(
      notificationTostaAction({
        message: modifiedErrorMessage,
        type: "Password Reset",
        status: "error",
      })
    );
  }
};
const signUpWithGoogleAction = () => async (dispatch) => {
  const provider = new firebase.auth.GoogleAuthProvider();
  try {
    dispatch(signUpModal({ type: true }));
    const signInG = await firebase.auth().signInWithPopup(provider);
    if (signInG.additionalUserInfo.isNewUser) {
      dispatch(
        notificationTostaAction({
          message: "SIgn Up successfully",
          type: "Sign In",
          status: "success",
        })
      );
      autoLoginAction();
    } else {
      dispatch(signUpModal({ type: true }));
      await firebase.auth().signOut();
      dispatch(logout());
      dispatch(
        notificationTostaAction({
          message: "This email already has an account",
          type: "Sign In",
          status: "error",
        })
      );
    }
  } catch (error) {
    dispatch(signUpModal({ type: true }));
    const modifiedErrorMessage = error.message.replace("Firebase: ", "");

    dispatch(
      notificationTostaAction({
        message: modifiedErrorMessage,
        type: "Sign In",
        status: "error",
      })
    );
    dispatch(signUpFailure(modifiedErrorMessage));
  }
};
export {
  autoLoginAction,
  logOut,
  passwordResetAction,
  resendEmailVerificationAction,
  signInAction, signInWithFacebookAction, signInWithGoogleAction,
  signUpAction,
  signUpWithFacebookAction,
  signUpWithGoogleAction
};

export const {
  signUpFailure,
  signUpSuccess,

  signInFailure,
  signInSuccess,
  sendEmailVerification,
  loginSuccess,
  googleAuthASuccess,
  passwordResetSuccess,
  passwordResetFailure,
  logout,
  
} = authentication.actions;

export default authentication.reducer;
