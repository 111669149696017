import { Link } from "react-router-dom";
function Package() {
    return (
      <div className="Package">
         <>
         <div className='grid grid-cols-1 sm:grid-cols-8 mt-10 xl:grid-cols-12 '>    
    <div className='mx-3 sm:mx-0 sm:col-start-2 sm:col-end-8 dark:bg-gary__700 dark:bg-gray__800 dark:bg-gray__900   rounded-xl  xl:col-start-3 xl:col-end-11 2xl:col-start-4 2xl:col-end-10'> 
          <div className="px-2 sm:10">
          <section className="relative z-10 flex min-h-[90vh] items-center overflow-hidden  dark:bg-gray__900 py-20 lg:py-[120px]">
      <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4 dark:bg-gray__900 lg:w-1/2">
            <div className="mb-12 w-full max-w-[470px] dark:text-ioty__white lg:mb-0">
              <h2 className="mb-6 text-[30px] font-bold uppercase text-primary sm:text-[40px]">
                404 Error
              </h2>
              <h3 className="mb-3 text-xl dark:text-ioty__white font-semibold text-black sm:text-3xl">
                Oops! The page you are looking for does not exist.
              </h3>
              <p className="mb-12 text-lg text-body-color">
                Uh oh, we can't seem to find the page you're looking for. Contact us for more information.
              </p>
              <Link
               to='/'
                className="inline-flex rounded border dark:border-gray__100 border-transparent bg-primary py-3 px-8 text-base font-medium text-white transition hover:bg-opacity-90"
              >
                Back to Homepage
              </Link>
            </div>
          </div>
          <div className="w-full px-4 lg:w-1/2">
            <div className="mx-auto text-center">
              <img
                src="https://cdn.tailgrids.com/2.0/image/application/images/404/image-08.svg"
                alt="404 image"
                className="mx-auto max-w-full"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="absolute top-0 left-0 -z-10 block h-full w-full bg-gray dark:bg-gray__900 lg:w-1/2"></div>
    </section>
          </div>
          </div>
          </div>
         </>
      </div>
    );
  }
  
  export default Package;