import {
  faSpinner
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from 'react-router-dom';
import {
  uploadImageProject
} from "../../redux/slices/common/fileUpload";
import { AllCategories } from "../../redux/slices/project/projects";

import { profileEditAction, } from '../../redux/slices/common/modalTheme';
import { writeProjectInfo } from '../../redux/slices/project/projects';
import { faEdit } from "@fortawesome/free-regular-svg-icons";
const notificationMethods = [
  { id: '1', title: 'Yes' },
  { id: '2', title: 'No' },
]
export default function UpdateForm({ adminRole}) { 
  const { id } = useParams();
  const { user } = useSelector((state) => state.authentication); 
    // eslint-disable-next-line no-unused-vars
  const { isLoading, projectImage } = useSelector((state) => state.fileUpload);
  const {userInfo} = useSelector((state) => state.userInfo);

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({     
    projectName: '',
    projectLocation: '',
    developer: '',
    slug: '',
    description: '',
    taxRate:0.7,
    categories:[],
    featuredProject:false,
    applies:{
      materials:true,
      labor:true
    },
    coverPhoto:'https://www.solidbackgrounds.com/images/4096x2304/4096x2304-french-sky-blue-solid-color-background.jpg'
  })
const [targetImage, setTargetImage] = useState('')
const [projectTab, setProjectTab] = useState(null)
useEffect(() => {
  if(projectImage)
  setFormData(prevFormData => ({
    ...prevFormData,
    coverPhoto: projectImage || prevFormData.coverPhoto
  }));
}, [projectImage]);
  const handleImageChangeCover = async (event) => {
    setTargetImage("companyCover");
    if (event.target.files[0]) {
      dispatch(
        uploadImageProject(event.target.files[0], "companyCover")
      );
      event.target.value = null;
    }
  };
  useEffect(() => {
    dispatch(AllCategories());
  }, []);
  // projectCategory
  const { categories, projectCategory } = useSelector((state) => state.projectSlice);
  useEffect(() => {
    if (projectCategory && projectCategory.length > 0) {
      setProjectTab(projectCategory);
      setFormData(prevFormData => ({
        ...prevFormData,
        categories: projectCategory
      }));
    } else if (categories && categories[0]?.categories) {
      setProjectTab(categories[0].categories);
      setFormData(prevFormData => ({
        ...prevFormData,
        categories: categories[0].categories
      }));
    } else {
      setProjectTab([]);
      setFormData(prevFormData => ({
        ...prevFormData,
        categories: []
      }));
    }
  }, [projectCategory, categories]);


  const handleInputChange = event => {
    const { name, value, type, checked } = event.target;
  
    if (type === 'checkbox') {
      setFormData(prevFormData => ({
        ...prevFormData,
        applies: {
          ...prevFormData.applies,
          [name]: checked
        }
      }));
    } else if (type === 'radio') {
      setFormData(prevFormData => ({
        ...prevFormData,
        featuredProject: value === 'Yes'
      }));
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value
      }));
    }
  };
  


 
  // const updateUserData = () => {
  //   dispatch(updateUserInfo(formData)); 
  //   dispatch(profileEditAction({type:false}))
  // };

  const saveProjectData = () => {
  
    dispatch(writeProjectInfo(formData, id)); 
    
    // // dispatch(profileEditAction({type:false}))
  };

  const isFormIncomplete = !formData.projectName || !formData.projectLocation || !formData.description ;
  return (
    <> 
    <div className='grid grid-cols-1 sm:grid-cols-8 xl:grid-cols-12'>       
      <div className='mx-3 sm:mx-0 sm:col-start-2 sm:col-end-8 xl:col-start-3 xl:col-end-11 2xl:col-start-4 2xl:col-end-10'>
           <div className='  rounded-b-lg border  border-stroke dark:border-gray__800 bg-white dark:bg-gray__800 pb-5 2xl:mb-0'>
           <div className=' px-1 sm:p-5 lg:p-8  md:pb-2'>
             <div className='mt-2 sm:mt-2 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6'>
               <div className='col-span-full'>
                 <label htmlFor='photo' className='block dark:text-gray__200 text-md xl:text-2xl xl:pb-4 font-medium  text-gray__900'>
                    New Project
                 </label>
                
               </div>
               <div className="col-span-full">
               <label htmlFor='lastName' className='block dark:text-gray__200 text-sm font-medium leading-6 mb-3 text-gray__900'>
                    Default Category  Or   <Link  to={`/project-category/${1212}`}><button className="mx-1 p-1"> <FontAwesomeIcon
                      icon={faEdit}
                      className=" mr-1.5 h-4 w-4"
                    /></button></Link>
                 </label>
                    {projectTab &&
                    projectTab.map((item) => (
                        <button className="py-1 px-2 bg-primary dark:bg-gray__900 mr-1 rounded text-sm text-white" key={item.categoryId}>{item.categoryName}</button>
                      ))}
                  </div>
               <div className="col-span-full md:mb-10 xl:mb-0">


              <img
                src={formData.coverPhoto}
                alt="profile cover"
                className="h-48 w-full object-cover object-center h-48"
              />
              <div className=" bottom-5 right-5 z-10">
                <label className="flex bg-gray-700 ml-[85%] -mt-[10%]  xl:ml-[95%] xl:-mt-[5%] h-9 w-9 cursor-pointer items-center justify-center rounded-full bg-primary text-gray__500 hover:bg-opacity-90">
                  <input
                    type="file"
                    onChange={handleImageChangeCover}
                    name="companyCover"
                    id="companyCover"
                    className="sr-only bg-gray__900"
                  />
                  {isLoading && targetImage === "companyCover" ? (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      className="animate-spin mr-1.5 h-4 w-4"
                    />
                  ) : (
                    <span>
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 16 16"
                        className="fill-current"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.4453 1.63085C5.56894 1.44539 5.7771 1.33398 6 1.33398H10C10.2229 1.33398 10.4311 1.44539 10.5547 1.63085L11.6901 3.33398H14C14.5304 3.33398 15.0391 3.5447 15.4142 3.91977C15.7893 4.29484 16 4.80355 16 5.33398V12.6673C16 13.1978 15.7893 13.7065 15.4142 14.0815C15.0391 14.4566 14.5304 14.6673 14 14.6673H2C1.46957 14.6673 0.960859 14.4566 0.585786 14.0815C0.210714 13.7065 0 13.1978 0 12.6673V5.33398C0 4.80355 0.210714 4.29484 0.585786 3.91977C0.960859 3.5447 1.46957 3.33398 2 3.33398H4.30988L5.4453 1.63085ZM6.35679 2.66732L5.22137 4.37045C5.09772 4.55592 4.88957 4.66732 4.66667 4.66732H2C1.82319 4.66732 1.65362 4.73756 1.5286 4.86258C1.40357 4.9876 1.33333 5.15717 1.33333 5.33398V12.6673C1.33333 12.8441 1.40357 13.0137 1.5286 13.1387C1.65362 13.2637 1.82319 13.334 2 13.334H14C14.1768 13.334 14.3464 13.2637 14.4714 13.1387C14.5964 13.0137 14.6667 12.8441 14.6667 12.6673V5.33398C14.6667 5.15717 14.5964 4.9876 14.4714 4.86258C14.3464 4.73756 14.1768 4.66732 14 4.66732H11.3333C11.1104 4.66732 10.9023 4.55592 10.7786 4.37045L9.64321 2.66732H6.35679Z"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.99984 6.66732C6.89527 6.66732 5.99984 7.56275 5.99984 8.66732C5.99984 9.77189 6.89527 10.6673 7.99984 10.6673C9.10441 10.6673 9.99984 9.77189 9.99984 8.66732C9.99984 7.56275 9.10441 6.66732 7.99984 6.66732ZM4.6665 8.66732C4.6665 6.82637 6.15889 5.33398 7.99984 5.33398C9.84079 5.33398 11.3332 6.82637 11.3332 8.66732C11.3332 10.5083 9.84079 12.0007 7.99984 12.0007C6.15889 12.0007 4.6665 10.5083 4.6665 8.66732Z"
                        />
                      </svg>
                    </span>
                  )}
                </label>
              
              </div>
              </div>
               <div className='col-span-full mt sm:col-span-3'>
                 <label htmlFor='firstName' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                    Name <span className="text-red-500">*</span>
                 </label>
                 <input
                   type='text'
                   name='projectName'
                   id='projectName'
                   autoComplete='given-name'
                   value={formData.projectName} 
                   onChange={handleInputChange}
                   className='block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6'
                 />
               </div> 
               <div className='col-span-full sm:col-span-3'>
                 <label htmlFor='lastName' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                    Developer 
                 </label>
                 <input
                   type='text'
                   name='developer'
                   id='developer'
                   autoComplete='family-name'
                   value={formData.developer} 
                   onChange={handleInputChange}
                   className='block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6'
                 />
               </div>
               <div className="col-span-full">
              <label htmlFor="about" className="block text-sm dark:text-ioty__white font-medium leading-6 text-gray-900">
                Description  <span className="text-red-500">*</span>
              </label>
              <div className="mt-2">
                <textarea
                  id="description"
                  name="description"
                  type='text'                
                  defaultValue={formData.description}
                  onChange={handleInputChange}
                  rows={3}
                  className="block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6"
                 
                />
              </div>
            </div> 
               <div className='col-span-full sm:col-span-2'>
                 <label htmlFor='lastName' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                    Location  <span className="text-red-500">*</span>
                 </label>
                 <input
                   type='text'
                   name='projectLocation'
                   id='projectLocation'
                   autoComplete='family-name'
                   value={formData.projectLocation} 
                   onChange={handleInputChange}
                   className='block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6'
                 />
               </div>
              
               <div className='col-span-full sm:col-span-2'>
                 <label htmlFor='lastName' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                    Slug  
                 </label>
                 <input
                   type='text'
                   name='slug'
                   id='slug'
                   autoComplete='family-name'
                   value={formData.slug} 
                   onChange={handleInputChange}
                   className='block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6'
                 />
               </div>
               <div className='col-span-full sm:col-span-2'>
                 <label htmlFor='lastName' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                    Tax Rate  
                 </label>
                 <input
                   type='number'
                   name='taxRate'
                   id='taxRate'
                   autoComplete='family-name'
                   value={formData.taxRate}
                   onChange={handleInputChange}
                   className='block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6'
                 />
               </div>
            
               <div className='col-span-full sm:col-span-3 ml-1'>
  <label htmlFor='lastName'  className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900 sm:mt-1'>
    Featured Project ?  
  </label>
  {notificationMethods.map((notificationMethod) => (
    <div key={notificationMethod.id} className="flex items-center">
      <input
        id={notificationMethod.id}
        name="featuredProject"
        type="radio"
        value={notificationMethod.title === 'Yes' ? 'Yes' : 'No'}
        checked={formData.featuredProject === (notificationMethod.title === 'Yes')}
        onChange={handleInputChange}
        className="h-5 w-5 mt-2 border-gray-300 text-indigo-600 focus:ring-indigo-600"
      />
      <label htmlFor={notificationMethod.id} className="ml-3 mt-2 dark:text-gray__200 block text-sm font-medium leading-6 text-gray__900">
        {notificationMethod.title}
      </label>
    </div>
  ))}
</div>

<div className='col-span-full sm:col-span-3'>
  <label htmlFor='lastName' className='block dark:text-gray__200 mt-1 text-sm font-medium leading-6 text-gray__900'>
    Apply to  
  </label>
  <div className="relative flex items-start">
    <div className="flex h-6 mt-1 items-center">
      <input
        id="materials"
        name="materials"
        type="checkbox"
        checked={formData.applies.materials}
        onChange={handleInputChange}
        className="h-4 w-4 rounded border-gray-300 mt-2 text-indigo-600 focus:ring-indigo-600"
      />
    </div>
    <div className="ml-3 text-sm leading-6 mt-2">
      <label htmlFor="materials" className="font-medium dark:text-gray__200 text-gray-900">
        Materials
      </label>
    </div>
  </div>
  <div className="relative flex items-start">
    <div className="flex h-6 mt-1 items-center">
      <input
        id="labor"
        name="labor"
        type="checkbox"
        checked={formData.applies.labor}
        onChange={handleInputChange}
        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
      />
    </div>
    <div className="ml-3 text-sm leading-6 mt-1">
      <label htmlFor="labor" className="font-medium dark:text-gray__200 text-gray-900">
        Labor
      </label>
    </div>
  </div>
</div>     
             </div>
           </div>
           
           <div className='flex mt-2 justify-end mr-10'>
            <Link to="/company">
            
            <button
              onClick={()=>{
                dispatch(profileEditAction({type:false}))
              }}
             className='inline-flex items-center px-8 py-2 border border-transparent text-sm  font-medium rounded-md shadow-sm  bg-ioty__white dark:bg-gray__900 text-ioty__black  focus:outline-none focus:border-blue-700   dark:text-ioty__white transition duration-150 ease-in-out disabled:opacity-50'
            
           >
             Cancel
           </button>
            
            </Link>
              <button
              
                onClick={saveProjectData}
                className='inline-flex items-center ml-1 px-8 py-2 border border-transparent text-sm  font-medium rounded-md shadow-sm text-white bg-primary dark:bg-gray__700  focus:outline-none focus:border-blue-700  transition duration-150 ease-in-out disabled:opacity-50'
                disabled={isFormIncomplete}
              >
                Save
              </button>
           
           </div>
         </div>
       
      </div>
    </div>
    </>
  );
}