import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { companyAdmin, companyEdit } from '../../../redux/slices/common/modalTheme';
import { fetchCompanyInfo } from "../../../redux/slices/company/company";
import { fetchAllUserInfo } from '../../../redux/slices/user/user';
import AccountAction from "./AccountAction";
import CompanyAdmin from './CompanyAdmin';

import {
  faClock,
  faEdit,
  faEnvelope,
  faMapMarker,
  faPhone
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function CompanyDetails() {
  const [value, setValue] = useState(null);
  const [employee, setEmployee] = useState(null);
  const [modal, setModal] = useState(false)
  const { companyAdminModal } = useSelector((state) => state.modalTheme);

  const { companyInfo, companyEmployee } = useSelector(
    (state) => state.companySlice
  );

  const dispatch = useDispatch();

  const { id } = useParams();
  const { companyNewUser} =  useSelector((state) => state.modalTheme)

  useEffect(() => {
    dispatch(fetchCompanyInfo(id));
    dispatch(fetchAllUserInfo())
  }, []);

  useEffect(() => {
    if (companyInfo) {
      setValue(companyInfo);
      setEmployee(companyEmployee);
    }
  }, [companyInfo, companyEmployee]);
   
  if (!value || !value.companyInfo) {
    // Add loading state or return null while waiting for data
    return null;
  }


  return (
    <div className="grid grid-cols-1 roboto sm:grid-cols-8 xl:grid-cols-12">
      <AccountAction modal ={ modal}/>
     
      <div className="mx-3 sm:mx-0 sm:col-start-2 sm:col-end-8 xl:col-start-2 xl:col-end-12 2xl:col-start-3 2xl:col-end-11">
        <div className="grid grid-cols-1  lg:grid-cols-3 gap-2">
          <div className="w-full flex flex-col  ">
            <div className="border-stroke relative my-1 flex border border-gray__200 rounded dark:border-gray__700 bg-white dark:bg-gray__800 p-6">
              <div className="mr-5 h-11 w-full max-w-[44px] overflow-hidden rounded-full lg:mr-4 lg:h-10 lg:max-w-[40px] xl:mr-5 xl:h-11 xl:max-w-[44px]">
                <img
                  src={value.companyInfo.photoURL}
                  alt="author"
                  className="h-full w-full object-cover object-center"
                />
              </div>
              <div className="w-full">
                  <h1 className="hover:text-primary mb-3 inline-block pr-4 text-base font-medium text-ioty__black dark:text-ioty__white sm:text-lg lg:text-base xl:text-lg">
                    {value.companyInfo.companyName}
                  </h1>
                <div className="col-span-full text-sm flex mt-0 text-ioty__black dark:text-ioty__white items-center">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className=" text-gray__400 dark:text-ioty mt-2 h-4 w-4 mr-6"
                  />
                  <h3 className="font-roboto mt-2  inline">
                    {value.companyInfo.companyEmail}
                  </h3>
                </div>
                {value && value.companyInfo.address && (
                  <div className="col-span-full text-sm flex mt-1 text-ioty__black dark:text-ioty__white items-center">
                    <FontAwesomeIcon
                      icon={faClock}
                      className="  text-gray__400 mt-2 h-4 w-4 mr-6"
                    />
                    <h1 className="font-roboto mt-2 inline">
                      {" "}
                      {moment(value.companyInfo.createTime).format(
                        "DD MMM YYYY"
                      )}{" "}
                      ( {moment(value.companyInfo.createTime).fromNow()} )
                    </h1>
                  </div>
                )}

                {value && value.companyInfo.address && (
                  <div className="col-span-full text-sm flex mt-2 text-ioty__black dark:text-ioty__white items-center">
                    <FontAwesomeIcon
                      icon={faMapMarker}
                      className="  text-gray__400 mt-0 h-4 w-4 mr-6"
                    />
                    <h1 className="font-roboto mt-0  inline">
                      {value.companyInfo.address}{" "}
                      {value.companyInfo.zipCode ? "," : ""}{" "}
                      {value.companyInfo.zipCode}
                      {value.companyInfo.city ? "," : ""}{" "}
                      {value.companyInfo.city}{" "}
                      {value.companyInfo.state ? "," : ""}{" "}
                      {value.companyInfo.state}
                    </h1>
                  </div>
                )}

                {value && value.companyInfo.phoneNumber && (
                  <div className="col-span-full text-sm flex mt-2 text-ioty__black dark:text-ioty__white items-center">
                    <FontAwesomeIcon
                      icon={faPhone}
                      className="  text-gray__400 mt-0 h-4 w-4 mr-6"
                    />
                    <h1 className="font-roboto mt-0  inline">
                      {value.companyInfo.phoneNumber}
                    </h1>
                  </div>
                )}
              </div>
              <Link to={`/companies/edit/${value.companyId}`}>
                <button className="text-ioty__balck dark:text-ioty__white hover:text-primary absolute right-[18px] top-[18px]">
                  <FontAwesomeIcon icon={faEdit} />
                </button>
              </Link>
            </div>
          </div>
          <div className="tex-center">
            <div className="border-stroke relative my-1 flex border border-gray__200 rounded dark:border-gray__700 bg-white dark:bg-gray__800 p-6">
              <div className="w-full  min-h-[11em]">
                <h1 className="text-ioty__black tex-center text-lg xl:text-xl dark:text-ioty__white">
                  {" "}
                  Action
                </h1>
                
                {value && value.companyInfo && (
                  <div>
                    <div className="col-span-full text-sm flex mt-4 font-roboto text-ioty__black dark:text-ioty__white items-center">
                      <h1 className="font-roboto mt-0  inline">
                        Company Status: {" "}
                        {value.companyInfo.isStatus ? " Active" : " Disabled"}
                      </h1>
                    </div>
                    <div className="span-full text-sm flex mt-4 text-ioty__black dark:text-ioty__white items-center">
                      <h1 className="font-roboto mt-0  inline">
                        Package:{" "}
                        {value.companyInfo.isPremium ? " Premium" : " Basic"}
                      </h1>
                    </div>
                    <div className="span-full text-sm flex mt-4 text-ioty__black dark:text-ioty__white items-center">
                      <h1 className="font-roboto mt-0  inline">
                        {" "}
                        {value.isPending
                          ? " Account Pending "
                          : " Account Approved"}
                      </h1>
                    </div>
                  </div>
                )}
              </div>
             
                <button onClick={()=>dispatch(companyEdit(true))} className="text-ioty__balck dark:text-ioty__white hover:text-primary absolute right-[18px] top-[18px]">
                  <FontAwesomeIcon icon={faEdit} />
                </button>
            
            </div>
          </div>
          <div className=" flex flex-col   border-stroke relative my-1 flex border border-gray__200 rounded dark:border-gray__700 bg-white dark:bg-gray__800 p-6">
           
            {companyAdminModal && ( <div className="text-ioty__black tex-center text-lg xl:text-lg roboto dark:text-ioty__white mb-2">
              Add or Remove <button onClick={()=>{
                dispatch(companyAdmin(false))
              }} className="float-right text-sm bg-ioty__primary dark:bg-gray__900 py-1 px-3 rounded-lg"> Back</button>
            </div> )}
            {!companyAdminModal && ( <div className="text-ioty__black tex-center text-lg xl:text-lg roboto dark:text-ioty__white mb-2">
                <button onClick={()=>{
                dispatch(companyAdmin(true))
              }} className="float-right text-sm bg-primary text-white dark:bg-gray__900 py-1 px-3 rounded-lg">Add / Remove</button>
            </div> )}
            {companyAdminModal && ( <div className=" overflow-y-auto"> <CompanyAdmin/></div>)}
            {!companyAdminModal && (
                <div className="max-h-[8.5em] overflow-y-auto">
                {employee &&
                  employee.map((item, index) => (
                    <li
                      key={index}
                      className="flex justify-between ring-1 ring-gray__200 dark:ring-gray__900 my-1  py-1"
                    >
                      <div className="flex gap-x-4">
                        <img
                          className="h-8 w-8 flex-none rounded-full bg-gray-900"
                          src={item.photoURL}
                          alt=""
                        />
                        <div className="min-w-0 flex-auto">
                          <p className="text-xs font-semibold dark:text-ioty__white text-ioty__black">
                            {item.firstName} {item.lastName}
                          </p>
                          <p className="mt-1 truncate text-xs dark:text-ioty__white text-ioty__black">
                            {item.email}{" "}
                          </p>
                        </div>
                      </div>
                      
                    </li>
                  ))}
              </div>
            )}
          
          </div>
        </div>
      </div>
    </div>
  );
}
export default CompanyDetails;
