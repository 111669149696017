import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { companyEdit } from '../../../redux/slices/common/modalTheme';
import { updateCompanyAction } from '../../../redux/slices/company/company';

export default function AccountAction() {
    const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const { isLoading, downloadURLLogo } = useSelector((state) => state.fileUpload);
  const {companyInfo} = useSelector((state) => state.companySlice);
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState({
    companyName: '',
    phoneNumber: '',
    address: '',
    city: '',
    isPremium: false,
    companyEmail: '',
    isStatus: true,
    zipCode: '',
    state: '',
    admin:[],
    photoURL: 'https://img.freepik.com/free-photo/white-paper-background-simple-diy-craft_53876-146283.jpg',
    coverPhoto:'https://img.freepik.com/free-photo/white-paper-background-simple-diy-craft_53876-146283.jpg'
  })
  useEffect(() => {
    if (companyInfo  && companyInfo.companyInfo) {  
      setFormData((prevFormData) => ({
        ...prevFormData,
        companyName: companyInfo.companyInfo.companyName || '',
        phoneNumber: companyInfo.companyInfo.phoneNumber || '',
        address: companyInfo.companyInfo.address || '',
        city: companyInfo.companyInfo.city || '',
        about:companyInfo.companyInfo.about || '',
        title:companyInfo.companyInfo.title || '',
        isPremium: companyInfo.companyInfo.isPremium || false,
        companyEmail: companyInfo.companyInfo.companyEmail || '',
        creatorEmail: companyInfo.companyInfo.creatorEmail || '',
        isStatus: companyInfo.companyInfo.isStatus || true,
        zipCode: companyInfo.companyInfo.zipCode || '',
        state: companyInfo.companyInfo.state || '',
        admin: companyInfo.companyInfo.admin || [],
        photoURL: companyInfo.companyInfo.photoURL || 'https://img.freepik.com/free-photo/white-paper-background-simple-diy-craft_53876-146283.jpg',
        coverPhoto: companyInfo.companyInfo.coverPhoto || 'https://img.freepik.com/free-photo/white-paper-background-simple-diy-craft_53876-146283.jpg',
        creatorUid: companyInfo.companyInfo.creatorUid || '',
        createTime: companyInfo.companyInfo.createTime || 0
      }));
      setStatus(companyInfo.isPending)
      
    }
  }, [companyInfo]);
 

  useEffect(()=>{
    if(downloadURLLogo) {
      setFormData({
        ...formData,
        photoURL:downloadURLLogo ? downloadURLLogo :'https://img.freepik.com/free-photo/white-paper-background-simple-diy-craft_53876-146283.jpg'
      })
     }
  },[downloadURLLogo])
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'radio' ? (checked ? value : '') : value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
    }));
  };;


  const actionUpdate = () => {
  
    dispatch(updateCompanyAction({
        info:formData, pending:status, id:companyInfo.companyId
    }))
  }

  const {companyModal  } = useSelector((state) => state.modalTheme);
  useEffect(() => {
    setOpen(companyModal)
  }, [companyModal]);

  return (
    <Transition.Root show={open} onClick={()=>{
        dispatch(companyEdit(false))
    }} as={Fragment} >
    <Dialog as="div" className="relative z-10" onClose={()=>{}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 dark:bg-gray__800 bg-gray__600 dark:bg-opacity-70 darl bg-opacity-90  transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg dark:bg-gray__900 bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all w-full sm:my-8  sm:w-full sm:max-w-md sm:p-6">
                <div className="flex items-center justify-between border-b dark:border-gray__700 border-gray__200">
                  <h1 className="mb-3 dark:text-ioty__white text-ioty__black">Update Account Status </h1>
                  
                </div>
                <div>
                <fieldset>
      <div className="space-y-5">
      <div className="relative mt-5 flex items-start">
  <div className="flex h-6 items-center">
    <input
      id="isStatus"
      aria-describedby="comments-description"
      name="isStatus" // Updated to match the state name
      type="radio"
      value={formData.isStatus}
      className="h-5 w-5 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
      checked={formData.isStatus}
      onChange={(e) => handleInputChange(e)}
    />
    
  </div>
  <div className="ml-3 text-sm leading-6">
    <label htmlFor="comments" className="font-medium text-ioty__black dark:text-ioty__white">
      Company Status
    </label>
  </div>
</div>
        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            <input
              id="isPremium"
              aria-describedby="candidates-description"
              name="isPremium"
              type="radio"
              className="h-5 w-5 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              checked={formData.isPremium}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label htmlFor="candidates" className="font-medium text-ioty__balck dark:text-ioty__white">
               Premium approved
            </label>
            
          </div>
        </div>
        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            <input
              id="offers"
              aria-describedby="offers-description"
              name="offers"
              type="radio"
              className="h-5 w-5 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              checked={status === false}
              onChange={((e) => handleInputChange(e))}
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label htmlFor="offers" className="font-medium text-ioty__balck dark:text-ioty__white">
                Approved
            </label>
            
          </div>
        </div>
      </div>
    </fieldset>
                 <button onClick={actionUpdate} className="float-right mt-8 text-sm  dark:bg-gray__700 bg-primary px-4 text-ioty__white font-roboto text-left rounded py-1"> Update</button>
                 <p className="text-sm text-red-400 mt-7 roboto"> Have to work....</p>
                </div>
                <></>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
