import { createSlice } from '@reduxjs/toolkit';
import { companies, users } from '../../../firebase/collection/index';
import { uploadImageToStorage } from '../../../firebase/firestore';
import { fetchCompanyInfo } from '../company/company';
import { updateCoverPhoto } from '../project/projects';
import { fetchUserInfo } from '../user/user';
import { notificationTostaAction } from './modalTheme';


const initialState = {
  isLoading: false,
  error: null,
  downloadURL: null,
  projectImage:null,
  downloadUrlFloorplan:null,
  subCategoryImage:null
};

const imageSlice = createSlice({
  name: 'image',
  initialState,
  reducers: {
    uploadImageStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    uploadImageSuccess: (state, action) => {
      state.isLoading = false;
      state.downloadURLUser = action.payload;
    },
    uploadLogoSuccess: (state, action) => {
      state.isLoading = false;
      state.downloadURLLogo = action.payload;
    },
    uploadFloorplanSuccess: (state, action) => {
      state.isLoading = false;
      state.downloadUrlFloorplan = action.payload;
    },
    uploadImageFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    uploaSubCategorySuccess: (state, action) => {
      state.isLoading = false;
      state.subCategoryImage = action.payload;
    },
    uploadProjectImage: (state, action) => {
      state.isLoading = false;
      state.projectImage = action.payload;
    },
    uploadImageSuccessCover: (state, action) => {
      state.isLoading = false;
      state.downloadURLCover = action.payload;
    },
    uploadImageFailureCover: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { uploadImageStart,uploaSubCategorySuccess, uploadLogoSuccess,uploadFloorplanSuccess, uploadImageSuccess,uploadImageSuccessCover,uploadProjectImage, uploadImageFailureCover, uploadImageFailure } =
  imageSlice.actions;


export const uploadImage = (file, folder, id) => async (dispatch) => {
  try {
    dispatch(uploadImageStart());

    // Upload the image to Firebase Storage
    const downloadURL = await uploadImageToStorage(file, folder);
   
    // Create the Firestore URL
    // const firestoreURL = await createFirestoreURL(downloadURL);
    
    dispatch(uploadImageSuccess(downloadURL));
    const documentRef =users.doc(id);
    const documentSnapshot = await documentRef.get();
    const documentData = documentSnapshot.data();
    documentData.userInfoData.photoURL = downloadURL;

    await documentRef.update(documentData).then(()=>{
      dispatch(fetchUserInfo(id))
      dispatch( notificationTostaAction({
        message: "Profile Picture Update",
        type: "Sign Up",
        status: "success",
      }) )
    })
  } catch (error) {
    
    dispatch(uploadImageFailure(error.message));
  }
};

export const uploadLogo = (file, folder) => async (dispatch) => {
  try {
    dispatch(uploadImageStart());

    // Upload the image to Firebase Storage
    const downloadURL = await uploadImageToStorage(file, folder);
   
    // Create the Firestore URL
    // const firestoreURL = await createFirestoreURL(downloadURL);
    dispatch(uploadLogoSuccess(downloadURL));
    dispatch( notificationTostaAction({
      message: "Profile Picture Update",
      type: "Sign Up",
      status: "success",
    }) )
    // const documentRef =users.doc(id);
    // const documentSnapshot = await documentRef.get();
    // const documentData = documentSnapshot.data();
    // documentData.userInfoData.photoURL = downloadURL;
    // await documentRef.update(documentData).then(()=>{
    //   dispatch(fetchUserInfo(id))
   
    // })
  } catch (error) {
    
    dispatch(uploadImageFailure(error.message));
  }
};

export const upLoadFloarImage = (file, folder) => async (dispatch) => {
  try {
    dispatch(uploadImageStart());

    // Upload the image to Firebase Storage
    const downloadURL = await uploadImageToStorage(file, folder);
   
    // Create the Firestore URL
    // const firestoreURL = await createFirestoreURL(downloadURL);
    dispatch(uploadFloorplanSuccess(downloadURL));
    dispatch( notificationTostaAction({
      message: "Floorplan Plan",
      type: "Sign Up",
      status: "success",
    }) )
    // const documentRef =users.doc(id);
    // const documentSnapshot = await documentRef.get();
    // const documentData = documentSnapshot.data();
    // documentData.userInfoData.photoURL = downloadURL;
    // await documentRef.update(documentData).then(()=>{
    //   dispatch(fetchUserInfo(id))
   
    // })
  } catch (error) {
    
    dispatch(uploadImageFailure(error.message));
  }
};

export const upLoadSubCategoryImage = (file, folder) => async (dispatch) => {
  try {
    dispatch(uploadImageStart());

    // Upload the image to Firebase Storage
    const downloadURL = await uploadImageToStorage(file, 'subCategory');
   
    // Create the Firestore URL
    // const firestoreURL = await createFirestoreURL(downloadURL);
    dispatch(uploaSubCategorySuccess(downloadURL));
  
  } catch (error) {
    
    dispatch(uploadImageFailure(error.message));
  }
};

export const uploadImageCover = (file, folder, id) => async (dispatch) => {
  try {
    dispatch(uploadImageStart());

    // Upload the image to Firebase Storage
    const downloadURL = await uploadImageToStorage(file, folder);
   
    // Create the Firestore URL
    // const firestoreURL = await createFirestoreURL(downloadURL);
    
    dispatch(uploadImageSuccessCover(downloadURL));
    const documentRef =users.doc(id);
    const documentSnapshot = await documentRef.get();
    const documentData = documentSnapshot.data();
    documentData.userInfoData.coverPhoto = downloadURL;
    await documentRef.update(documentData).then(()=>{
      dispatch(fetchUserInfo(id))
      dispatch(
        notificationTostaAction({
          message: "Cover Photo Update",
          type: "Sign Up",
          status: "success",
        }) 
      )
    })
    
  } catch (error) {
    dispatch(uploadImageFailureCover(error.message));
  }
};


export const updateImageProject = (file, info) => async (dispatch) => {
  console.log(file, info) 
  try {
     dispatch(uploadImageStart());

    // Upload the image to Firebase Storage

     const downloadURL = await uploadImageToStorage(file, 'project');
   
    // Create the Firestore URL
    // const firestoreURL = await createFirestoreURL(downloadURL);
    
     
     
     dispatch(updateCoverPhoto( info.companyId, info.projectId, downloadURL,))
     dispatch(uploadImageSuccessCover(''));
   
}
catch (error) {
  dispatch(uploadImageFailureCover(error.message));
}


};
export const uploadImageProject = (file, folder) => async (dispatch) => {
  try {
    dispatch(uploadImageStart());

    // Upload the image to Firebase Storage
    const downloadURL = await uploadImageToStorage(file, folder);
   
    // Create the Firestore URL
    // const firestoreURL = await createFirestoreURL(downloadURL);
    
    dispatch(uploadProjectImage(downloadURL));
   
    // await documentRef.update(documentData).then(()=>{
    //   dispatch(fetchUserInfo(id))
    //   dispatch(
    //     notificationTostaAction({
    //       message: "Cover Photo Update",
    //       type: "Sign Up",
    //       status: "success",
    //     }) 
    //   )
    // })
    
  } catch (error) {
    dispatch(uploadImageFailureCover(error.message));
  }
};

export const uploadImageCompany = (file, folder, companyDetails) => async (dispatch) => {
  try {
    dispatch(uploadImageStart());
   
    // Upload the image to Firebase Storage
    const downloadURL = await uploadImageToStorage(file, folder);
    dispatch(uploadImageSuccess(downloadURL));
    // Create the Firestore URL
    // const firestoreURL = await createFirestoreURL(downloadURL);
    
   
    const documentRef =companies.doc(companyDetails.companyId);
    const documentSnapshot = await documentRef.get();
    const documentData = documentSnapshot.data();
    documentData.companyInfo.photoURL = downloadURL;
    await documentRef.update(documentData).then(()=>{
      dispatch(fetchCompanyInfo(companyDetails.companyId))
      dispatch(
        notificationTostaAction({
          message: "Company Logo Update",
          type: "Sign Up",
          status: "success",
        }) 
      )
    })
    
  } catch (error) {
    dispatch(uploadImageFailureCover(error.message));
  }
};

export const uploadImageCoverCompany = (file, folder, companyDetails) => async (dispatch) => {
  console.log(file, folder, companyDetails)
  try {
    dispatch(uploadImageStart());
   
    // Upload the image to Firebase Storage
    const downloadURL = await uploadImageToStorage(file, folder);
    dispatch(uploadImageSuccess(downloadURL));
    // Create the Firestore URL
    // const firestoreURL = await createFirestoreURL(downloadURL);
    
   
    const documentRef =companies.doc(companyDetails.companyId);
    const documentSnapshot = await documentRef.get();
    const documentData = documentSnapshot.data();
    documentData.companyInfo.coverPhoto = downloadURL;
    await documentRef.update(documentData).then(()=>{
      dispatch(fetchCompanyInfo(companyDetails.companyId))
      dispatch(
        notificationTostaAction({
          message: "Company Logo Update",
          type: "Sign Up",
          status: "success",
        }) 
      )
    })
    
  } catch (error) {
    dispatch(uploadImageFailureCover(error.message));
  }
};

export default imageSlice.reducer;