import { useEffect } from 'react';
import {allProjects } from '../../../redux/slices/project/projects'
import { faEdit, faSearch } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from 'react-redux';
import ProjectList from './ProjectList'

function Project() {
    const dispatch = useDispatch()
    useEffect(()=>{
       dispatch(allProjects())
    },[])
    const {publicProjectList } = useSelector((state) => state.projectSlice);
    console.log(publicProjectList, 'publicProjectListpublicProjectList')
    return (
      <div className="Project">
         <>
         <div className="mx-auto w-full max-w-[1270px]  mb-0 rounded-b-lg   pb-5 2xl:mb-0">
         <div
                className="relative w-full w-[90%] ml-[0%] md:w-[50%] md:ml-[25%] mt-10 rounded-full mt-3 shadow focus:outline-none focus:ring-1 bg-gray__200 dark:bg-gray__600 py-2"
                style={{ minHeight: "40px" }}
              >
                <FontAwesomeIcon
                  icon={faSearch}
                  className="absolute text-gray-400 dark:text-white"
                  style={{
                    top: "50%",
                    left: "10px",
                    transform: "translateY(-50%)",
                  }}
                />
                <input
                  type="text"
                  placeholder="Search with name or email"
                //   value={searchTerm}
                //   onChange={handleChange}
                  className="w-full bg-transparent border-none dark:text-gray__200 focus:ring-0 focus:outline-none pl-10"
                />
              </div>
              <ProjectList publicProjectList ={publicProjectList}/>
          </div>
         </>
      </div>
    );
  }
  
  export default Project;