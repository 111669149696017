import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import CompanyEdit from '../components/company/CompanyEdit';
import CompanyForm from '../components/company/CompanyForm';
import CompanyProfile from '../components/company/CompanyProfile';
import Error from '../components/company/Error';
import Panding from '../components/company/Panding';
import CompanyTable from '../components/company/admin//CompanyTable';
import { userCompany } from '../redux/slices/company/company';

function Companies() {
  const dispatch = useDispatch(); 
  const { userInfo } = useSelector((state) => state.userInfo); 
  const { userCompanyInfo } = useSelector((state) => state.companySlice);
  const { companyModal } = useSelector((state) => state.modalTheme);

  const [company, setCompanyInfoUser] = useState();
  const [adminStatus, setAdminStatus] = useState('user');

  useEffect(() => {
    if (userInfo && userInfo.userInfoData) {
      dispatch(userCompany(userInfo.userInfoData.uid));
    }
  }, [userInfo, userInfo?.userInfoData]);
 
  useEffect(() => {
    
    if (userCompanyInfo) {
      setCompanyInfoUser(userCompanyInfo);
     if(userCompanyInfo.companyInfo.admin &&  userInfo) {
     
      userCompanyInfo.companyInfo.admin.map((item)=>{
        if(item.adminUid === userInfo.userInfoData.uid) {
          if(item.isEditor){
            setAdminStatus('editor')
          }
          else if(item.isAdmin){
            setAdminStatus('admin')
          }
        }
      }) 
     }
    }
  }, [userCompanyInfo]);


    
  if (userInfo === null) {
    return <Error />;
  }

  return (
    <div className="Companies">
      <>
      {userInfo && userInfo.isAdmin && (
                      userInfo.isAdmin.isSystemAdmin ? <CompanyTable/> :  <div className="px-2 sm:10">
                      {!userInfo && !companyModal && (<Error/>)}
                      { !company  && (<CompanyForm/>)}
                      {company && company.companyInfo && company.isPending && !companyModal &&(<Panding/>)}                  
                      {company && company.companyInfo && company.isPending === false &&  !companyModal &&<CompanyProfile adminStatus={adminStatus}  value={company}/> }
                       {companyModal && (<CompanyEdit/>)} 
                      
                    </div>)}
       
      </>
    </div>
  );
}

export default Companies;
