import { createSlice } from '@reduxjs/toolkit';
import { items } from '../../../firebase/collection/index';



const productSlice = createSlice({
  name: 'company',
  initialState: {
     products: null
     },

  reducers: {
    setProducts(state, action) {
      state.products = action.payload;
    } 
}
})

export const { setProducts } = productSlice.actions;
export const allProducts = () => {

    return (dispatch) => {
        items
        .get()
        .then((querySnapshot) => {
          
          const allProducts = querySnapshot.docs.map((doc) => doc.data())
          dispatch(setProducts(allProducts));
          console.log('all project', allProducts)
       
        })
        .catch((error) => {
          console.log('Error getting documents:', error);
        });
    };
  };

  export default productSlice.reducer;