import { createSlice } from '@reduxjs/toolkit';
import { getDatabase, ref, set } from "firebase/database";
import { projects, floorpans, categories } from '../../../firebase/collection/index';
import { collaborateModalAction, notificationTostaAction, setLoading } from "../common/modalTheme";
import {sendEmail} from '../common/mailchimpSlice'


const projectSlice = createSlice({
  name: 'company',
  initialState: { companyInfo: null,collaborateProjectApproval:null,companyEmployee:null, userCompanyInfo: null, companyProjects:null,project:null, floorpans:null,floorplanDetails:null, categories:null,projectCategory:null ,collaborateProject:null, publicProjectList:null },

  reducers: {
    setCompanyInfo(state, action) {
      state.companyInfo = action.payload;
    },
    setAllProjects(state, action){
      state.publicProjectList = action.payload;
    },
    setCategoriesCustome(state, action) {
      state.projectCategory = action.payload;
    },
    setfloorplanData(state, action){
      state.floorpans = action.payload;
    },
    setFloorPlanDetails(state, action) {
      state.floorplanDetails = action.payload;;
    },
    clearCompanyInfo(state) {
      state.companyInfo = null;
    },
    setUserCompanyInfo(state, action) {
      state.userCompanyInfo = action.payload;
    },
    setAllCompanyProjects(state, action) {
      state.companyProjects = action.payload;
    },
    setAllCollaborateProjects(state, action) {
      state.collaborateProject = action.payload;
    },
    requestApprovalProject(state, action){
      state.collaborateProjectApproval = action.payload;
    },
    setProjectInfo(state, action) {
      state.project = action.payload;
    },
    companyEmployeeAction(state, action) {
      state.companyEmployee = null
      state.companyEmployee = action.payload;
    },
    fetchCompanyInfoSuccess(state, action) {
      state.companyInfo = action.payload;
    },
    setCategories(state, action) {
      state.categories = action.payload;
    },
  },
});

export const { setCompanyInfo, clearCompanyInfo, requestApprovalProject,setAllCompanyInfo,setProjectInfo, companyEmployeeAction,setAllCompanyProjects, fetchCompanyInfoSuccess,setUserCompanyInfo, userCompanyInfo, setfloorplanData, setFloorPlanDetails,setAllProjects, setCategories,setCategoriesCustome,setAllCollaborateProjects } = projectSlice.actions;

// export const userCompany = (adminId) => {
//   return (dispatch) => {
//     companies
//       .get()
//       .then((querySnapshot) => {
//         const companies = querySnapshot.docs
//           .map((doc) => doc.data())
//           .filter((company) => company.companyInfo.admin.some((admin) => admin.adminUid === adminId));
//         dispatch(setUserCompanyInfo(companies[0]));
//       })
//       .catch((error) => {
//         console.log('Error getting documents:', error);
//       });
//   };
// };

export const collaborateProjectUpdate = (project, companyId, action) => {
  return (dispatch) => {
    dispatch(setLoading({
      type: 'company_write',
      isLoad: true
    }));
     console.log('project', project)
    const projectData = { ...project }; // Create a shallow copy of the original object

    // Find the index of the collaboration entry with the matching companyId
    const collaborationIndex = projectData.collaboration.findIndex(
      (collaboration) => collaboration.companyId === companyId
    );

    // If the collaboration entry is found
    if (collaborationIndex !== -1) {
      if (action === "accept") {
        // Update the acceptStatus to true
        const updatedCollaboration = [
          ...projectData.collaboration.slice(0, collaborationIndex),
          {
            ...projectData.collaboration[collaborationIndex],
            acceptStatus: true,
          },
          ...projectData.collaboration.slice(collaborationIndex + 1),
        ];
        projectData.collaboration = updatedCollaboration;
      } else if (action === "delete") {
        // Remove the collaboration entry from the array
        const updatedCollaboration = [
          ...projectData.collaboration.slice(0, collaborationIndex),
          ...projectData.collaboration.slice(collaborationIndex + 1),
        ];
        projectData.collaboration = updatedCollaboration;
      }
    }
    delete projectData.accessTab;
    projects
    .doc(projectData.projectId)
    .update(projectData) 
    .then(() => {
      dispatch(notificationTostaAction({
        message:action ==='delete'? "Collaboration request declined"  :'Collaboration request accept',
        type: "Project Info",
        status: action ==='delete' ? "error" : 'success'
      }));
      
      dispatch(comapnyProjects(projectData.companyId))
    })
    .then(() => {
      dispatch(setLoading({
        type: 'company_update',
        isLoad: false
      }));     
    })
    .catch((error) => {
      console.error('Error writing Company information data to Firestore: ', error);
    });
    // Now you can dispatch the updated projectData or perform other actions with it.
  };
};

export const allProjects = () => {

  return (dispatch) => {
    projects
      .get()
      .then((querySnapshot) => {
        
        const allProjects = querySnapshot.docs.map((doc) => doc.data())
        dispatch(setAllProjects(allProjects));
        console.log('all project', allProjects)
     
      })
      .catch((error) => {
        console.log('Error getting documents:', error);
      });
  };
};
export const comapnyProjects = (id) => {

  return (dispatch) => {
    var collaborateProject= []
    var requestApproval=[]
    projects
      .get()
      .then((querySnapshot) => {
        
        const allCompanies = querySnapshot.docs.map((doc) => doc.data()).filter((project) => project.companyId === id);
        dispatch(setAllCompanyProjects(allCompanies));
       querySnapshot.docs.map((doc) => {
        if( doc.data().collaboration){
         doc.data().collaboration.map((item)=>{
          if(item.companyId ===id){
            collaborateProject.push({
              ...doc.data(),
              status:item.acceptStatus,
              accessTab:item.accessTab,
              categories:item.accessTab
            })
            requestApproval.push(
            {
                ...doc.data(),
                status:item.acceptStatus,
                accessTab:item.accessTab,
               
              }
            )
          }
         
         })
        }
       }
       )
       console.log(collaborateProject, 'collaborateProject')
       dispatch(setAllCollaborateProjects(collaborateProject))
       dispatch(requestApprovalProject(requestApproval))
     
      })
      .catch((error) => {
        console.log('Error getting documents:', error);
      });
  };
};



export const setProjectCategoryCutome = (projectCategory) => {
  return (dispatch) => {
    dispatch(setCategoriesCustome(projectCategory))
  };
};


export const fetchProjectInfo = (projectId) => {
  return async (dispatch) => {
    try {
   
   
      const doc = await projects.doc(projectId).get();
      if (doc.exists) {
        const porjectInfo = doc.data();
        dispatch(setProjectInfo(porjectInfo));
       
      }
    } catch (error) {
      console.error('Error fetching Company information data from Firestore: ', error);
    }
  };
};

export const fetchFloorPlanInfo = (projectId) => {
  return async (dispatch) => {
    try {
      const floorplanData = [];
      const querySnapshot = await floorpans.doc(projectId).collection('floorpan').get();
      querySnapshot.forEach((doc) => {
        floorplanData.push(doc.data());
        // You can dispatch or process the data here as needed
      });
      dispatch(setfloorplanData(floorplanData));
    } catch (error) {
      console.error("Error getting documents:", error);
    }
  };
};

export const fetchFloorPlanDetailsInfo = (projectId, floorplanId) => {
  return async (dispatch) => {
    try {
      const doc = await floorpans.doc(projectId).collection('floorpan').doc(floorplanId).get();
      if (doc.exists) {
        const details = doc.data();
        dispatch(setFloorPlanDetails(details));
       
      }
      else {
        console.log('data not found')
      }
    } catch (error) {
      console.error("Error getting documents:", error);
    }
  };
};


export const updatefllorplanInfo = (projectInfo) => {
  return (dispatch) => {
    dispatch(setLoading({
      type: 'company_write',
      isLoad: true
    }));


    const floorpanCollectionRef = floorpans.doc(projectInfo.projectId).collection("floorpan");

    floorpanCollectionRef.add(projectInfo)
      .then((docRef) => {
        console.log("Document successfully added with ID: ", docRef.id);
    
        const floorplanId = docRef.id;
        docRef.update({ floorplanId })
          .then(() => {
            dispatch(notificationTostaAction({
              message: "New Floorplan Update",
              type: "floorplan Info",
              status: "success"
            }));
          })
          .catch((error) => {
            console.error("Error updating floorplanId field: ", error);
          });
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  };
}

export const updatedCategoriesAction = (projectCat, projectId) => {
  return (dispatch) => {
    dispatch(setLoading({
      type: 'company_write',
      isLoad: true
    }));


    projects
      .doc(projectId)
      .update({categories:projectCat}) 
      .then(() => {
        dispatch(notificationTostaAction({
          message: "Project categories update",
          type: "Project Info",
          status: "success"
        }));
        dispatch(fetchProjectInfo(projectId))
        
      })
      .then(() => {
        dispatch(setLoading({
          type: 'company_update',
          isLoad: false
        }));     
      })
      .catch((error) => {
        console.error('Error writing Company information data to Firestore: ', error);
      });
  };
};
export const updateProjectInfo = (projectInfo) => {
  return (dispatch) => {
    dispatch(setLoading({
      type: 'company_write',
      isLoad: true
    }));

    // Extracting companyName from companyInfo

    projects
      .doc(projectInfo.projectId)
      .update(projectInfo) // Update only the companyInfo field
      .then(() => {
        dispatch(notificationTostaAction({
          message: "Project Info Update",
          type: "Project Info",
          status: "success"
        }));
        dispatch(fetchProjectInfo(projectInfo.projectId))
        dispatch(comapnyProjects(projectInfo.companyId))
        // dispatch(fetchCompanyInfo(CompanyInfoData.uid));
      })
      .then(() => {
        dispatch(setLoading({
          type: 'company_update',
          isLoad: false
        }));     
      })
      .catch((error) => {
        console.error('Error writing Company information data to Firestore: ', error);
      });
  };
};

export const updateProjectInfoForColaboration = (projectInfo, data) => {
  return (dispatch) => {
    dispatch(setLoading({
      type: 'company_write',
      isLoad: true
    }));

    // Extracting companyName from companyInfo
    projects
      .doc(projectInfo.projectId)
      .update(projectInfo) // Update only the companyInfo field
      .then(() => {
        dispatch(notificationTostaAction({
          message: "Project Info Update",
          type: "Project Info",
          status: "success"
        }));
        const sendEmailData ={
          fromEmail:'sohag02.c@Signalflow.com',
          toEmail:'sohagchowdhury321@gmail.com',
          subject:data.subject,
          description:data.description + 'url:' + `http://localhost:3000/company/${projectInfo.companyId}/project/collaborate/${projectInfo.projectId}`
        }
        dispatch(sendEmail(sendEmailData))
        console.log(sendEmailData, 'HEELO NEXT ON WIIL BE CALL')

        dispatch(fetchProjectInfo(projectInfo.projectId))
        dispatch(comapnyProjects(projectInfo.companyId))
        
        // dispatch(fetchCompanyInfo(CompanyInfoData.uid));
      })
      .then(() => {
        dispatch(setLoading({
          type: 'company_update',
          isLoad: false
        }));     
      })
      .catch((error) => {
        console.error('Error writing Company information data to Firestore: ', error);
      });
  };
};

export const updateCoverPhoto = (companyId, projectId ,coverPhoto) => {
  return (dispatch) => {
    dispatch(setLoading({
      type: 'company_write',
      isLoad: true
    }));

    // Extracting companyName from companyInfo

    projects
      .doc(projectId)
      .update({coverPhoto:coverPhoto}) // Update only the companyInfo field
      .then(() => {
        dispatch(fetchProjectInfo(projectId))
        dispatch(comapnyProjects(companyId))
        dispatch(notificationTostaAction({
          message: "Project Info Update",
          type: "Project Info",
          status: "success"
        }));
       
      })
      .then(() => {
        dispatch(setLoading({
          type: 'company_update',
          isLoad: false
        }));     
      })
      .catch((error) => {
        console.error('Error writing Company information data to Firestore: ', error);
      });
  };
};
export const categoriesUpdate = (categoriesInfo) => {
  console.log(categoriesInfo)
  return (dispatch) => {
    dispatch(setLoading({
      type: 'company_write',
      isLoad: true
    }));

    // Extracting companyName from companyInfo

    categories
      .doc('pXuRmVbmcuIQFqMnwp8c')
      .set({categories:categoriesInfo}) // Update only the companyInfo field
      .then(() => {
        // dispatch(fetchProjectInfo(projectId))
        // dispatch(comapnyProjects(companyId))
        dispatch(notificationTostaAction({
          message: " categories Update",
          type: "Project Info",
          status: "success"
        }));
       
      })
      .then(() => {
        dispatch(setLoading({
          type: 'company_update',
          isLoad: false
        }));     
      })
      .catch((error) => {
        console.error('Error writing Company information data to Firestore: ', error);
      });
  };
};
export const AllCategories = () => {
  return (dispatch) => {
    categories
      .get()
      .then((querySnapshot) => {
        const list = querySnapshot.docs.map((doc) => doc.data());
        console.log(list, 'allCompanies')
        dispatch(setCategories(list));
      })
      .catch((error) => {
        console.log('Error getting documents:', error);
      });
  };
};

export const writeProjectInfo = (projectInfo,id) => {
  return (dispatch) => {
    dispatch(setLoading({
      type:'company_write',
      isLoad:true
    }))
    const newDocRef =projects.doc();
    const uniqueId = newDocRef.id;
    projects
      .doc(uniqueId)
      .set({
        ...projectInfo,
        projectId:uniqueId,
        companyId:id

      })
      .then(() => {
        dispatch(notificationTostaAction({
          message:"Project Data Insert",
          type:"Sign Up",
          status:"success"
        }))
        // dispatch(fetchCompanyInfo(CompanyInfoData.uid));
      }).then(() => {
        dispatch(setLoading({
          type:'company_write',
          isLoad:false
        }))
        const database = getDatabase();
        const notificationRef = ref(database, `systemAdmin/notification/${Date.now()}`);
        return set(notificationRef, {
          message: "New Project Update",
          type: "Project Update",
          companyId:id,
          status: "success",
          timestamp:Date.now(),
          isSeen:false,
          isAction:true,  
        }).then(()=>{
        });
      })
      .catch((error) => {
        console.error('Error writing Project information data to Firestore: ', error);
      });
  };
};



export default projectSlice.reducer;