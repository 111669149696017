import Profile from './porfile/Profile';

function ProfileView(props) {

  const { formData, user, adminRole } = props;

  return (
    <div className="ProfileView">
      
      { !formData &&  <Profile formData ={formData} userInfo={user} adminRole= {adminRole} />}
        <Profile formData ={formData} /> 
                
     
    </div>
  );
}

export default ProfileView;
