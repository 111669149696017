import { combineReducers } from 'redux';
import authentication from './slices/auth/auth.js';
import fileUpload from "./slices/common/fileUpload.js";
import modalTheme from './slices/common/modalTheme.js';
import mailchimpSlice from './slices/common/mailchimpSlice.js';
import companySlice from './slices/company/company.js';
import projectSlice from './slices/project/projects.js';
import userSlice from './slices/user/user.js';
import productSlice from './slices/products/product.js'

const rootReducer = combineReducers({
  modalTheme,
  authentication,
  fileUpload, 
  companySlice,
  userInfo: userSlice,
  projectSlice:projectSlice,
  mailchimpSlice:mailchimpSlice,
  products:productSlice
});

export default rootReducer;