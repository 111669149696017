import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { collaborateModalAction } from "../../redux/slices/common/modalTheme";
import { fetchAllUserInfo } from "../../redux/slices/user/user";
import { useEffect } from "react";
import {allCompany }  from '../../redux/slices/company/company'
import { updateProjectInfoForColaboration} from '../../redux/slices/project/projects'
import {notificationTostaAction} from '../../redux/slices/common/modalTheme'

import {
  faAdd,
  faEdit,
  faHandshake,
  faList12,
  faMapMarker,
  faSearch,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";

export default function Collaborate() {
  const [formData, setFormData] = useState({
   subject:'',
   description:''
  })
  const dispatch = useDispatch();
  const { collaborateModal } = useSelector((state) => state.modalTheme);

  const [selectedOption, setSelectedOption] = useState([]);

  useEffect(()=>{
    dispatch(allCompany())
  },[])
  const { allCompanyInfo } = useSelector((state) => state.companySlice);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const { project } = useSelector((state) => state.projectSlice);
  const [user, setUser] = useState(null);
  const handleChange = (e) => {
    setSearchTerm(e.target.value);

    if (e.target.value.length >= 3 && allCompanyInfo) {
      const results = allCompanyInfo
        .filter(
          (item) =>
            item.companyInfo
            .companyName
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            item.companyInfo.companyEmail
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) 
        )
        .map((item) => item);
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };
  const handleChangeDropdown = (e) => {
    const selectedCategoryId = e.target.value;
    const selectedCategory = project.categories.find(
      (option) => option.categoryId === selectedCategoryId
    );
    const categoryExists = selectedOption.some(
      (item) => item.categoryId === selectedCategoryId
    );
    if (categoryExists) {
      dispatch(notificationTostaAction({
        message:'This category already exists in the selected options.',
        status:'error'
      }))
      return;
    }
    setSelectedOption((prevSelectedOption) => [
      ...prevSelectedOption,
      selectedCategory,
    ]);
  };
  const cancelButtonRef = useRef(null);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    
  }
  const inviationHandelar = () => {
    const data = {
      subject: formData.subject,
      description: formData.description,
      accessTab: selectedOption,
      companyId: user.companyId,
      companyInfo: user.companyInfo,
      email: user.companyInfo.companyEmail,
      acceptStatus: false,
    };
  
    const updatedProjectInfo = Object.assign({}, project);
  
    if (!updatedProjectInfo.hasOwnProperty('collaboration')) {
      updatedProjectInfo.collaboration = [];
    } else {
      updatedProjectInfo.collaboration = [...updatedProjectInfo.collaboration];
    }
  
    // Check if the same companyId already exists in collaboration
    const existingCompany = updatedProjectInfo.collaboration.find(
      (collaborationItem) => collaborationItem.companyId === data.companyId
    );
  
    if (existingCompany) {
      dispatch(notificationTostaAction({
        message:'Same company already invited for collaboration on this project',
        status:'warning'
      }))
      return; // Stop further execution
    }
  
    updatedProjectInfo.collaboration.push(data);
    dispatch(updateProjectInfoForColaboration(updatedProjectInfo, data));
  };
  return (
    <Transition.Root
      show={collaborateModal}
      onClick={() => dispatch(collaborateModalAction(false))}
      as={Fragment}
    >
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => {}}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 dark:bg-gray__800 dark:bg-opacity-75  bg-opacity-60 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray__900 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:w-full sm:max-w-lg sm:p-6">
                <div className="mx-auto flex-shrink-0   rounded-full text-gray__900 sm:mx-0 sm:h-24 mx-2 w-24 sm:w-24">
                  <img className="mx-2" src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/360/external-handshake-achievements-flaticons-lineal-color-flat-icons-2.png"></img>
                  
                </div>
                <div className="mt-3 text-start nx-1 sm:ml-4 sm:mt-0 text-left">
                
                  {user && user.companyInfo && user.companyInfo.photoURL && (
                    <>
                      <Dialog.Title
                    as="h3"
                    className="tlock text-start text-lg  text-primary font-medium leading-6 text-gray-900"
                  >
                    Collaborate with
                  </Dialog.Title>
                    <div className="flex flex-col gap-10 pt-4 flex-row">
                      <img
                        className=" w-14 h-12 flex-none rounded object-cover"
                        src={user.companyInfo.photoURL}
                        alt=""
                      />
                      <div className="max-w-xl mb-5 flex-auto">
                        <h3 className="text-lg text-start text-sm font-semibold  tracking-tight text-gray__900 dark:text-gray__200">
                          {user.companyInfo.companyName} 
                        </h3>
                        <p className="text-base text-start  text-sm text-gray__600 dark:text-gray__300">
                          {user.companyInfo.companyEmail}
                        </p>
                      </div>
                    </div>
                    <p className="text-gray__200 text-start text-xs leading-4 font-roboto">{user.companyInfo.about}</p>
                    </>
                  )}
                  {selectedOption.length > 0 && (
                    <h3 className="text-lg text-start mt-1 mx-0 mb-2 text-sm font-semibold  tracking-tight text-gray__900 dark:text-gray__200">
                    Selected tab
                   </h3>
                  )}
                   
                  {selectedOption &&
                    selectedOption.map((item, index) => (
                      <button
                        className="bg-primary m-1 py-1 text-gray__200 text-start text-sm px-2 rounded"
                        key={index + "l"}
                      >
                        {item.categoryName}
                      </button>
                    ))}
                  {!user && (
                    <div className=" mt-5">
                      <div
                        className="relative w-full w-[100%]   rounded shadow focus:outline-none focus:ring-1 bg-gray__200 dark:bg-gray__600 px-4 py-2"
                        style={{ minHeight: "40px" }}
                      >
                        <FontAwesomeIcon
                          icon={faSearch}
                          className="absolute text-gray-400 dark:text-white"
                          style={{
                            top: "50%",
                            left: "10px",
                            transform: "translateY(-50%)",
                          }}
                        />
                        <input
                          type="text"
                          placeholder="Search with Company Name or or Company Email"
                          value={searchTerm}
                          onChange={handleChange}
                          className="w-full bg-transparent border-none dark:text-gray__200 focus:ring-0 focus:outline-none pl-10"
                        />
                      </div>

                      <ul className="mt-1  mt-2 overflow-y-auto">
                        {searchResults.map((item, index) => (
                          <li
                            key={index}
                            className="cursor-pointer py-1"
                            onClick={() => setUser(item)}
                          >
                            <div className="flex items-center">
                              <img
                                src={item.companyInfo.photoURL}
                                alt="Profile"
                                className="w-6 h-6 rounded-full mr-4"
                              />
                              <div>
                                <p className="text-sm dark:text-ioty__white text-ioty__black roboto">{item.companyInfo.companyName
}</p>
                                <p className="text-sm dark:text-ioty__white text-ioty__black roboto">
                                  {item.companyInfo.companyEmail}
                                </p>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <div>
                    <div className="mt-3">
                      {project && project.categories && (
                        <>
                          <label
                            htmlFor="photo"
                            className="block dark:text-gray__200 mb-2 text-sm font-medium leading-6 text-gray__900' "
                          >
                            Select a category
                          </label>
                          <select
                            id="categoryDropdown"
                            className="w-full bg-gray__200 dark:bg-gray__600 h-9 rounded border-none dark:text-gray__200 focus:ring-0 focus:outline-none pl-10"
                            value={selectedOption}
                            onChange={(e) => handleChangeDropdown(e)}
                          >
                            <option className="w-full mt-2 bg-transparent border-none dark:text-gray__200 focus:ring-0 focus:outline-none pl-10">
                              Select an option
                            </option>
                            {project.categories.map((option) => (
                              <option
                                className="w-full mt-2 bg-transparent border-none dark:text-gray__200 focus:ring-0 focus:outline-none pl-10"
                                key={option.categoryId}
                                value={option.categoryId}
                              >
                                {option.categoryName}
                              </option>
                            ))}
                          </select>
                        </>
                      )}
                    </div>
                    <div className='col-span-full md:mt-4 sm:col-span-2'>
                 <label htmlFor='phoneNumber' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                   Subject
                 </label>
                 <input
                   type='text'
                   name='subject'
                   id='subject'
                   autoComplete='tel'
                   value={formData.subject}
                   onChange={handleInputChange}
                   className='block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6'
                 />
               </div>
               <div className="col-span-full">
              <label htmlFor="description" className="block text-sm dark:text-ioty__white font-medium leading-6 text-gray-900">
                Description
              </label>
              <div className="mt-2">
                <textarea
                  id="description"
                  name="description"
                  type='text'                
                  defaultValue={formData.description}
                  onChange={handleInputChange}
                  rows={3}
                  className="block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6"
                 
                />
              </div>
            </div>
                  </div>
                </div>

              

                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                    type="button"
                    className="mt-3 mx-2 px-4 inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-gray__200 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={inviationHandelar}
                  >
                    Invite 
                  </button>
                  <button
                   onClick={() => dispatch(collaborateModalAction(false))}
                   ref={cancelButtonRef}
                    type="button"
                    className="mt-3 mx-2 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                   
                  >
                    Cancel
                  </button>

               
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
