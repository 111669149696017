
import React, { Component } from 'react';
import Chart from 'react-apexcharts';

class Chartwo extends Component {
    constructor(props) {
        super(props);
  
        this.state = {
        
          series: [{
            name: 'series1',
            data: [31, 40, 28, 51, 42, 109, 100]
          }, {
            name: 'series2',
            data: [11, 32, 45, 32, 34, 52, 41]
          }],
          options: {
            chart: {
              height: 350,
              type: 'area',
              toolbar: {
                show: true, // Set this to true to show the toolbar
                tools: {
                  download: false, // Set this to false to disable the download button
                },
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            xaxis: {
              type: 'datetime',
              categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
            },
            tooltip: {
              x: {
                format: 'dd/MM/yy HH:mm'
              },
            },
          },
        
        
        };
      }
  
    

  render() {

    return (
      <div className="donut">
        <Chart options={this.state.options} series={this.state.series} type="area" height={295} />
      </div>
    );
  }
}

export default Chartwo;

