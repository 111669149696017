import "apexcharts/dist/apexcharts.css";
import React from "react";
import ChartOne from "../temp/ChartOne";
import ChartThree from "../temp/ChartThree";
import Chartwo from "../temp/ChartTwo";
function Home() {
  return (
    <div className="p-[30px] mt-5">
      <div className="mb-10 w-full">
        <div className="items-center justify-between border-b  border-stroke dark:border-gray__700 md:flex">
          <div className="mb-6 w-full">
            <h2 className="mb-2 text-2xl font-semibold text-black dark:text-gray__200">
              Sales Analytics
            </h2>
            <p className="text-sm dark:text-gray__200 font-medium text-body-color">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
              ultrices lectus sem.
            </p>
          </div>
          <div className="mb-6">
            <button className="inline-flex items-center justify-center whitespace-nowrap rounded bg-primary py-[10px] px-5 text-sm font-medium text-white hover:bg-opacity-90">
              Add New Item
              <span className="pl-2">
                <svg
                  width={18}
                  height={18}
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.99961 2.39961C5.35453 2.39961 2.39961 5.35453 2.39961 8.99961C2.39961 12.6447 5.35453 15.5996 8.99961 15.5996C12.6447 15.5996 15.5996 12.6447 15.5996 8.99961C15.5996 5.35453 12.6447 2.39961 8.99961 2.39961ZM0.599609 8.99961C0.599609 4.36042 4.36042 0.599609 8.99961 0.599609C13.6388 0.599609 17.3996 4.36042 17.3996 8.99961C17.3996 13.6388 13.6388 17.3996 8.99961 17.3996C4.36042 17.3996 0.599609 13.6388 0.599609 8.99961Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.99961 5.09961C9.49667 5.09961 9.89961 5.50255 9.89961 5.99961V11.9996C9.89961 12.4967 9.49667 12.8996 8.99961 12.8996C8.50255 12.8996 8.09961 12.4967 8.09961 11.9996V5.99961C8.09961 5.50255 8.50255 5.09961 8.99961 5.09961Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.09961 8.99961C5.09961 8.50255 5.50255 8.09961 5.99961 8.09961H11.9996C12.4967 8.09961 12.8996 8.50255 12.8996 8.99961C12.8996 9.49667 12.4967 9.89961 11.9996 9.89961H5.99961C5.50255 9.89961 5.09961 9.49667 5.09961 8.99961Z"
                    fill="white"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4 lg:w-1/2 xl:w-7/12 2xl:w-5/12">
            <div className="mb-8 w-full rounded-lg border  border-stroke dark:border-gray__700 bg-white dark:bg-gray__800 px-5 pt-[30px] pb-5 sm:px-[30px]">
              <div className="justify-between sm:flex">
                <div className="mb-4 sm:mb-0">
                  <h5 className="text-xl font-semibold text-black dark:text-gray__200">
                    Email Sent
                  </h5>
                  <p className="text-sm text-body-color">
                    Detailed data of your email inbox
                  </p>
                </div>
                <div>
                  <div className=" z-20 inline-block rounded bg-gray-2">
                    <select
                      name
                      id
                      className=" z-20 inline-flex appearance-none rounded border  border-stroke dark:border-gray__700 bg-transparent py-1 pl-3 pr-8 text-sm text-body-color outline-none"
                    >
                      <option value>Monthly</option>
                      <option value>Yearly</option>
                    </select>
                    <span className="absolute top-1/2 right-3 z-10 -translate-y-1/2">
                      <svg
                        width={10}
                        height={6}
                        viewBox="0 0 10 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.47072 1.08816C0.47072 1.02932 0.500141 0.955772 0.54427 0.911642C0.647241 0.808672 0.809051 0.808672 0.912022 0.896932L4.85431 4.60386C4.92785 4.67741 5.06025 4.67741 5.14851 4.60386L9.09079 0.896932C9.19376 0.793962 9.35557 0.808672 9.45854 0.911642C9.56151 1.01461 9.5468 1.17642 9.44383 1.27939L5.50155 4.98632C5.22206 5.23639 4.78076 5.23639 4.51598 4.98632L0.558981 1.27939C0.50014 1.22055 0.47072 1.16171 0.47072 1.08816Z"
                          fill="#637381"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M1.22659 0.546578L5.00141 4.09604L8.76422 0.557869C9.08459 0.244537 9.54201 0.329403 9.79139 0.578788C10.112 0.899434 10.0277 1.36122 9.77668 1.61224L9.76644 1.62248L5.81552 5.33722C5.36257 5.74249 4.6445 5.7544 4.19352 5.32924C4.19327 5.32901 4.19377 5.32948 4.19352 5.32924L0.225953 1.61241C0.102762 1.48922 -4.20186e-08 1.31674 -3.20269e-08 1.08816C-2.40601e-08 0.905899 0.0780105 0.712197 0.211421 0.578787C0.494701 0.295506 0.935574 0.297138 1.21836 0.539529L1.22659 0.546578ZM4.51598 4.98632C4.78076 5.23639 5.22206 5.23639 5.50155 4.98632L9.44383 1.27939C9.5468 1.17642 9.56151 1.01461 9.45854 0.911642C9.35557 0.808672 9.19376 0.793962 9.09079 0.896932L5.14851 4.60386C5.06025 4.67741 4.92785 4.67741 4.85431 4.60386L0.912022 0.896932C0.809051 0.808672 0.647241 0.808672 0.54427 0.911642C0.500141 0.955772 0.47072 1.02932 0.47072 1.08816C0.47072 1.16171 0.50014 1.22055 0.558981 1.27939L4.51598 4.98632Z"
                          fill="#637381"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <ChartOne />
              </div>
            </div>
          </div>
          <div className="w-full px-4 lg:w-1/2 xl:w-5/12 2xl:w-4/12">
            <div className="mb-8 w-full rounded-lg border  border-stroke dark:border-gray__700 bg-white dark:bg-gray__800 px-5 pt-[30px] pb-5 sm:px-[30px]">
              <div className="mb-4 justify-between sm:flex">
                <div className="mb-4 sm:mb-0">
                  <h5 className="text-xl font-semibold text-black dark:text-gray__200">
                    Sales Graph
                  </h5>
                  <p className="text-sm text-body-color">
                    your total sales data analytics
                  </p>
                </div>
                <div>
                  <div className=" z-20 inline-block">
                    <select
                      name
                      id
                      className=" z-20 inline-flex appearance-none rounded bg-transparent py-1 pl-3 pr-8 text-sm text-body-color outline-none"
                    >
                      <option value>This Week</option>
                      <option value>Last Week</option>
                    </select>
                    <span className="absolute top-1/2 right-3 z-10 -translate-y-1/2">
                      <svg
                        width={10}
                        height={6}
                        viewBox="0 0 10 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.47072 1.08816C0.47072 1.02932 0.500141 0.955772 0.54427 0.911642C0.647241 0.808672 0.809051 0.808672 0.912022 0.896932L4.85431 4.60386C4.92785 4.67741 5.06025 4.67741 5.14851 4.60386L9.09079 0.896932C9.19376 0.793962 9.35557 0.808672 9.45854 0.911642C9.56151 1.01461 9.5468 1.17642 9.44383 1.27939L5.50155 4.98632C5.22206 5.23639 4.78076 5.23639 4.51598 4.98632L0.558981 1.27939C0.50014 1.22055 0.47072 1.16171 0.47072 1.08816Z"
                          fill="#637381"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M1.22659 0.546578L5.00141 4.09604L8.76422 0.557869C9.08459 0.244537 9.54201 0.329403 9.79139 0.578788C10.112 0.899434 10.0277 1.36122 9.77668 1.61224L9.76644 1.62248L5.81552 5.33722C5.36257 5.74249 4.6445 5.7544 4.19352 5.32924C4.19327 5.32901 4.19377 5.32948 4.19352 5.32924L0.225953 1.61241C0.102762 1.48922 -4.20186e-08 1.31674 -3.20269e-08 1.08816C-2.40601e-08 0.905899 0.0780105 0.712197 0.211421 0.578787C0.494701 0.295506 0.935574 0.297138 1.21836 0.539529L1.22659 0.546578ZM4.51598 4.98632C4.78076 5.23639 5.22206 5.23639 5.50155 4.98632L9.44383 1.27939C9.5468 1.17642 9.56151 1.01461 9.45854 0.911642C9.35557 0.808672 9.19376 0.793962 9.09079 0.896932L5.14851 4.60386C5.06025 4.67741 4.92785 4.67741 4.85431 4.60386L0.912022 0.896932C0.809051 0.808672 0.647241 0.808672 0.54427 0.911642C0.500141 0.955772 0.47072 1.02932 0.47072 1.08816C0.47072 1.16171 0.50014 1.22055 0.558981 1.27939L4.51598 4.98632Z"
                          fill="#637381"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <ChartThree />
              </div>
            </div>
          </div>
          <div className="w-full px-4 2xl:w-3/12">
            <div className="-mx-4 flex flex-wrap">
              <div className="w-full px-4 md:w-1/2 lg:w-1/3 2xl:w-full">
                <div className=" mb-8 flex items-center rounded-[10px] bg-white dark:bg-gray__800 py-9 px-6 shadow-card sm:px-10 md:px-6 xl:px-10">
                  <div className="mr-4 flex h-[50px] w-full max-w-[50px] items-center justify-center rounded-full bg-primary text-white sm:mr-6 sm:h-[60px] sm:max-w-[60px] md:mr-4 md:h-[50px] md:max-w-[50px] xl:mr-6 xl:h-[60px] xl:max-w-[60px]">
                    <svg
                      viewBox="0 0 30 30"
                      className="h-6 w-6 sm:h-[30px] sm:w-[30px] md:h-6 md:w-6 xl:h-[30px] xl:w-[30px]"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15 0C15.8332 0 16.5086 0.685278 16.5086 1.53061V28.4694C16.5086 29.3147 15.8332 30 15 30C14.1668 30 13.4914 29.3147 13.4914 28.4694V1.53061C13.4914 0.685278 14.1668 0 15 0Z"
                        fill="currentColor"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.92909 6.60152C9.00419 5.51075 10.4623 4.89796 11.9828 4.89796H21.0345C21.8677 4.89796 22.5431 5.58324 22.5431 6.42857C22.5431 7.27391 21.8677 7.95918 21.0345 7.95918H11.9828C11.2626 7.95918 10.5719 8.24945 10.0626 8.76613C9.55334 9.28282 9.26724 9.98359 9.26724 10.7143C9.26724 11.445 9.55334 12.1458 10.0626 12.6624C10.5719 13.1791 11.2626 13.4694 11.9828 13.4694H18.0172C19.5377 13.4694 20.9958 14.0822 22.0709 15.173C23.146 16.2637 23.75 17.7431 23.75 19.2857C23.75 20.8283 23.146 22.3077 22.0709 23.3985C20.9958 24.4893 19.5377 25.102 18.0172 25.102H7.75862C6.92543 25.102 6.25 24.4168 6.25 23.5714C6.25 22.7261 6.92543 22.0408 7.75862 22.0408H18.0172C18.7374 22.0408 19.4282 21.7505 19.9374 21.2339C20.4467 20.7172 20.7328 20.0164 20.7328 19.2857C20.7328 18.555 20.4467 17.8542 19.9374 17.3376C19.4281 16.8209 18.7374 16.5306 18.0172 16.5306H11.9828C10.4623 16.5306 9.00419 15.9178 7.92909 14.827C6.85399 13.7363 6.25 12.2569 6.25 10.7143C6.25 9.1717 6.85399 7.69229 7.92909 6.60152Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <div>
                    <p className="text-2xl font-bold leading-none text-black dark:text-gray__200 sm:text-[28px] md:text-2xl xl:text-[28px]">
                      $4,350
                    </p>
                    <p className="text-sm text-body-color sm:text-base">
                      Earned this month
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full px-4 md:w-1/2 lg:w-1/3 2xl:w-full">
                <div className=" mb-8 flex items-center rounded-[10px] bg-white dark:bg-gray__800 py-9 px-6 shadow-card sm:px-10 md:px-6 xl:px-10">
                  <div className="mr-4 flex h-[50px] w-full max-w-[50px] items-center justify-center rounded-full bg-secondary text-white sm:mr-6 sm:h-[60px] sm:max-w-[60px] md:mr-4 md:h-[50px] md:max-w-[50px] xl:mr-6 xl:h-[60px] xl:max-w-[60px]">
                    <svg
                      viewBox="0 0 30 30"
                      className="h-6 w-6 sm:h-[30px] sm:w-[30px] md:h-6 md:w-6 xl:h-[30px] xl:w-[30px]"
                    >
                      <g clipPath="url(#clip0_2962_776)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M1.69465 19.1955C2.90281 17.9873 4.54144 17.3086 6.25005 17.3086H16.25C17.9587 17.3086 19.5973 17.9873 20.8054 19.1955C22.0136 20.4037 22.6924 22.0423 22.6924 23.7509V26.2509C22.6924 27.0475 22.0466 27.6932 21.25 27.6932C20.4535 27.6932 19.8077 27.0475 19.8077 26.2509V23.7509C19.8077 22.8073 19.4329 21.9024 18.7657 21.2352C18.0985 20.568 17.1936 20.1932 16.25 20.1932H6.25005C5.30649 20.1932 4.40158 20.568 3.73438 21.2352C3.06718 21.9024 2.69235 22.8073 2.69235 23.7509V26.2509C2.69235 27.0475 2.04661 27.6932 1.25005 27.6932C0.453482 27.6932 -0.192261 27.0475 -0.192261 26.2509V23.7509C-0.192261 22.0423 0.486481 20.4037 1.69465 19.1955Z"
                          fill="currentColor"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.25 5.19321C9.28519 5.19321 7.69235 6.78604 7.69235 8.7509C7.69235 10.7158 9.28519 12.3086 11.25 12.3086C13.2149 12.3086 14.8077 10.7158 14.8077 8.7509C14.8077 6.78604 13.2149 5.19321 11.25 5.19321ZM4.80774 8.7509C4.80774 5.19291 7.69206 2.30859 11.25 2.30859C14.808 2.30859 17.6924 5.19291 17.6924 8.7509C17.6924 12.3089 14.808 15.1932 11.25 15.1932C7.69206 15.1932 4.80774 12.3089 4.80774 8.7509Z"
                          fill="currentColor"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M23.6035 18.5528C23.8027 17.7815 24.5893 17.3177 25.3606 17.5169C26.7427 17.8737 27.9671 18.6795 28.8417 19.8076C29.7162 20.9357 30.1913 22.3224 30.1923 23.7498V26.2509C30.1923 27.0474 29.5466 27.6932 28.75 27.6932C27.9535 27.6932 27.3077 27.0474 27.3077 26.2509V23.7513C27.307 22.9632 27.0446 22.1977 26.5618 21.5749C26.0789 20.9519 25.4027 20.5069 24.6395 20.3099C23.8682 20.1107 23.4044 19.3241 23.6035 18.5528Z"
                          fill="currentColor"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M18.6028 3.55561C18.8004 2.78394 19.5861 2.31854 20.3578 2.51612C21.7435 2.87093 22.9718 3.67687 23.8489 4.80686C24.726 5.93686 25.2021 7.32664 25.2021 8.75711C25.2021 10.1876 24.726 11.5774 23.8489 12.7074C22.9718 13.8373 21.7435 14.6433 20.3578 14.9981C19.5861 15.1957 18.8004 14.7303 18.6028 13.9586C18.4052 13.1869 18.8706 12.4012 19.6423 12.2036C20.4076 12.0077 21.0858 11.5626 21.5702 10.9386C22.0546 10.3146 22.3175 9.54707 22.3175 8.75711C22.3175 7.96715 22.0546 7.19966 21.5702 6.57563C21.0858 5.9516 20.4076 5.50653 19.6423 5.31059C18.8706 5.11301 18.4052 4.32728 18.6028 3.55561Z"
                          fill="currentColor"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2962_776">
                          <rect width={30} height={30} fill="currentColor" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div>
                    <p className="text-2xl font-bold leading-none text-black dark:text-gray__200 sm:text-[28px] md:text-2xl xl:text-[28px]">
                      583
                    </p>
                    <p className="text-sm text-body-color sm:text-base">
                      New Clients
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full px-4 md:w-1/2 lg:w-1/3 2xl:w-full">
                <div className=" mb-8 flex items-center rounded-[10px] bg-white dark:bg-gray__800 py-9 px-6 shadow-card sm:px-10 md:px-6 xl:px-10">
                  <div className="mr-4 flex h-[50px] w-full max-w-[50px] items-center justify-center rounded-full bg-[#00C3F9] text-white sm:mr-6 sm:h-[60px] sm:max-w-[60px] md:mr-4 md:h-[50px] md:max-w-[50px] xl:mr-6 xl:h-[60px] xl:max-w-[60px]">
                    <svg
                      viewBox="0 0 30 30"
                      className="h-6 w-6 sm:h-[30px] sm:w-[30px] md:h-6 md:w-6 xl:h-[30px] xl:w-[30px]"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.6327 26.5815C11.7877 26.5815 11.9133 26.4559 11.9133 26.3009C11.9133 26.1459 11.7877 26.0203 11.6327 26.0203C11.4777 26.0203 11.3521 26.1459 11.3521 26.3009C11.3521 26.4559 11.4777 26.5815 11.6327 26.5815ZM9.10718 26.3009C9.10718 24.9061 10.2379 23.7754 11.6327 23.7754C13.0275 23.7754 14.1582 24.9061 14.1582 26.3009C14.1582 27.6957 13.0275 28.8264 11.6327 28.8264C10.2379 28.8264 9.10718 27.6957 9.10718 26.3009Z"
                        fill="currentColor"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M23.9796 26.5815C24.1346 26.5815 24.2602 26.4559 24.2602 26.3009C24.2602 26.1459 24.1346 26.0203 23.9796 26.0203C23.8246 26.0203 23.699 26.1459 23.699 26.3009C23.699 26.4559 23.8246 26.5815 23.9796 26.5815ZM21.4541 26.3009C21.4541 24.9061 22.5848 23.7754 23.9796 23.7754C25.3744 23.7754 26.5051 24.9061 26.5051 26.3009C26.5051 27.6957 25.3744 28.8264 23.9796 28.8264C22.5848 28.8264 21.4541 27.6957 21.4541 26.3009Z"
                        fill="currentColor"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1.25 3.85228C1.25 3.07739 1.87817 2.44922 2.65306 2.44922H7.14285C7.81159 2.44922 8.38738 2.92119 8.51863 3.57692L9.41621 8.06146H27.3469C27.7651 8.06146 28.1614 8.24797 28.428 8.57015C28.6945 8.89233 28.8035 9.31661 28.7251 9.72735L26.9272 19.1552C26.7604 19.995 26.3036 20.7493 25.6366 21.2862C24.9736 21.8199 24.145 22.1049 23.2942 22.0921H12.4078C11.557 22.1049 10.7284 21.8199 10.0654 21.2862C9.39888 20.7496 8.94219 19.9959 8.77515 19.1568C8.77504 19.1562 8.77494 19.1557 8.77484 19.1552L6.90146 9.79531C6.89286 9.75972 6.88561 9.72361 6.87979 9.68702L5.99279 5.25534H2.65306C1.87817 5.25534 1.25 4.62717 1.25 3.85228ZM9.97785 10.8676L11.5272 18.6085C11.5657 18.8023 11.6711 18.9764 11.825 19.1003C11.9789 19.2242 12.1715 19.29 12.369 19.2862L12.3959 19.2859H23.3061L23.333 19.2862C23.5305 19.29 23.7231 19.2242 23.877 19.1003C24.0298 18.9773 24.1349 18.8048 24.174 18.6126L25.651 10.8676H9.97785Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <div>
                    <p className="text-2xl font-bold leading-none text-black dark:text-gray__200 sm:text-[28px] md:text-2xl xl:text-[28px]">
                      1289
                    </p>
                    <p className="text-sm text-body-color sm:text-base">
                      New Sales
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4 2xl:w-5/12">
            <div className="mb-8 w-full rounded-lg border  border-stroke dark:border-gray__700 bg-white dark:bg-gray__800 px-5 pt-[30px] pb-5 sm:px-[30px] 2xl:mb-0">
              <div className="flex justify-between">
                <div>
                  <h3 className="text-xl font-bold leading-none text-black dark:text-gray__200 sm:text-[28px]">
                    $35,8K
                  </h3>
                  <p className="text-sm text-body-color sm:text-base">
                    Overall Revenue
                  </p>
                </div>
                <div>
                  <div className=" z-20 inline-block rounded bg-gray-2">
                    <select
                      name
                      id
                      className=" z-20 inline-flex appearance-none rounded border  border-stroke dark:border-gray__700 bg-transparent py-1 pl-3 pr-8 text-sm text-body-color outline-none"
                    >
                      <option value>Monthly</option>
                      <option value>Yearly</option>
                    </select>
                    <span className="absolute top-1/2 right-3 z-10 -translate-y-1/2">
                      <svg
                        width={10}
                        height={6}
                        viewBox="0 0 10 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.47072 1.08816C0.47072 1.02932 0.500141 0.955772 0.54427 0.911642C0.647241 0.808672 0.809051 0.808672 0.912022 0.896932L4.85431 4.60386C4.92785 4.67741 5.06025 4.67741 5.14851 4.60386L9.09079 0.896932C9.19376 0.793962 9.35557 0.808672 9.45854 0.911642C9.56151 1.01461 9.5468 1.17642 9.44383 1.27939L5.50155 4.98632C5.22206 5.23639 4.78076 5.23639 4.51598 4.98632L0.558981 1.27939C0.50014 1.22055 0.47072 1.16171 0.47072 1.08816Z"
                          fill="#637381"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M1.22659 0.546578L5.00141 4.09604L8.76422 0.557869C9.08459 0.244537 9.54201 0.329403 9.79139 0.578788C10.112 0.899434 10.0277 1.36122 9.77668 1.61224L9.76644 1.62248L5.81552 5.33722C5.36257 5.74249 4.6445 5.7544 4.19352 5.32924C4.19327 5.32901 4.19377 5.32948 4.19352 5.32924L0.225953 1.61241C0.102762 1.48922 -4.20186e-08 1.31674 -3.20269e-08 1.08816C-2.40601e-08 0.905899 0.0780105 0.712197 0.211421 0.578787C0.494701 0.295506 0.935574 0.297138 1.21836 0.539529L1.22659 0.546578ZM4.51598 4.98632C4.78076 5.23639 5.22206 5.23639 5.50155 4.98632L9.44383 1.27939C9.5468 1.17642 9.56151 1.01461 9.45854 0.911642C9.35557 0.808672 9.19376 0.793962 9.09079 0.896932L5.14851 4.60386C5.06025 4.67741 4.92785 4.67741 4.85431 4.60386L0.912022 0.896932C0.809051 0.808672 0.647241 0.808672 0.54427 0.911642C0.500141 0.955772 0.47072 1.02932 0.47072 1.08816C0.47072 1.16171 0.50014 1.22055 0.558981 1.27939L4.51598 4.98632Z"
                          fill="#637381"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <Chartwo />
              </div>
            </div>
          </div>
          <div className="w-full px-4 2xl:w-7/12">
            <div className="w-full rounded-lg border  border-stroke dark:border-gray__700 bg-white dark:bg-gray__800">
              <div className="max-w-full overflow-x-auto">
                <table className="w-full table-auto">
                  <thead>
                    <tr className="border-b  border-stroke dark:border-gray__700">
                      <th className="min-w-[300px] py-5 pl-8 pr-4">
                        <p className="text-left text-base font-medium text-black dark:text-gray__200">
                          Product Name
                        </p>
                      </th>
                      <th className="min-w-[90px] py-5 px-4">
                        <p className="text-left text-base font-medium text-black dark:text-gray__200">
                          Category
                        </p>
                      </th>
                      <th className="min-w-[90px] py-5 px-4">
                        <p className="text-left text-base font-medium text-black dark:text-gray__200">
                          Price
                        </p>
                      </th>
                      <th className="min-w-[90px] py-5 px-4">
                        <p className="text-left text-base font-medium text-black dark:text-gray__200">
                          Sold
                        </p>
                      </th>
                      <th className="min-w-[90px] py-5 pl-4 pr-8">
                        <p className="text-left text-base font-medium text-black dark:text-gray__200">
                          Profit
                        </p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-b  border-stroke dark:border-gray__700">
                      <td className="py-[18px] pl-6 pr-3">
                        <div className="flex items-center">
                          <div className="">
                            <input
                              type="checkbox"
                              name="tableCheckbox"
                              id="checkbox-2"
                              className="tableCheckbox sr-only"
                            />
                            <label
                              htmlFor="checkbox-2"
                              className="flex cursor-pointer items-center text-base text-body-color"
                            >
                              <span className="icon-box mr-5 flex h-5 w-5 items-center justify-center rounded-[3px] border-[.5px]  border-stroke dark:border-gray__700 bg-gray-2 text-white">
                                <svg
                                  width={14}
                                  height={14}
                                  viewBox="0 0 10 10"
                                  className="icon opacity-0"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M8.62796 2.20602C8.79068 2.36874 8.79068 2.63256 8.62796 2.79528L4.04463 7.37861C3.88191 7.54133 3.61809 7.54133 3.45537 7.37861L1.37204 5.29528C1.20932 5.13256 1.20932 4.86874 1.37204 4.70602C1.53476 4.5433 1.79858 4.5433 1.96129 4.70602L3.75 6.49473L8.03871 2.20602C8.20142 2.0433 8.46524 2.0433 8.62796 2.20602Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            </label>
                          </div>
                          <div className="flex items-center">
                            <img
                              src="./assets/images/tables/table-12/image-01.jpg"
                              alt="product"
                              className="mr-4 h-[50px] w-[60px] rounded object-cover object-center"
                            />
                            <p className="text-sm text-body-color">
                              Apple Watch Series 7
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="py-[18px] px-4">
                        <p className="text-sm text-body-color">Electronics</p>
                      </td>
                      <td className="py-[18px] px-4">
                        <p className="text-sm text-body-color">$269</p>
                      </td>
                      <td className="py-[18px] px-4">
                        <p className="text-sm text-body-color">22</p>
                      </td>
                      <td className="py-[18px] px-4">
                        <p className="text-sm text-body-color">$45</p>
                      </td>
                      <td className="py-[18px] pl-4 pr-6">
                        <div x-data="{openDropDown: false}" className="">
                          <button className="text-body-color">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12Z"
                                stroke="#637381"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12Z"
                                stroke="#637381"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12Z"
                                stroke="#637381"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                          <div
                            x-show="openDropDown"
                            className="absolute right-0 top-full z-40 w-[200px] space-y-1 rounded bg-white dark:bg-gray__800 p-2 shadow-card"
                          >
                            <button className="w-full rounded py-2 px-3 text-left text-sm text-body-color hover:bg-gray-2">
                              Edit
                            </button>
                            <button className="w-full rounded py-2 px-3 text-left text-sm text-body-color hover:bg-gray-2">
                              Delete
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b  border-stroke dark:border-gray__700">
                      <td className="py-[18px] pl-6 pr-3">
                        <div className="flex items-center">
                          <div className="">
                            <input
                              type="checkbox"
                              name="tableCheckbox"
                              id="checkbox-3"
                              className="tableCheckbox sr-only"
                            />
                            <label
                              htmlFor="checkbox-3"
                              className="flex cursor-pointer items-center text-base text-body-color"
                            >
                              <span className="icon-box mr-5 flex h-5 w-5 items-center justify-center rounded-[3px] border-[.5px]  border-stroke dark:border-gray__700 bg-gray-2 text-white">
                                <svg
                                  width={14}
                                  height={14}
                                  viewBox="0 0 10 10"
                                  className="icon opacity-0"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M8.62796 2.20602C8.79068 2.36874 8.79068 2.63256 8.62796 2.79528L4.04463 7.37861C3.88191 7.54133 3.61809 7.54133 3.45537 7.37861L1.37204 5.29528C1.20932 5.13256 1.20932 4.86874 1.37204 4.70602C1.53476 4.5433 1.79858 4.5433 1.96129 4.70602L3.75 6.49473L8.03871 2.20602C8.20142 2.0433 8.46524 2.0433 8.62796 2.20602Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            </label>
                          </div>
                          <div className="flex items-center">
                            <img
                              src="./assets/images/tables/table-12/image-02.jpg"
                              alt="product"
                              className="mr-4 h-[50px] w-[60px] rounded object-cover object-center"
                            />
                            <p className="text-sm text-body-color">
                              Macbook Pro M1
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="py-[18px] px-4">
                        <p className="text-sm text-body-color">Electronics</p>
                      </td>
                      <td className="py-[18px] px-4">
                        <p className="text-sm text-body-color">$546</p>
                      </td>
                      <td className="py-[18px] px-4">
                        <p className="text-sm text-body-color">34</p>
                      </td>
                      <td className="py-[18px] px-4">
                        <p className="text-sm text-body-color">$125</p>
                      </td>
                      <td className="py-[18px] pl-4 pr-6">
                        <div x-data="{openDropDown: false}" className="">
                          <button className="text-body-color">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12Z"
                                stroke="#637381"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12Z"
                                stroke="#637381"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12Z"
                                stroke="#637381"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                          <div
                            x-show="openDropDown"
                            className="absolute right-0 top-full z-40 w-[200px] space-y-1 rounded bg-white dark:bg-gray__800 p-2 shadow-card"
                          >
                            <button className="w-full rounded py-2 px-3 text-left text-sm text-body-color hover:bg-gray-2">
                              Edit
                            </button>
                            <button className="w-full rounded py-2 px-3 text-left text-sm text-body-color hover:bg-gray-2">
                              Delete
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b  border-stroke dark:border-gray__700">
                      <td className="py-[18px] pl-6 pr-3">
                        <div className="flex items-center">
                          <div className="">
                            <input
                              type="checkbox"
                              name="tableCheckbox"
                              id="checkbox-4"
                              className="tableCheckbox sr-only"
                            />
                            <label
                              htmlFor="checkbox-4"
                              className="flex cursor-pointer items-center text-base text-body-color"
                            >
                              <span className="icon-box mr-5 flex h-5 w-5 items-center justify-center rounded-[3px] border-[.5px]  border-stroke dark:border-gray__700 bg-gray-2 text-white">
                                <svg
                                  width={14}
                                  height={14}
                                  viewBox="0 0 10 10"
                                  className="icon opacity-0"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M8.62796 2.20602C8.79068 2.36874 8.79068 2.63256 8.62796 2.79528L4.04463 7.37861C3.88191 7.54133 3.61809 7.54133 3.45537 7.37861L1.37204 5.29528C1.20932 5.13256 1.20932 4.86874 1.37204 4.70602C1.53476 4.5433 1.79858 4.5433 1.96129 4.70602L3.75 6.49473L8.03871 2.20602C8.20142 2.0433 8.46524 2.0433 8.62796 2.20602Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            </label>
                          </div>
                          <div className="flex items-center">
                            <img
                              src="./assets/images/tables/table-12/image-03.jpg"
                              alt="product"
                              className="mr-4 h-[50px] w-[60px] rounded object-cover object-center"
                            />
                            <p className="text-sm text-body-color">
                              Dell Inspiron 15
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="py-[18px] px-4">
                        <p className="text-sm text-body-color">Electronics</p>
                      </td>
                      <td className="py-[18px] px-4">
                        <p className="text-sm text-body-color">$444</p>
                      </td>
                      <td className="py-[18px] px-4">
                        <p className="text-sm text-body-color">64</p>
                      </td>
                      <td className="py-[18px] px-4">
                        <p className="text-sm text-body-color">$247</p>
                      </td>
                      <td className="py-[18px] pl-4 pr-6">
                        <div x-data="{openDropDown: false}" className="">
                          <button className="text-body-color">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12Z"
                                stroke="#637381"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12Z"
                                stroke="#637381"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12Z"
                                stroke="#637381"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                          <div
                            x-show="openDropDown"
                            className="absolute right-0 top-full z-40 w-[200px] space-y-1 rounded bg-white dark:bg-gray__800 p-2 shadow-card"
                          >
                            <button className="w-full rounded py-2 px-3 text-left text-sm text-body-color hover:bg-gray-2">
                              Edit
                            </button>
                            <button className="w-full rounded py-2 px-3 text-left text-sm text-body-color hover:bg-gray-2">
                              Delete
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b  border-stroke dark:border-gray__700">
                      <td className="py-[18px] pl-6 pr-3">
                        <div className="flex items-center">
                          <div className="">
                            <input
                              type="checkbox"
                              name="tableCheckbox"
                              id="checkbox-5"
                              className="tableCheckbox sr-only"
                            />
                            <label
                              htmlFor="checkbox-5"
                              className="flex cursor-pointer items-center text-base text-body-color"
                            >
                              <span className="icon-box mr-5 flex h-5 w-5 items-center justify-center rounded-[3px] border-[.5px]  border-stroke dark:border-gray__700 bg-gray-2 text-white">
                                <svg
                                  width={14}
                                  height={14}
                                  viewBox="0 0 10 10"
                                  className="icon opacity-0"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M8.62796 2.20602C8.79068 2.36874 8.79068 2.63256 8.62796 2.79528L4.04463 7.37861C3.88191 7.54133 3.61809 7.54133 3.45537 7.37861L1.37204 5.29528C1.20932 5.13256 1.20932 4.86874 1.37204 4.70602C1.53476 4.5433 1.79858 4.5433 1.96129 4.70602L3.75 6.49473L8.03871 2.20602C8.20142 2.0433 8.46524 2.0433 8.62796 2.20602Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            </label>
                          </div>
                          <div className="flex items-center">
                            <img
                              src="./assets/images/tables/table-12/image-04.jpg"
                              alt="product"
                              className="mr-4 h-[50px] w-[60px] rounded object-cover object-center"
                            />
                            <p className="text-sm text-body-color">
                              HP Probook 450
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="py-[18px] px-4">
                        <p className="text-sm text-body-color">Electronics</p>
                      </td>
                      <td className="py-[18px] px-4">
                        <p className="text-sm text-body-color">$499</p>
                      </td>
                      <td className="py-[18px] px-4">
                        <p className="text-sm text-body-color">72</p>
                      </td>
                      <td className="py-[18px] px-4">
                        <p className="text-sm text-body-color">$103</p>
                      </td>
                      <td className="py-[18px] pl-4 pr-6">
                        <div x-data="{openDropDown: false}" className="">
                          <button className="text-body-color">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12Z"
                                stroke="#637381"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12Z"
                                stroke="#637381"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12Z"
                                stroke="#637381"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                          <div
                            x-show="openDropDown"
                            className="absolute right-0 top-full z-40 w-[200px] space-y-1 rounded bg-white dark:bg-gray__800 p-2 shadow-card"
                          >
                            <button className="w-full rounded py-2 px-3 text-left text-sm text-body-color hover:bg-gray-2">
                              Edit
                            </button>
                            <button className="w-full rounded py-2 px-3 text-left text-sm text-body-color hover:bg-gray-2">
                              Delete
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
