import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Transition } from '@headlessui/react';
import { EllipsisHorizontalIcon, EnvelopeIcon, PhoneIcon } from "@heroicons/react/20/solid";
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { fetchAllUserInfo } from "../../redux/slices/user/user";
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
export default function UserTable() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllUserInfo());
  }, []);
  const { allUserInfo } = useSelector((state) => state.userInfo);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  useEffect(() => {
    if (allUserInfo) {
      setSearchResults(allUserInfo);
    }
  }, [allUserInfo]);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);

    if (e.target.value.length >= 3 && allUserInfo) {
      const results = allUserInfo.filter(
        (item) =>
          item.userInfoData.firstName
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          item.userInfoData.lastName
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          item.userInfoData.email
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
      );
      setSearchResults(results);
    } else {
      setSearchResults(allUserInfo);
    }
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-8 mt-10 xl:grid-cols-12 ">
      <div className="mx-3 sm:mx-0 sm:col-start-2 sm:col-end-8 dark:bg-gary__700 dark:bg-gray__800 dark:bg-gray__900  rounded-xl  xl:col-start-2 xl:col-end-12 2xl:col-start-3 2xl:col-end-11">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <div
                className="relative w-full w-[90%] ml-[5%] md:w-[50%] md:ml-[25%] mt-10 rounded-full mt-3 shadow focus:outline-none focus:ring-1 bg-gray__200 dark:bg-gray__600 px-4 py-2"
                style={{ minHeight: "40px" }}
              >
                <FontAwesomeIcon
                  icon={faSearch}
                  className="absolute text-gray-400 dark:text-white"
                  style={{
                    top: "50%",
                    left: "10px",
                    transform: "translateY(-50%)",
                  }}
                />
                <input
                  type="text"
                  placeholder="Search with name or email"
                  value={searchTerm}
                  onChange={handleChange}
                  className="w-full bg-transparent border-none dark:text-gray__200 focus:ring-0 focus:outline-none pl-10"
                />
              </div>
              <p className="text-xs dark:text-white text-center mt-2 roboto">
                Pagination will be set in the future.
              </p>
            </div>
          </div>
          <div className="mt-8 flow-root">
            <>
              <ul
                role="list"
                className="grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-3 "
              >
                {searchResults &&
                  searchResults.map((person, index) => (
                    <li
                      key={index}
                      className="col-span-1 divide-y border border-gray__200 dark:border-gray__800  rounded bg-white dark:bg-gray__800 shadow"
                    >
                      <div className="flex w-full items-center justify-between space-x-4 p-6 text-gray__200 dark:text-gray__900">
                        
                      <img
                          className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"
                          src={person.userInfoData.photoURL}
                          alt=""
                        />
                        <div className="flex-1 truncate text-gray__200 dark:text-gray__900">
                          <div className="flex items-center space-x-3 text-gray__200 dark:text-gray__900">
                            <h3 className="truncate text-sm font-medium text-gray-900 text-gray__700 dark:text-gray__200">
                              {person.userInfoData.lastName}{" "}
                              {person.userInfoData.firstName}
                            </h3>
                            <span className="inline-flex flex-shrink-0 items-center  rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                              {person.isAdmin.isCompanyAdmin
                                ? "Company Admin"
                                : ""}
                              {person.isAdmin.userAdmin ? "User" : ""}
                              {person.isAdmin.isClientAdmin ? "Client" : ""}
                              {person.isAdmin.isEmployeeAdmin ? "Employee" : ""}
                              {person.isAdmin.isSystemAdmin
                                ? "System Admin"
                                : ""}
                            </span>
                          </div>
                          <p className="mt-1 truncate text-sm text-gray-500 text-gray__700 dark:text-gray__200">
                            {person.userInfoData.email}
                          </p>
                        </div>
                        <Menu as="div" className="relative ml-auto">
              <Menu.Button className="-m-2.5 block p-2.5 text-gray__400 dark:text-gray__400 hover:text-gray-500">
                <span className="sr-only">Open options</span>
                <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-gray__200 dark:bg-gray__700 py-2">
                  <Menu.Item>
                    {({ active }) => (
                    
                     <Link to={`/profile/${person.userInfoData.uid
                     }`}>
                       <button  className='p-2 text-start mx-2 w-full text-ioty__black dark:text-ioty__white'>View</button>
                     </Link>
                      
                    )}
                  </Menu.Item>
                  <Menu.Item>
                  {({ active }) => (
                    
                    <Link to={`/profile/edit/${person.userInfoData.uid
                    }`}>
                      <button  className='p-2 text-start mx-2 w-full text-ioty__black dark:text-ioty__white'>Edit</button>
                    </Link>
                     
                   )}
                  </Menu.Item>
                  <Menu.Item>
                  {({ active }) => (
                    
                      <button  className='p-2 text-start mx-2 w-full text-ioty__black dark:text-ioty__white'>Disable</button>
                   
                     
                   )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
            
                      </div>
                      <div className="text-gray__200 dark:text-gray__900">
                        <div className="-mt-px flex divide-x text-gray__200 dark:text-gray__900 ">
                          <div className="flex w-0 flex-1  dark:text-gray__200 ">
                            <a
                              href={`mailto:${person.userInfoData.email}`}
                              className="relative inline-flex w-0 dark:text-gray__200 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray__700"
                            >
                              <EnvelopeIcon
                                className="h-5 w-5 text-gray__700 dark:text-gray__200"
                                aria-hidden="true"
                              />
                              Email
                            </a>
                          </div>
                          <div className="-ml-px flex w-0 flex-1  text-gray__200 dark:text-gray__900">
                            <a
                              href={`tel:${person.userInfoData.phoneNumber}`}
                              className="relative inline-flex w-0 dark:text-gray__200 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray__700"
                            >
                              <PhoneIcon
                                className="h-5 w-5 text-gray__700 dark:text-gray__200"
                                aria-hidden="true"
                              />
                              Call
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </>
          </div>
        </div>
      </div>
    </div>
  );
}
