import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const mailchimpSlice = createSlice({
  name: 'mailchimp',
  initialState: {
    isSending: false,
    successMessage: null,
    errorMessage: null,
  },
  reducers: {
    sendEmailStart: (state) => {
      state.isSending = true;
      state.successMessage = null;
      state.errorMessage = null;
    },
    sendEmailSuccess: (state, action) => {
      state.isSending = false;
      state.successMessage = action.payload;
      state.errorMessage = null;
    },
    sendEmailFailure: (state, action) => {
      state.isSending = false;
      state.successMessage = null;
      state.errorMessage = action.payload;
    },
  },
});
const mandrillApiKey = "md-P7ECK3kq9HpqVlJ6WgHHWQ"; // Replace with your actual Mandrill API key

export const sendEmail = (emailData) => async (dispatch) => {
  dispatch(sendEmailStart());

  try {
    const response = await axios.post(
      'https://mandrillapp.com/api/1.0/messages/send.json',
      {
        key: mandrillApiKey,
        message: {
          from_email: emailData.from,
          to: [
            {
              email: emailData.to,
              type: 'to',
            },
          ],
          subject: emailData.subject,
          text: emailData.body,
        },
      }
    );

    if (response.data[0].status === 'sent') {
      dispatch(sendEmailSuccess('Email sent successfully!'));
    } else {
      dispatch(sendEmailFailure('An error occurred while sending the email. Please try again later.'));
    }
  } catch (error) {
    dispatch(sendEmailFailure(error.message || 'An error occurred while sending the email. Please try again later.'));
  }
};
export const { sendEmailStart, sendEmailSuccess, sendEmailFailure } = mailchimpSlice.actions;

export default mailchimpSlice.reducer;