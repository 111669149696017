import { createSlice } from '@reduxjs/toolkit';
import { getDatabase, ref, set } from "firebase/database";
import { companies, users } from '../../../firebase/collection/index';
import { companyEmployAdd, companySectionAction, notificationTostaAction, setLoading } from "../common/modalTheme";

const companySlice = createSlice({
  name: 'company',
  initialState: { companyInfo: null,companyEmployee:null, userCompanyInfo: null, allCompanyInfo:null }, 
  reducers: {
    setCompanyInfo(state, action) {
      state.companyInfo = action.payload;
    },
    clearCompanyInfo(state) {
      state.companyInfo = null;
    },
    setUserCompanyInfo(state, action) {
      state.userCompanyInfo = action.payload;
    },
    setAllCompanyInfo(state, action) {
      state.allCompanyInfo = action.payload;
    },
    companyEmployeeAction(state, action) {
      state.companyEmployee = null
      state.companyEmployee = action.payload;
    },
    fetchCompanyInfoSuccess(state, action) {
      state.companyInfo = action.payload;
    },
  },
});

export const { setCompanyInfo, clearCompanyInfo, setAllCompanyInfo, companyEmployeeAction, fetchCompanyInfoSuccess,setUserCompanyInfo, userCompanyInfo } = companySlice.actions;

export const userCompany = (adminId) => {
  return (dispatch) => {
    companies
      .get()
      .then((querySnapshot) => {
        const companies = querySnapshot.docs
          .map((doc) => doc.data())
          .filter((company) => company.companyInfo.admin.some((admin) => admin.adminUid === adminId));
        dispatch(setUserCompanyInfo(companies[0]));
      })
      .catch((error) => {
        console.log('Error getting documents:', error);
      });
  };
};

export const allCompany = () => {
  return (dispatch) => {
    companies
      .get()
      .then((querySnapshot) => {
        const allCompanies = querySnapshot.docs.map((doc) => doc.data());
        dispatch(setAllCompanyInfo(allCompanies));
      })
      .catch((error) => {
        console.log('Error getting documents:', error);
      });
  };
};
export const fetchCompanyInfo = (CompanyId) => {
  return async (dispatch) => {
    try {
   
      let companyEmployee = [];
      const doc = await companies.doc(CompanyId).get();
      if (doc.exists) {
        const CompanyInfoData = doc.data();
        dispatch(setUserCompanyInfo(CompanyInfoData));
        dispatch(fetchCompanyInfoSuccess(CompanyInfoData));
         
        for (const item of CompanyInfoData.companyInfo.admin) {
          const doc = await users.doc(item.adminUid).get();
          if (doc.exists) {
            const userInfoData = doc.data().userInfoData;
            companyEmployee.push(userInfoData);
          }
        }

        dispatch(companyEmployeeAction(companyEmployee));
      }
    } catch (error) {
      console.error('Error fetching Company information data from Firestore: ', error);
    }
  };
};
 export const  companyUserAction = (adminInfo) =>{
  
  return (dispatch) => {
    dispatch(setLoading({
      type: 'company_write',
      isLoad: true
    }));

    const { info, uid, companyId,  isDelete,
    } = adminInfo; // Extracting companyName from companyInfo
    companies
      .doc(companyId
        )
      .update({ companyInfo:info }) // Update only the companyInfo field
     .then(()=>{
        const database = getDatabase();
        const notificationRef = ref(database, `notification/${uid}/${Date.now()}`);
        return set(notificationRef, {
          message: isDelete ?  "You have been removed" : 'Your role has been changed',
          type: 'Company Role chanege',
          status: 'success' ,
          isAction:false,
          isSeen:false,
          congMrss: isDelete ? ' Sorry !!' : 'Congratulation',
          timestamp: Date.now(),
       
        })
      })
      .then(() => {
        dispatch(fetchCompanyInfo(companyId))
        dispatch(setLoading({
          type: 'user__role',
          isLoad: false
        }));     
      }).then(() => {
        dispatch(companyEmployAdd({type:false}))
        dispatch(companySectionAction(1))
        dispatch(notificationTostaAction({
          message:isDelete ? 'User delete': "User assign for new Role",
          type: "user role",
          status: isDelete? "error" : "success"
        }));
        // dispatch(fetchCompanyInfo(CompanyInfoData.uid));
      })
      .catch((error) => {
        console.error('Error writing Company information data to Firestore: ', error);
      });
  };
}
export const  updateCompanyAction = (companyInfo) =>{

    return (dispatch) => {
      dispatch(setLoading({
        type: 'company_write',
        isLoad: true
      }));
      const { info, id, pending } = companyInfo; // Extracting companyName from companyInfo
  
      companies
        .doc(id)
        .update({ companyInfo:info,
          isPending:pending
        }) 
        .then(() => {
          dispatch(notificationTostaAction({
            message: "Company action update",
            type: "company action",
            status: "success"
          }));
          // dispatch(fetchCompanyInfo(CompanyInfoData.uid));
        }).then(() => {
             info.admin.map((item) => {
                const database = getDatabase();
                const notificationRef = ref(database, `notification/${item.adminUid}/${Date.now()}`);
                return set(notificationRef, {
                  message: pending ? 'Your company Enrol request Declined' : ' Your company Enrol request approved!!',
                  type: 'Aproval company',
                  status:pending? 'reject' : 'success' ,
                  isAction:false,
                  isSeen:true,
                  congMrss: pending ? 'Sorry ' : ' Congratulations !!',
                  timestamp: Date.now(),
               
                })
              })
            }).then(()=>{
              dispatch(notificationTostaAction({
                message: "All employee notify",
                type: "company action",
                status: "success"
              }))
            })
        .then(() => {
          dispatch(setLoading({
            type: 'company_update',
            isLoad: false
          }));     
        })
        .catch((error) => {
          console.error('Error writing Company information data to Firestore: ', error);
        });
    };
   
}
export const updateCompanyInfo = (companyInfo) => {
  return (dispatch) => {
    dispatch(setLoading({
      type: 'company_write',
      isLoad: true
    }));

    const { info, id } = companyInfo; // Extracting companyName from companyInfo

    companies
      .doc(id)
      .update({ companyInfo:info }) // Update only the companyInfo field
      .then(() => {
        dispatch(notificationTostaAction({
          message: "Company Data Update",
          type: "Sign Up",
          status: "success"
        }));
        // dispatch(fetchCompanyInfo(CompanyInfoData.uid));
      })
      .then(() => {
        dispatch(setLoading({
          type: 'company_update',
          isLoad: false
        }));     
      })
      .catch((error) => {
        console.error('Error writing Company information data to Firestore: ', error);
      });
  };
};
export const writeCompanyInfo = (companyInfo) => {
  return (dispatch) => {
    dispatch(setLoading({
      type:'company_write',
      isLoad:true
    }))
    const newDocRef =companies.doc();
    const uniqueId = newDocRef.id;
    companies
      .doc(uniqueId)
      .set({
         companyInfo,
         isPending:true,
         companyId:uniqueId
      })
      .then(() => {
        dispatch(notificationTostaAction({
          message:"Company Data Update",
          type:"Sign Up",
          status:"success"
        }))
        // dispatch(fetchCompanyInfo(CompanyInfoData.uid));
      }).then(() => {
        dispatch(setLoading({
          type:'company_write',
          isLoad:false
        }))
        const database = getDatabase();
        const notificationRef = ref(database, `systemAdmin/notification/${Date.now()}`);
        return set(notificationRef, {
          message: "Reques awaiting for approval",
          type: "Compnay request",
          companyId:uniqueId,
          status: "success",
          timestamp:Date.now(),
          companyName:companyInfo.companyName,
          isSeen:false,
          isAction:true,  
        }).then(()=>{
        });
      })
      .catch((error) => {
        console.error('Error writing Company information data to Firestore: ', error);
      });
  };
};

export const  logOutremoveData =()=>{
  return (dispatch) =>{
    dispatch(setCompanyInfo(null))
  }
}

export default companySlice.reducer;