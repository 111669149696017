import { addDoc, collection, getFirestore } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';

export const uploadImageToStorage = async (file, folder) => {
  const timestamp = Math.floor(Date.now() / 1000);
  const storageRef = ref(getStorage(), folder + '/' + timestamp + file.name);
  const uploadTask = uploadBytesResumable(storageRef, file);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      'state_changed',
      null,
      (error) => {
        reject(error);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        resolve(downloadURL);
      }
    );
  });
};

export const createFirestoreURL = async (file, folder) => {
  try {
    const downloadURL = await uploadImageToStorage(file, folder);
    const db = getFirestore();
    const imagesCollectionRef = collection(db, 'images');

    const newImageDocRef = await addDoc(imagesCollectionRef, { url: downloadURL });
    const newImageId = newImageDocRef.id;
    return newImageId;
  } catch (error) {
    throw error;
  }
};
