import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { profileEditAction } from '../../redux/slices/common/modalTheme';
import { updateUserInfo, writeUserInfo } from '../../redux/slices/user/user';

import { Link } from 'react-router-dom';
import ProfileView from './ProfileView';
export default function UpdateForm({ adminRole}) { 
  const { user } = useSelector((state) => state.authentication); 
    // eslint-disable-next-line no-unused-vars
  const { isLoading } = useSelector((state) => state.fileUpload);
  const { profileEdit } = useSelector((state) => state.modalTheme);
  const {userInfo} = useSelector((state) => state.userInfo);
  const [profile, setProfile] =useState(false)
  const [companyValue, setComapnyValue] = useState(null);

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: '',
    isDark:false,
    title:'',
    about:'',
    city: '',
    zipCode: '',
    authProvider:null,
    state: '',
    photoURL: 'https://img.icons8.com/stickers/360/user-male-circle-skin-type-2.png',
    coverPhoto:'https://hooquest.com/wp-content/uploads/2021/11/facebook-cover-2.jpg'
  })
  useEffect (()=>{
    if( profileEdit.type === true)
    {
      setProfile(true)
    }
    else if(!userInfo ) {
      setProfile(true)
    }
    else {
      setProfile(false)
    }
  
  
  },[userInfo, profileEdit.type])
 
  useEffect(() => {
    if (userInfo && userInfo.userInfoData && userInfo.isAdmin) {
      const {
        firstName,
        lastName,
        photoURL,
        email,
        uid,
        city,
        phoneNumber,
        state,
        title,
        coverPhoto,
        zipCode,
        emailVerified,
        about,
        address,
        accountCreateTime,
      } = userInfo.userInfoData;
  
      setFormData((prevData) => ({
        firstName,
        lastName,
        photoURL,
        email,
        uid,
        phoneNumber,
        city,
        coverPhoto,
        title:title ? title: '',
        address,
        state,
        about,
        zipCode,
        emailVerified,
        accountCreateTime,
        isAdmin: userInfo.isAdmin,
      }));
    } else if (user) {
      
      setFormData((prevData) => ({
        ...prevData,
        firstName: user.name ? user.name.split(' ')[0] : '',
        lastName: user.name ? user.name.split(' ')[1] : '',
        photoURL: user.photoURL || prevData.photoURL,
        email: user.email || '',
        uid: user.uid,
        emailVerified: user.emailVerified,
        accountCreateTime: user.accountCreateTime ? user.accountCreateTime : Date.now() ,
      }));
    }
  }, [user, userInfo, userInfo?.userInfoData, userInfo?.isAdmin]);



 

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const { userCompanyInfo } = useSelector((state) => state.companySlice);
  useEffect(() => {
    if (
      userCompanyInfo &&
      userCompanyInfo.companyInfo &&
      userCompanyInfo.companyInfo.admin
    ) {
      userCompanyInfo.companyInfo.admin.map((item) => {
        if (item.adminUid === userInfo.userInfoData.uid) {
          setComapnyValue({
            name: userCompanyInfo.companyInfo.companyName,
            logo: userCompanyInfo.companyInfo.photoURL,
            title: item.title,
            companySatus: userCompanyInfo.isPending,
          });
        }
      });
    }
  }, [userCompanyInfo]);
  const updateUserData = () => {
    dispatch(updateUserInfo(formData)); 
    dispatch(profileEditAction({type:false}))
  };

  const saveUserData = () => {
    dispatch(writeUserInfo(formData)); 
    dispatch(profileEditAction({type:false}))
  };

  const isFormIncomplete = !formData.lastName || !formData.firstName || !formData.email;
  return (
    <> { !profile && <ProfileView formData={formData} user={user} adminRole={adminRole} />}
    <div className='grid grid-cols-1 sm:grid-cols-8 xl:grid-cols-12'>
      
        
      <div className='mx-3 sm:mx-0 sm:col-start-2 sm:col-end-8 xl:col-start-3 xl:col-end-11 2xl:col-start-4 2xl:col-end-10'>
       
        {profile && (
           <div className='  rounded-b-lg border  border-stroke dark:border-gray__800 bg-white dark:bg-gray__800 pb-5 2xl:mb-0'>
           <div className=' px-1 sm:p-5 lg:p-8  md:pb-2'>
             <div className='mt-2 sm:mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6'>
               <div className='col-span-full'>
                 <label htmlFor='photo' className='block dark:text-gray__200 text-md xl:text-2xl xl:pb-4 font-medium  text-gray__900'>
                    Update Your Information
                 </label>
                
               </div>
               <div className='col-span-full mt sm:col-span-3'>
                 <label htmlFor='firstName' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                   First Name <span className="text-red-500">*</span>
                 </label>
                 <input
                   type='text'
                   name='firstName'
                   id='firstName'
                   autoComplete='given-name'
                   value={formData.firstName}
                   onChange={handleInputChange}
                   className='block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6'
                 />
               </div> 
               <div className='col-span-full sm:col-span-3'>
                 <label htmlFor='lastName' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                   Last Name  <span className="text-red-500">*</span>
                 </label>
                 <input
                   type='text'
                   name='lastName'
                   id='lastName'
                   autoComplete='family-name'
                   value={formData.lastName}
                   onChange={handleInputChange}
                   className='block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6'
                 />
               </div>
               {companyValue && (
                 <div className='col-span-full '>
                 <label htmlFor='lastName' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                   Title 
                 </label>
                 <input
                   type='text'
                   name='title'
                   id='title'
                   autoComplete='family-name'
                   value={formData.title}
                   onChange={handleInputChange}
                   className='block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6'
                 />
               </div>
               )}
               <div className="col-span-full">
              <label htmlFor="about" className="block text-sm dark:text-ioty__white font-medium leading-6 text-gray-900">
                About
              </label>
              <div className="mt-2">
                <textarea
                  id="about"
                  name="about"
                  type='text'                
                  defaultValue={formData.about}
                  onChange={handleInputChange}
                  rows={3}
                  className="block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6"
                 
                />
              </div>
            </div>
               <div className='col-span-full sm:col-span-3'>
                 <label htmlFor='email' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                   Email address <span className="text-red-500">*</span>
                 </label>
                 <input
                   disabled={true}
                   type='email'
                   name='email'
                   id='email'
                   autoComplete='email'
                   value={formData.email}
                   onChange={handleInputChange}
                   className='block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6'
                 />
               </div>
               <div className='col-span-full sm:col-span-3'>
                 <label htmlFor='phoneNumber' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                   Phone Number
                 </label>
                 <input
                   type='number'
                   name='phoneNumber'
                   id='phoneNumber'
                   autoComplete='tel'
                   value={formData.phoneNumber}
                   onChange={handleInputChange}
                   className='block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6'
                 />
               </div>
               <div className='col-span-full'>
                 <label htmlFor='address' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                   Address
                 </label>
                 <input
                   type='text'
                   name='address'
                   id='address'
                   autoComplete='street-address'
                   value={formData.address}
                   onChange={handleInputChange}
                   className='block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6'
                 />
               </div>
               <div className='col-span-full sm:col-span-2'>
                 <label htmlFor='state' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                   State
                 </label>
                 <input
                   type='text'
                   name='state'
                   id='state'
                   autoComplete='address-level1'
                   value={formData.state}
                   onChange={handleInputChange}
                   className='block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6'
                 />
               </div>
               <div className='col-span-full sm:col-span-2'>
                 <label htmlFor='city' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                   City
                 </label>
                 <input
                   type='text'
                   name='city'
                   id='city'
                   autoComplete='address-level2'
                   value={formData.city}
                   onChange={handleInputChange}
                   className='block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6'
                 />
               </div>
              
               <div className='col-span-full sm:col-span-2'>
                 <label htmlFor='zipCode' className='block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900'>
                   Zip Code
                 </label>
                 <input
                   type='text'
                   name='zipCode'
                   id='zipCode'
                   autoComplete='postal-code'
                   value={formData.zipCode}
                   onChange={handleInputChange}
                   className='block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700  sm:text-sm sm:leading-6'
                 />
               </div>
               
             </div>
           </div>
           
           <div className='flex mt-2 justify-end'>
            <Link to="/profile">
            
            <button
              onClick={()=>{
                dispatch(profileEditAction({type:false}))
              }}
             className='inline-flex items-center px-8 py-2 border border-transparent text-sm  font-medium rounded-md shadow-sm  bg-ioty__white dark:bg-gray__900 text-ioty__black  focus:outline-none focus:border-blue-700   dark:text-ioty__white transition duration-150 ease-in-out disabled:opacity-50'
            
           >
             Cancel
           </button>
            
            </Link>
              {userInfo && userInfo.userInfoData ? (
                <button
              
                onClick={updateUserData}
                className='inline-flex items-center ml-1 px-8 py-2 border border-transparent text-sm  font-medium rounded-md shadow-sm text-white bg-primary dark:bg-gray__700  focus:outline-none focus:border-blue-700  transition duration-150 ease-in-out disabled:opacity-50'
                disabled={isFormIncomplete}
              >
                Update
              </button> 
              ) : (  <button
              
                onClick={saveUserData}
                className='inline-flex items-center ml-1 px-8 py-2 border border-transparent text-sm  font-medium rounded-md shadow-sm text-white bg-primary dark:bg-gray__700  focus:outline-none focus:border-blue-700  transition duration-150 ease-in-out disabled:opacity-50'
                disabled={isFormIncomplete}
              >
                Save
              </button>)}
           
           </div>
         </div>
        ) }
      </div>
    </div>
    </>
  );
}