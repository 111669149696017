const products = [
    {
      id: 1,
      name: 'Leather Long Wallet',
      color: 'Natural',
      price: '$75',
      href: '#',
      imageSrc: 'https://tailwindui.com/img/ecommerce-images/home-page-04-trending-product-02.jpg',
      imageAlt: 'Hand stitched, orange leather long wallet.',
    },
    // More products...
  ]
  
  export default function ProductList(props) {
     const {products} =props
    return (
      <div className="bg-white dark:bg-gray__900">
        <div className="mx-auto max-w-2xl px-4 py-8 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 ">
          <div className="md:flex md:items-center md:justify-between  ">
            <h2 className="text-2xl font-bold tracking-tight text-gray__900 dark:text-white">All Products</h2>
          
          </div>
          
          <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-4 md:gap-y-0  lg:gap-x-8  ">
  {products && products.map((product,index) => (
    <div key={index} className="group relative dark:bg-white rounded mt-1">
      <div className="h-56 w-full overflow-hidden rounded-md bg-gray-200  group-hover:opacity-75 lg:h-72 xl:h-80">
        <img
          src={product.src}
          alt={product.imageAlt}
          className="h-full w-full object-contain"
        />
      </div>
      <h3 className="mt-2 text-sm text-gray-700  mx-2">
          <span className="absolute inset-0" />
          {product.name}
      </h3>
      <p className="mt-1 text-sm font-medium text-gray-900  mx-2 mb-2">{product.price}</p>
    </div>
  ))}
</div>

  
          <div className="mt-8 text-sm md:hidden">
            <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
              Shop the collection
              <span aria-hidden="true"> &rarr;</span>
            </a>
          </div>
        </div>
      </div>
    )
  }