import {
  faBox,
  faBuilding,
  faCubes,
  faExchangeAlt,
  faHome,
  faProjectDiagram,
  faShoppingBag,
  faUsers,
  faList12
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setManuSize } from "../redux/slices/common/modalTheme";

const navigationSystemAdmin = [
  { name: "Home", to: "/", icon: faHome, current: true },
  { name: "Companies", to: "/companies", icon: faBuilding, current: true },
  { name: "Projects", to: "/project", icon: faProjectDiagram, current: false },
  { name: "Packages", to: "/package", icon: faBox, current: false },
  { name: "Bundles", to: "/bundles", icon: faCubes, current: false },
  { name: "Products", to: "/product", icon: faShoppingBag, current: false },
  { name: "Users", to: "/users", icon: faUsers, current: false },
  {
    name: "Category",
    to: "/category",
    icon: faList12,
    current: false,
  },
  {
    name: "Trasactions",
    to: "/transition",
    icon: faExchangeAlt,
    current: false,
  },
  ,
 
];

const navigationUser = [
  { name: "Home", to: "/", icon: faHome, current: true },
  { name: "Project", to: "/project", icon: faProjectDiagram, current: false },
];
const navigationCompany = [
  { name: "Home", to: "/", icon: faHome, current: true },
  { name: "Company", to: "/company", icon: faBuilding, current: true },
  { name: "Projects", to: "/project", icon: faProjectDiagram, current: false },
  { name: "Packages", to: "/package", icon: faBox, current: false },
  { name: "Bundles", to: "/bundles", icon: faCubes, current: false },
  { name: "Products", to: "/product", icon: faShoppingBag, current: false },
];
// eslint-disable-next-line no-unused-vars
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function LargeDevice() {
  const { manuSize } = useSelector((state) => state.modalTheme);

  const [isHovered, setIsHovered] = useState(-1);

  const handleHover = (index) => {
    setIsHovered(index);
  };

  const handleLeave = (index, hover) => {
    setIsHovered(-1);
  };

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.authentication);
  const [userValue, setUserInfo] = useState(null);

  const [adminRole, setAdminRole] = useState({
    isCompanyAdmin: false,
    isClientAdmin: false,
    isSystemAdmin: false,
    isEmployeeAdmin: false,
    userAdmin: true,
  });
  const { userInfo, notification } = useSelector((state) => state.userInfo);

  useEffect(() => {
    if (user && userInfo && userInfo.userInfoData) {
      setAdminRole(userInfo.isAdmin);
    } else {
      setUserInfo(user);
    }
  }, [userInfo, user, notification]);
  const [large, setLarge] = useState(true);

  const largeSet = () => {
    dispatch(
      setManuSize({
        type: large,
      })
    );
    setLarge(!large);
  };
  return (
    <div className="LargeDevice dark:bg-gray__900">
      <div className="">
        <div className=" bg-white  border border-stroke dark:border-gray__700  dark:border-gray__700  dark:border-gray__700 dark:bg-gray__800 h-[120vh]">
          <nav className="mt-10">
            <ul>
              {user &&
                adminRole.isSystemAdmin &&
                navigationSystemAdmin.map((item, index) => (
                  <li className="group relative">
                    <Link
                      to={`${item.to}`}
                      key={index + 2}
                      className="relative flex items-center justify-center border-r-4 border-transparent py-3 px-7 text-base font-medium text-body-color duration-200 hover:border-primary hover:bg-gray-2 dark:hover:bg-gray__900  hover:text-primary"
                    >
                      <span>
                        <FontAwesomeIcon
                          className="bg-gray__100  dark:bg-gray__900  p-2 rounded-xl "
                          icon={item.icon}
                        />
                      </span>
                    </Link>

                    <span className="invisible absolute top-1/2 left-[115%] -translate-y-1/2 whitespace-nowrap rounded bg-white py-1 px-[10px] text-sm font-medium text-body-color dark:bg-gray__800 dark:text-white dark:py-2 dark:px-3 drop-shadow-tooltip group-hover:visible">
                      <span className="absolute top-1/2 -left-2 -translate-y-1/2">
                        <svg
                          width="9"
                          height="12"
                          viewBox="0 0 9 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.23134 6.8294C0.642883 6.43303 0.642882 5.56697 1.23133 5.1706L7.44134 0.987699C8.10557 0.540292 9 1.01624 9 1.81709L9 10.1829C9 10.9838 8.10557 11.4597 7.44134 11.0123L1.23134 6.8294Z"
                            fill="white"
                            className="dark:fill-gray__800" // Fill color for dark theme
                          />
                        </svg>
                      </span>
                      {item.name}
                    </span>
                  </li>
                ))}

              {user &&
                adminRole.isCompanyAdmin &&
                navigationCompany.map((item, index) => (
                  <li className="group relative">
                    <Link
                      to={`${item.to}`}
                      key={index + 3}
                      className="relative flex items-center justify-center border-r-4 border-transparent py-3 px-7 text-base font-medium text-body-color duration-200 hover:border-primary hover:bg-gray-2 dark:hover:bg-gray__900  hover:text-primary"
                    >
                      <span>
                        <FontAwesomeIcon
                          className="bg-gray__100  dark:bg-gray__900  p-2 rounded-xl "
                          icon={item.icon}
                        />
                      </span>
                    </Link>

                    <span className="invisible absolute top-1/2 left-[115%] -translate-y-1/2 whitespace-nowrap rounded bg-white py-1 px-[10px] text-sm font-medium text-body-color dark:bg-gray__800 dark:text-white dark:py-2 dark:px-3 drop-shadow-tooltip group-hover:visible">
                      <span className="absolute top-1/2 -left-2 -translate-y-1/2">
                        <svg
                          width="9"
                          height="12"
                          viewBox="0 0 9 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.23134 6.8294C0.642883 6.43303 0.642882 5.56697 1.23133 5.1706L7.44134 0.987699C8.10557 0.540292 9 1.01624 9 1.81709L9 10.1829C9 10.9838 8.10557 11.4597 7.44134 11.0123L1.23134 6.8294Z"
                            fill="white"
                            className="dark:fill-gray__800" // Fill color for dark theme
                          />
                        </svg>
                      </span>
                      {item.name}
                    </span>
                  </li>
                ))}
              {user &&
                adminRole.userAdmin &&
                navigationUser.map((item, index) => (
                  <li className="group relative">
                    <Link
                      to={`${item.to}`}
                      key={index + 1}
                      className="relative flex items-center justify-center border-r-4 border-transparent py-3 px-7 text-base font-medium text-body-color duration-200 hover:border-primary hover:bg-gray-2 dark:hover:bg-gray__900  hover:text-primary"
                    >
                      <span>
                        <FontAwesomeIcon
                          className="bg-gray__100  dark:bg-gray__900  p-2 rounded-xl "
                          icon={item.icon}
                        />
                      </span>
                    </Link>

                    <span className="invisible absolute top-1/2 left-[115%] -translate-y-1/2 whitespace-nowrap rounded bg-white py-1 px-[10px] text-sm font-medium text-body-color dark:bg-gray__800 dark:text-white dark:py-2 dark:px-3 drop-shadow-tooltip group-hover:visible">
                      <span className="absolute top-1/2 -left-2 -translate-y-1/2">
                        <svg
                          width="9"
                          height="12"
                          viewBox="0 0 9 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.23134 6.8294C0.642883 6.43303 0.642882 5.56697 1.23133 5.1706L7.44134 0.987699C8.10557 0.540292 9 1.01624 9 1.81709L9 10.1829C9 10.9838 8.10557 11.4597 7.44134 11.0123L1.23134 6.8294Z"
                            fill="white"
                            className="dark:fill-gray__800" // Fill color for dark theme
                          />
                        </svg>
                      </span>
                      {item.name}
                    </span>
                  </li>
                ))}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default LargeDevice;
