import {
  faArrowRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { allCompany } from "../../../redux/slices/company/company";

const people = [
    {
      name: 'Lindsay Walton',
      title: 'Front-end Developer',
      department: 'Optimization',
      email: 'lindsay.walton@example.com',
      role: 'Member',
      image:
        'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    // More people...
  ]
  
  export default function UserTable() {
    const dispatch = useDispatch(); 


    useEffect(()=>{
        dispatch(allCompany())
      },[])
      const { allCompanyInfo } = useSelector((state) => state.companySlice);

    return (
        <div className='grid grid-cols-1 sm:grid-cols-8 mt-10 xl:grid-cols-12 '>    
        <div className='mx-3 sm:mx-0 sm:col-start-2 sm:col-end-8 dark:bg-gary__700 dark:bg-gray__800 dark:bg-gray__900  rounded-xl  xl:col-start-2 xl:col-end-12 2xl:col-start-3 2xl:col-end-11'> 
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold text-ioty__black dark:text-ioty__white leading-6 ">Company Request</h1>
            <p className="mt-2 text-sm text-ioty__black dark:text-ioty__white">
            Pagination and filtering will be set in the future.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              className="block rounded-md bg-primary dark:bg-gray__800 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Enroll company
            </button>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-700">
                <thead className="text-ioty-black  dark:text-ioty__white">
                  <tr className="">
                    <th scope="col" className="p-3.5 pl-4 ml-3 pr-3 text-left text-sm font-semibold  sm:pl-0">
                      Company
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold ">
                      Employee 
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold ">
                      Status
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold ">
                       Package
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:ivide-gray__700 bg-white dark:bg-gray__800 divide-y border border-gray__200 dark:border-gray__800  rounded bg-white dark:bg-gray__800 shadow">
                  {allCompanyInfo && allCompanyInfo.map((item, index) => (
                    <tr key={index}>
                      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                        <div className="flex items-center">
                          <div className="h-11 mx-1 w-11 flex-shrink-0">
                            <img className="h-11 w-11 rounded-full" src={item.companyInfo.photoURL} alt="" />
                          </div>
                          <div className="ml-4">
                            <div className="font-medium  text-ioty__black dark:text-ioty__white">{item.companyInfo.companyName}</div>
                            <div className="mt-1 text-ioty__black dark:text-ioty__white">{item.companyInfo.companyEmail}</div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-ioty__black dark:text-ioty__white">
                        
                        <div className="mt-1 text-ioty__black dark:text-ioty__white">{item.companyInfo.admin.length}</div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-ioty__black dark:text-ioty__white">
                        <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                          {item.isPending? 'Pending' : 'Active'}
                        </span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-ioty__black dark:text-ioty__white">{item.companyInfo.isPremium ? 'Premium': 'Basic'}</td>
                      <td className="relative whitespace-nowrap py-5 pl-3 mr-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        
                       <Link to={`/companies/${item.companyId}`}>
                       <button className="mr-4 text-ioty__white bg-primary dark:bg-gray__900 p-2 rounded"> Details
                         <FontAwesomeIcon
                        icon={faArrowRight}
                        className=" mx-2 mr-1.5 h-4 w-4"
                      />
                         </button>
                       </Link>
                       
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
    )
  }
  