import { Link, useParams } from "react-router-dom";
import { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Chat from "../chat/Chat";
import ChatList from "../chat/ChatList";
import ChatTab from "../chat/ChatTab";
import Tabs from "../project/Tabs";
import {fetchFloorPlanDetailsInfo, fetchProjectInfo} from '../../redux/slices/project/projects'
import { useEffect } from "react";
import FloorpalnTab from "./FloorpalnTab";
function FloorplanDetails() {
    const dispatch = useDispatch()
    const { companyId, projectId, floorplanId } = useParams();
    const [tabFloorplan, setTabFloorplan] = useState(null)

    const {chatTab, projectTab} = useSelector((state) => state.modalTheme);
    useEffect(()=>{
       dispatch( fetchFloorPlanDetailsInfo(projectId,floorplanId))
    },[])
    useEffect(() => {
      dispatch(fetchProjectInfo(projectId));
    }, [projectId]);
    const { project } = useSelector((state) => state.projectSlice);
    const { userCompanyInfo } = useSelector((state) => state.companySlice);
    useEffect(() => {
      if (project && userCompanyInfo) {
        if (project.companyId === userCompanyInfo.companyId) {
          setTabFloorplan(project.categories);
        } else {
          const tab = [];
          project.collaboration.forEach((item) => {
            if (item.companyId === userCompanyInfo.companyId) {
              item.accessTab.forEach((accessCategory) => {
                const matchingCategory = project.categories.find(
                  (category) => category.categoryName === accessCategory.categoryName
                );
                if (matchingCategory) {
                  tab.push(matchingCategory);
                }
              });
            }
          });
          setTabFloorplan(tab);
        }
      }
    }, [project, userCompanyInfo]);
    const icons = [
      "https://img.icons8.com/metro/26/228BE6/searchlight.png",
      "https://img.icons8.com/dotty/80/228BE6/tv.png",
    ];
  
    const [position, setPosition] = useState([]);
  
    const droppableRef = useRef(null);
    const [draggedIcon, setDraggedIcon] = useState(null); // Variable to store the dragged icon
  
    const dragStart = (e, icon) => {
      console.log("drag Start");
  
      // Store the dragged icon in the state variable
      setDraggedIcon(icon);
    };
  
    const draggingOver = (e) => {
      e.preventDefault();
      console.log("dragging over now");
    };
  
    const dragDrop = (e) => {
      e.preventDefault();
      console.log("drag Drop");
  
      if (draggedIcon) {
        const droppedIcon = draggedIcon;
        console.log("You have dropped", droppedIcon);
  
        const rect = droppableRef.current.getBoundingClientRect();
        const x = ((e.clientX - rect.left) / rect.width) * 100;
        const y = ((e.clientY - rect.top) / rect.height) * 100;
  
        const newPosition = {
          x,
          y,
          icon: droppedIcon,
        };
  
        setPosition((prevState) => [...prevState, newPosition]);
        setDraggedIcon(null); // Reset the dragged icon state variable
      }
    };
  
    const touchStart = (e, icon) => {
      console.log("touch Start");
      e.preventDefault();
  
      // Store the dragged icon in the state variable
      setDraggedIcon(icon);
    };
  
    const touchMove = (e) => {
      e.preventDefault();
      console.log("touch Move");
    };
  
    const touchEnd = (e) => {
      console.log("touch End");
  
      if (draggedIcon) {
        const droppedIcon = draggedIcon;
        console.log("You have dropped", droppedIcon);
  
        const rect = droppableRef.current.getBoundingClientRect();
        const x = ((e.changedTouches[0].clientX - rect.left) / rect.width) * 100;
        const y = ((e.changedTouches[0].clientY - rect.top) / rect.height) * 100;
  
        const newPosition = {
          x,
          y,
          icon: droppedIcon,
        };
  
        setPosition((prevState) => [...prevState, newPosition]);
        setDraggedIcon(null); // Reset the dragged icon state variable
      }
    };
    const { floorplanDetails } = useSelector((state) => state.projectSlice);
    return (
      <div className="Project">
        <>
        {floorplanDetails && (<>
            <h1 className="text-ioty__black dark:text-primary my-2 xl:ml-8 text-xl mx-2 font-bold">{floorplanDetails.floorplanName}</h1>
            {project && project.categories &&(<><FloorpalnTab tabs ={tabFloorplan}/></>)}
       <div className="px-2">
         <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-9">
         
           <div className="col-span-full sm:col-span-3 xl:col-span-3 2xl:col-span-2 dark:bg-gray__700 p-5 border  border-stroke dark:border-gray__800 bg-white dark:bg-gray__800 pb-5">
            <div className="mb-3 "> <Tabs/></div>
            {projectTab && projectTab ===1 && (<>
             <h1 className="text-ioty__black dark:text-primary mt-10 text-center font-bold">Hotspots  tab implement futuere</h1>
             {icons &&
               icons.map((item, index) => (
                 <button
                   key={index}
                   draggable
                   onTouchStart={(e) => touchStart(e, item)}
                   onTouchMove={(e) => touchMove(e)}
                   onTouchEnd={(e) => touchEnd(e)}
                   onDragStart={(e) => dragStart(e, item)}
                   className="m-1 p-1 bg-gray__900 rounded-full"
                 >
                   <img className="w-4 h-4" src={item} alt="er" />
                 </button>
               ))}</>)}
            {projectTab && projectTab ===2 && (<div>
             <h1 className="text-ioty__black dark:text-primary mt-10 text-center font-bold">Package  tab implement futuere</h1>
            </div>)}
            {projectTab && projectTab ===3 && (<div>
             <h1 className="text-ioty__black dark:text-primary mt-10 text-center font-bold">Add tab implement futuere</h1>
            </div>)}
            
           </div>
           <div className="col-span-full sm:col-span-6 xl:col-span-6 2xl:col-span-4 overflow-auto scrollbar-thin scrollbar-thumb-gray_700 scrollbar-track-gray_900 border  border-stroke dark:border-gray__900 bg-white dark:bg-gray__900 pb-5">
             <div
               ref={droppableRef}
               onTouchMove={(e) => draggingOver(e)}
               onDragOver={(e) => draggingOver(e)}
               onDrop={(e) => dragDrop(e)}
               onTouchEnd={(e) => touchEnd(e)}
               className="dark:bg-gray__700 bg-gray__300"
               style={{
                backgroundImage: `url('${floorplanDetails.floorplanImage}')`,
                 backgroundSize: "contain",
                 backgroundRepeat: "no-repeat",
                 backgroundPosition: "center",
                 height: "40em",
                 width: "50em",
                 position: "relative",
                 overflow: "hidden",
               }}
             >
               <div
                 style={{
                   position: "absolute",
                   top: 0,
                   left: 0,
                   width: "100%",
                   height: "100%",
                 }}
               >
                 {position.map((pos, index) => (
                   <div
                     key={index}
                     draggable
                     onTouchStart={(e) => touchStart(e, pos.icon)}
                     onTouchMove={(e) => touchMove(e)}
                     onTouchEnd={(e) => touchEnd(e)}
                     onDragStart={(e) => dragStart(e, pos.icon)}
                     onDragOver={(e) => draggingOver(e)}
                     className="bg-gray__900 p-1  rounded-full"
                     style={{
                       position: "absolute",
                       top: `${pos.y}%`,
                       left: `${pos.x}%`,
                     }}
                   >
                     <img className="w-4 h-4" src={pos.icon} alt="sdf" />
                   </div>
                 ))}
               </div>
             </div>
           </div>
           <div className=" hidden 2xl:inline 2xl:col-span-3   bg-white dark:bg-gray__800 ">
             <div><ChatTab/></div>
             {chatTab && chatTab ===1  && (
               <div className="xl:mx-10"><Chat /></div>
             )}
              {chatTab && chatTab ===2  && (
               <><ChatList /></>
             )}
             
           </div>
         </div>
       </div></>)}
        
        </>
      </div>
    );
  }
  
  export default FloorplanDetails;