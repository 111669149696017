import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { signInAction, signInWithFacebookAction, signInWithGoogleAction } from "../../redux/slices/auth/auth";
import { passwordResetModal, signInModal, signUpModal } from "../../redux/slices/common/modalTheme";

export default function SignIn({ value }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  useEffect(() => {
    setOpen(value);
    
  }, [value]);
  const createAccount =()=>{
    dispatch(signInModal({ type: true }))
    dispatch(signUpModal({ type: false }))
  }
  const resetPassword= ()=>{
    dispatch(signInModal({ type: true }))
    dispatch(signUpModal({ type: true }))
    dispatch(passwordResetModal({ type: false }))
  }
  const resetModal= ()=>{
    dispatch(signInModal({ type: true }))
    dispatch(signUpModal({ type: true }))
    dispatch(passwordResetModal({ type: true }))
    
  }
  const handleSignin = (e) => {
    e.preventDefault();
    dispatch(signInAction(formData.email, formData.password))
    }

    // Handle the signup logic using the form data
    // You can dispatch actions, make API calls, etc.
   
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    isFormValid()
  };
  const isFormValid = () => {
    const { email, password, } = formData;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    

    if  (email === "" || password === "") {
      return false;
    }

    else if (!emailRegex.test(email)) {
      return false;
    }

    else if (password.length  < 6) {
      return false;
    }
    else {
      return true;
    }
  }
  
  return (
    <Transition.Root show={open} as={Fragment} onClick={resetModal}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
        <div className="fixed inset-0 dark:bg-gray__800 bg-gray__600 dark:bg-opacity-70 darl bg-opacity-90  transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg dark:bg-gray__900 bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all w-full sm:my-8  sm:w-full sm:max-w-md sm:p-6">
                <div className="flex items-center justify-between border-b dark:border-gray__700 border-gray__200">
                  <div className="text-sm ">
                    <h1 className="dark:text-gray__200 text-xl text-ioty__black">
                      Sign In
                    </h1>
                  </div>
                  <div className="text-sm ">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-full px-3 mb-1 py-0 text-sm font-semibold dark:text-gray__300 text-ioty__black shadow-sm dark:hover:bg-gray__700 hover:bg-gray__200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                      onClick={(e) => {
                        dispatch(signInModal({ type: value }));
                      }}
                    >
                      <XMarkIcon
                        className={`${open === false ? "hidden" : "h-8 w-8"}`}
                      />
                    </button>
                  </div>
                </div>

                <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-[480px]">
                  <div className="dark:bg-gray__900 bg-white py-4 shadow sm:rounded-lg sm:px-4">
                    <div className="space-y-2" >
                      <div>
                        <label
                          htmlFor="email"
                          className="block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900"
                        >
                          Email address
                        </label>
                        <div className="mt-2">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            value={formData.email}
                            onChange={handleChange}
                            className="block w-full rounded-md dark:bg-gray__600 border-0 pl-2 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div>
                        <label
                          htmlFor="password"
                          className="block dark:text-gray__200 text-sm font-medium leading-6 text-gray__900"
                        >
                          Password
                        </label>
                        <div className="mt-2">
                          <input
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            required
                            value={formData.password}
                            onChange={handleChange}
                            className="block w-full rounded-md pl-2 dark:bg-gray__600 border-0 py-1.5 dark:text-white text-gray__900 shadow-sm ring-1 ring-inset dark:ring-gray__800 ring-gray__300 dark:placeholder:text-white placeholder:text-gray__400 focus:ring-2 focus:ring-inset dark:focus:ring-gray__700 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                     
                     
                      <div className="flex items-center justify-end ">
                        <div className="text-sm leading-6">
                          <button  
                            onClick={resetPassword}                       
                            className="font-semibold dark:text-gray__300 text-ioty__black hover:text-indigo-500"
                          >
                            Forgot password?
                          </button>
                        </div>
                      </div>

                      <div>
                        <button
                           disabled={!isFormValid()}
                           onClick={handleSignin}
                          className={`flex w-full justify-center  rounded-md dark:bg-gray__800 bg-[#87c4dc] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#87c4dc] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${isFormValid ? '' : 'pointer-events-none'}`}
                        >
                          Sign In
                        </button>
                      </div>
                    </div>

                    <div>
                    <div className="relative mt-5">
                        <div
                          className="absolute inset-0 flex items-center"
                          aria-hidden="true"
                        >
                          <div className="w-full border-t dark:border-gray__700 border-gray__200" />
                        </div>
                        <div className="relative flex justify-center text-xs font-medium leading-6">
                          <span className="bg-white dark:bg-gray__700 dark:text-gray__200 rounded-full px-6 text-gray__900">
                            Or continue with
                          </span>
                        </div>
                      </div>

                      <div className="mt-6 grid grid-cols-2 gap-4">
                        <button 
                         onClick={()=>{dispatch(signInWithFacebookAction())}}
                          className="flex w-full items-center justify-center gap-3 rounded dark:bg-gray__800 bg-ioty__white px-3 py-1.5 text-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F]"
                        >  <img width="34" height="34" src="https://img.icons8.com/color/48/facebook-new.png" alt="facebook-new"/>
                          <span className="text-sm font-semibold leading-6">
                            Facebook
                          </span>
                        </button>

                        <button
                         onClick={()=>{
                          dispatch(signInWithGoogleAction())
                         }}
                          className="flex w-full items-center justify-center gap-3 rounded dark:bg-gray__800 bg-ioty__white px-3 py-1.5 text-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F]"
                        >  <img width="30" height="30" src="https://img.icons8.com/office/42/google-logo.png" alt="google-logo"/>
                          <span className="text-sm font-semibold leading-6">
                            Google
                          </span>
                        </button>
                      </div>
                      <div className="relative mt-5">
                        <div
                          className="absolute inset-0 flex items-center"
                          aria-hidden="true"
                        >
                          <div className="w-full border-t dark:border-gray__700 border-gray__200" />
                        </div>
                        <div className="relative flex justify-center text-xs font-medium leading-6">
                          <span className="bg-white dark:bg-gray__700 dark:text-gray__200 rounded-full px-6 text-gray__900">
                          New to Signalflow?
                          </span>
                        </div>
                      </div>
                    <div>
                        <button
                            onClick={createAccount}
                          className="flex w-full justify-center mt-4 text-xs  rounded-md dark:bg-gray__800 bg-ioty__white px-3 py-1 text-sm font-semibold leading-6 dark:text-gray__200 text-ioty__black shadow-sm hover:bg-ioty__white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Create Your Signalflow Account
                        </button>
                      </div>
                     
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
