import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { companyEmployAdd } from '../../../redux/slices/common/modalTheme';

const data = [
  {
    "phoneNumber": "",
    "coverPhoto": "https://hooquest.com/wp-content/uploads/2021/11/facebook-cover-2.jpg",
    "email": "sohagchowdhury60@gmail.com",
    "emailVerified": true,
    "uid": "50sVzyDOFaUCgPmE8KZLRdAPHTE3",
    "photoURL": "https://lh3.googleusercontent.com/a/AAcHTtePzFwI92g3EBHikZ2SsUvgn-8IWtoEtlcNjSzK=s96-c",
    "address": "",
    "city": "",
    "state": "",
    "lastName": "chowdhury",
    "zipCode": "",
    "about": "Jk",
    "isDark": false,
    "firstName": "sohag",
    "authProvider": "google",
    "accountCreateTime": "Mon, 12 Jun 2023 14:33:43 GMT"
  },
  {
    "coverPhoto": "https://firebasestorage.googleapis.com/v0/b/signalflow-dev.appspot.com/o/cover%2F1686923241ioctyCover2.jpg?alt=media&token=28f0c637-3827-43d2-8429-7f535b7b2999",
    "authProvider": "google",
    "zipCode": "Mirpur-1216",
    "emailVerified": true,
    "accountCreateTime": "Sat, 04 Jun 2022 13:44:02 GMT",
    "address": "Elephent Road, 329/1",
    "lastName": "Chowdhury 3",
    "about": "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here'",
    "firstName": "TMS",
    "isAdmin": {
      "isCompanyAdmin": false,
      "isSystemAdmin": false,
      "isClientAdmin": false,
      "isEmployeeAdmin": false,
      "userAdmin": true
    },
    "city": "Mirpur",
    "state": "Dhaka",
    "photoURL": "https://firebasestorage.googleapis.com/v0/b/signalflow-dev.appspot.com/o/users%2F1686938915Profile.jpg?alt=media&token=9b15a04c-6267-429a-bf4d-5af41479d631",
    "uid": "ErgE2yHbr8VEKVNjGM0dGp3taqC3",
    "phoneNumber": "01709843342",
    "email": "sohagchowdhury321@gmail.com"
  },
  {
    "postalCode": "33014",
    "uid": "swv1I3zDOZZMheSAFiNvgIWcE9M2",
    "authProvider": "google",
    "isAdmin": {
      "isClientAdmin": false,
      "isSystemAdmin": false,
      "userAdmin": true,
      "isCompanyAdmin": false,
      "isEmployeeAdmin": false
    },
    "city": "Miami Lakes",
    "photoURL": "https://firebasestorage.googleapis.com/v0/b/signalflow-dev.appspot.com/o/users%2F1687358250rory%20social.png?alt=media&token=2e5e2c9b-9298-4ea3-97bb-0e5b1b079016",
    "coverPhoto": "https://firebasestorage.googleapis.com/v0/b/signalflow-dev.appspot.com/o/cover%2F1687358493180%20Arvida.jpg?alt=media&token=c9ba168c-d83c-4526-874a-9c14b072abec",
    "firstName": "Rory",
    "address": "3301 NW 82nd Avenue",
    "about": "We consider the completion of this project to be the beginning of a long-term relationship with you, and we look forward to providing ongoing support and assistance as you continue to use and enjoy your home automation system.",
    "email": "rory@iotyinc.com",
    "region": "Florida",
    "emailVerified": true,
    "accountCreateTime": "Wed, 07 Mar 2018 14:51:22 GMT",
    "state": "Florida",
    "lastName": "Silva",
    "phoneNumber": "3054074442"
  },
  {
    "phoneNumber": "01709843342",
    "about": " is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum",
    "lastName": "Chowdhury 3",
    "firstName": "TMS",
    "emailVerified": true,
    "coverPhoto": "https://hooquest.com/wp-content/uploads/2021/11/facebook-cover-2.jpg",
    "address": "Elephent Road, 329/1",
    "accountCreateTime": "Wed, 01 Jun 2022 09:16:21 GMT",
    "isAdmin": {
      "isSystemAdmin": false,
      "isClientAdmin": false,
      "userAdmin": true,
      "isCompanyAdmin": false,
      "isEmployeeAdmin": false
    },
    "state": "Dhaka",
    "uid": "zDmJyGPvp2bEuTk6bN65H0oQqQO2",
    "zipCode": "Dhaka-1216",
    "authProvider": "google",
    "city": "Mirpur",
    "email": "sohag02.c@gmail.com",
    "photoURL": "https://firebasestorage.googleapis.com/v0/b/signalflow-dev.appspot.com/o/users%2F1687628677Gavi.jpg?alt=media&token=f4b31db5-44fc-4aa4-9cca-5141c5a28d7f"
  }
];

export default function AutocompleteSearchBar() {
  const dispatch = useDispatch()
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  if (e.target.value.length >= 3) {
    const results = data.filter((item) =>
      item.firstName.toLowerCase().includes(e.target.value.toLowerCase()) ||
      item.lastName.toLowerCase().includes(e.target.value.toLowerCase()) ||
      item.email.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setSearchResults(results);
  } else {
    setSearchResults([]);
  }
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleChange}
        className="w-full py-1.5 px-4 mt-0 rounded-full shadow focus:outline-none focus:ring-1  bg-ioty__white"
      />
      <ul className="mt-1 max-h-[6em] mt-2 overflow-y-auto">
        {searchResults.map((item, index) => (
          <li key={index} className="cursor-pointer py-1" onClick={()=>dispatch(companyEmployAdd({ type:true , user:item}))}>
            <div className="flex items-center">
              <img
                src={item.photoURL}
                alt="Profile"
                className="w-6 h-6 rounded-full mr-4"
              />
              <div>
                <p className='text-sm dark:text-ioty__white text-ioty__black roboto'>{`${item.firstName} ${item.lastName}`}</p>
                <p className='text-sm dark:text-ioty__white text-ioty__black roboto'>{item.email}</p>
              </div>
              
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
