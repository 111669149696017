
import UpdateForm from '../components/Profile/UpdateForm';
function Transitions() {
    return (
      <div className="Transitions border-t border-white dark:border-gray__800">
         <>
          <UpdateForm></UpdateForm>
         </>
      </div>
    );
  }
  export default Transitions;