import { createSlice } from '@reduxjs/toolkit';
import { get, getDatabase, onValue, ref, set, update } from "firebase/database";
import { companies, users } from '../../../firebase/collection/index';
import { changeMode, notificationTostaAction } from "../common/modalTheme";


const initialState = {
  isStatus: true,
  allUserInfo:null,
  isAdmin: {
    isSystemAdmin: false,
    isCompanyAdmin: false,
    isClientAdmin: false,
    isEmployeeAdmin: false,
    userAdmin: true,
  },
  isDark:false,
  permittedProject: [],
  userInfo: null,
  notification:null,
  adminPerUser:null,
  allUser:null
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo(state, action) {
      state.userInfo = action.payload;
    },
    clearUserInfo(state) {
      state.userInfo = null;
    },
    fetchUserInfoSuccess(state, action) {
      state.userInfo = action.payload;
    },
    setUserNotification(state, action) {
      state.notification = action.payload;
    },
    setSuperAdminNotification(state, action){
      state.notification =action.payload
    },
    adminPerUserInfo(state, action){
      state.adminPerUser =action.payload
    },
    allUserInfo(state, action) {
      state.allUserInfo =action.payload
    }

  },
});


export const { setUserInfo, clearUserInfo, allUserInfo,adminPerUserInfo, fetchUserInfoSuccess,setUserNotification, setSuperAdminNotification } = userSlice.actions;

export const fetchUserNotifications = (userId) => {
  return (dispatch) => {
    const database = getDatabase();
    const notificationsRef = ref(database, `notification/${userId}`);

    try {
      onValue(notificationsRef, (snapshot) => {
        const notifications = snapshot.val();
        if (notifications) {
          const notificationList = Object.values(notifications);
          dispatch(setUserNotification(notificationList.sort((a, b) => b.timestamp - a.timestamp)));
        } else {
          dispatch(setUserNotification([]));
        }
      });
    } catch (error) {
      console.error('Realtime error: ', error);
    }
  };
};
export const fetchSuperAdminNotification = () => {
  return (dispatch) => {
    const database = getDatabase();

    const notificationsRef = ref(database, "systemAdmin/notification");

    try {
      onValue(notificationsRef, (snapshot) => {
        const notifications = snapshot.val();
        if (notifications) {
          const notificationList = Object.values(notifications);
          dispatch(setSuperAdminNotification(notificationList.sort((a, b) => b.timestamp -a.timestamp )));
        } else {
          dispatch(setSuperAdminNotification([]));
        }
      });
    } catch (error) {
      console.error('Realtime error: ', error);
    }
  };
};

export const companyAction =(paylaod)=>{
  return (dispatch) =>{
   try {
    dispatch(fetchCompanyInfo(paylaod.id, paylaod.type))
   }
   catch(error){
    console.log(error, )
   }
  }
}


export const fetchCompanyInfo = (companyId, action) => {
  return (dispatch) => {
   
    companies
      .doc(companyId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          companies
            .doc(companyId)
            .update({ isPending: action? false : true })
            .then(() => {
              doc.data().companyInfo.admin.map((item) => {
                const database = getDatabase();
                const notificationRef = ref(database, `notification/${item.adminUid}/${Date.now()}`);
                return set(notificationRef, {
                  message: action ? 'Your company Enrol request approved!!' : 'Your company Enrol request Declined',
                  type: 'Aproval company',
                  status:action? 'success' : 'reject' ,
                  isAction:false,
                  isSeen:true,
                  congMrss: action ? 'Congratulations!!' : 'Sorry ',
                  timestamp: Date.now(),
               
                })
              })
            }).then(()=>{
              dispatch(adminNotificationUpdate(companyId,"Compnay request", action))
           })
            .catch((error) => {
              console.error('Error updating isPending property:', error);
            });
        }
      })
      .catch((error) => {
        console.error('Error fetching company information data from Firestore: ', error);
      });
  };
};

export const adminNotificationUpdate = (companyId, type, action) => {
  return (dispatch) => {
    try {
      const database = getDatabase();
      const notificationRef = ref(database, 'systemAdmin/notification');

      get(notificationRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const notifications = snapshot.val();

            // Loop through each notification
            Object.keys(notifications).forEach((notificationId) => {
              const notification = notifications[notificationId];

              // Check if companyId and type match
              if (
                notification.companyId === companyId &&
                notification.type === type
              ) {
                // Update the isAction property to true
                notification.isAction =false
                // Add additional property isApproved
                notification.isApproved =action ? true : false;
              }
            });

            // Update the notifications in the database
            update(notificationRef, notifications)
              .then(() => {
                console.log("Notification updated successfully.");
              })
              .catch((error) => {
                console.error("Error updating notification:", error);
              });
          } else {
            console.log("Notification not found.");
          }
        })
        .catch((error) => {
          console.error("Error retrieving notification:", error);
        });
    } catch (error) {
      console.error("Error updating notification:", error);
    }
  };
};


export const updateUserAndAdminNotification = () => {
  return (dispatch) => {
    const database = getDatabase();
    const systemAdminRef = ref(database, 'systemAdmin/notification');
    try {
      onValue(systemAdminRef, (snapshot) => {
        const notifications = snapshot.val();
        if (notifications) {
          // Loop through each notification item
          Object.keys(notifications).forEach((notificationId) => {
            const notification = notifications[notificationId];
            // Set 'isSeen' to false
            notification.isSeen = false;
          });

          // Update the notifications in the database
          set(systemAdminRef, notifications)
            .catch((error) => {
              console.error('Error updating notifications:', error);
            });
        }
      });
    } catch (error) {
      console.error('Realtime error: ', error);
    }
  };
};

export const updateSuperAdminNotification = () => {
  return (dispatch) => {
    const database = getDatabase();
    const systemAdminRef = ref(database, 'systemAdmin/notification');
    try {
      onValue(systemAdminRef, (snapshot) => {
        const notifications = snapshot.val();
        if (notifications) {
          // Loop through each notification item
          Object.keys(notifications).forEach((notificationId) => {
            const notification = notifications[notificationId];
            // Set 'isSeen' to false
            notification.isSeen = true;
          });

          // Update the notifications in the database
          set(systemAdminRef, notifications)
          
            .catch((error) => {
              console.error('Error updating notifications:', error);
            });
        }
      });
    } catch (error) {
      console.error('Realtime error: ', error);
    }
  };
};
export const updateUserNotification = (id) => {
  return (dispatch) => {
    const database = getDatabase();
    const systemAdminRef = ref(database, `notification/${id}`);
    try {
      onValue(systemAdminRef, (snapshot) => {
        const notifications = snapshot.val();
        if (notifications) {
          // Loop through each notification item
          Object.keys(notifications).forEach((notificationId) => {
            const notification = notifications[notificationId];
            // Set 'isSeen' to false
            notification.isSeen = true;
          });

          // Update the notifications in the database
          set(systemAdminRef, notifications)
          
            .catch((error) => {
              console.error('Error updating notifications:', error);
            });
        }
      });
    } catch (error) {
      console.error('Realtime error: ', error);
    }
  };
};
export const fetchAllUserInfo = () => {
  return (dispatch) => {
    users
      .get()
      .then((querySnapshot) => {
        const userInfoData = [];
        querySnapshot.forEach((doc) => {
          if (doc.exists) {
            const userInfo = doc.data();
            userInfoData.push(userInfo);
          }
        });
        dispatch(allUserInfo(userInfoData))
        // dispatch(fetchUserInfoSuccess(userInfoData));
      })
      .catch((error) => {
        console.error('Error fetching user information data from Firestore: ', error);
      });
  };
};
export const fetchUserInfoAdmin = (userId) => {
  return (dispatch) => {
    users
      .doc(userId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const userInfoData = doc.data();
          dispatch(adminPerUserInfo(userInfoData))
        }
      })
      .catch((error) => {
        console.error('Error fetching user information data from Firestore: ', error);
      });
  };
};

export const fetchUserInfo = (userId) => {
  return (dispatch) => {
    users
      .doc(userId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const userInfoData = doc.data();
          dispatch(changeMode({ type: userInfoData.isDark ? 'dark':'light', instance:false }))
          dispatch(fetchUserInfoSuccess(userInfoData));
          if(userInfoData.isAdmin.isSystemAdmin){
            dispatch(fetchSuperAdminNotification())
          }
          else if(userInfoData.isAdmin.isCompanyAdmin) {
            dispatch(fetchUserNotifications(userId));
          }
          else  {
            dispatch(fetchUserNotifications(userId));
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching user information data from Firestore: ', error);
      });
  };
};

export const fetchUserInfoAfterUpdate = (userId) => {
  return (dispatch) => {
    users
      .doc(userId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const userInfoData = doc.data();
          dispatch(fetchUserInfoSuccess(userInfoData));
          if(userInfoData.isAdmin.isSystemAdmin){
            dispatch(fetchSuperAdminNotification())
          }
          else if(userInfoData.isAdmin.isCompanyAdmin) {
            dispatch(fetchUserNotifications(userId));
          }
          else  {
            dispatch(fetchUserNotifications(userId));
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching user information data from Firestore: ', error);
      });
  };
};


export const themeUpdate =  (theamVaue, id) => {
  return async (dispatch) => {
    const documentRef =users.doc(id);
    await documentRef.update({
      isDark:theamVaue
    }).then(()=>{
      dispatch(fetchUserInfo(id))
      dispatch(
        notificationTostaAction({
          message:`${theamVaue ? 'Day': 'Night'} mode active.`,
          type: "Sign Up",
          status: "success",
        })
      )
}).catch((error) => {
        console.error('Error writing user information data to Firestore: ', error);
      });
  }
}


export const updateUserInfo = (userInfoData) => {
  return (dispatch) => {
    users
      .doc(userInfoData.uid)
      .update({
        userInfoData,
      })
      .then(() => {
        dispatch(notificationTostaAction({
          message:"Data Updated Successfully",
          type:"Sign Up",
          status:"success"
        }))
      })
      .catch((error) => {
        console.error('Error writing user information data to Firestore: ', error);
        // .then(() => {
        //   const database = getDatabase();
        //   const notificationRef = ref(database, `notification/${userInfoData.uid}/${Date.now()}`);
        //   return set(notificationRef, {
        //     message: "Data Updated Successfully",
        //     type: "Sign Up",
        //     status: "success",
        //     timestamp:Date.now(),
        //     isSeen:false,
        //     isAction:true,
  
        //   });
        // })
      });
  };
};

export const writeUserInfo = (userInfoData) => {
  return (dispatch) => {
    users
      .doc(userInfoData.uid)
      .set({
        ...initialState,
        userInfoData,
      })
      .then(() => {
        dispatch(notificationTostaAction({
          message:"Data Insert Successfully",
          type:"Sign Up",
          status:"success"
        }))
        dispatch(fetchUserInfo(userInfoData.uid));
      }).then(() => {
        dispatch(fetchUserInfo(userInfoData.uid));
      })
      .catch((error) => {
        console.error('Error writing user information data to Firestore: ', error);
        // .then(() => {
        //   const database = getDatabase();
        //   const notificationRef = ref(database, `notification/${userInfoData.uid}/${Date.now()}`);
        //   return set(notificationRef, {
        //     message: "Data Updated Successfully",
        //     type: "Sign Up",
        //     status: "success",
        //     timestamp:Date.now(),
        //     isSeen:false,
        //     isAction:true,
  
        //   });
        // })
      });
  };
};
export const  logOutremoveData =()=>{
  return (dispatch) =>{
    dispatch(setUserInfo(null))
  }
}

export default userSlice.reducer;